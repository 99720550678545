import type { DocumentCallback } from "@innovatrics/dot-document-auto-capture";

import { useState } from "react";
import { useDocumentScanning } from "../lib/hooks/useDocumentScanning";
import DocumentCamera from "./DocumentCamera";
import DocumentUi from "./DocumentUi";
import { useTranslations } from "use-intl";
import { DocumentScanningResult } from "../lib/types/types";
import { IconButton } from "@mui/material";
import { X } from "@phosphor-icons/react";

export interface Props {
  onPhotoTaken: (response: DocumentScanningResult | null) => void;
  onError: () => void;
  onClose: () => void;
}

function DocumentAutoCapture({ onPhotoTaken, onError, onClose }: Props) {
  const t = useTranslations();

  const [loading, setLoading] = useState(false);
  const { mutateAsync } = useDocumentScanning({
    onSuccess: onPhotoTaken,
    onError,
  });
  const handlePhotoTaken: DocumentCallback = async (imageData) => {
    setLoading(true);
    const reader = new FileReader();
    reader.readAsDataURL(imageData.image);
    reader.onloadend = function () {
      const base64data = reader.result;
      const base64 = (base64data as string)?.split("base64,")[1];
      if (base64) {
        mutateAsync({
          frontDoc: base64,
        });
      }
    };
  };

  return (
    <div className="relative flex">
      {!loading && (
        <div className="absolute end-8 top-5 z-10 rounded-full bg-white">
          <IconButton onClick={() => onClose()}>
            <X size={20} className="fill-secondary" />
          </IconButton>
        </div>
      )}
      {loading ? (
        <div className="flex flex-1 items-center justify-center">
          <div className="mt-[70%] flex flex-col items-center justify-center">
            <div className="relative flex h-24 w-24 items-center justify-center rounded-full bg-green-200 p-6">
              <div className="absolute inset-0 animate-spin rounded-full border-4 border-t-4 border-green-300 border-t-green-800" />

              <img src={"/scan.svg"} width={40} height={40} alt="scan" />
            </div>
            <div className="mt-2 text-black">
              {t("travellers.scanningInProgress")}
            </div>
          </div>
        </div>
      ) : (
        <>
          <DocumentUi
            showCameraButtons={false}
            placeholder="passport-solid-back-blank"
            instructions={{
              candidate_selection: t("travellers.candidate_selection"),
              document_centering: t("travellers.document_centering"),
              document_not_present: t("travellers.document_not_present"),
              document_too_far: t("travellers.document_too_far"),
              sharpness_too_low: t("travellers.sharpness_too_low"),
              brightness_too_low: t("travellers.brightness_too_low"),
              brightness_too_high: t("travellers.brightness_too_high"),
              hotspots_present: t("travellers.hotspots_present"),
            }}
            appStateInstructions={{
              loading: { text: t("travellers.scannerLoading") },
              waiting: { text: t("travellers.scannerWaiting") },
            }}
          />
          <DocumentCamera
            cameraFacing="environment"
            onPhotoTaken={handlePhotoTaken}
            onError={onError}
          />
        </>
      )}
    </div>
  );
}

export default DocumentAutoCapture;
