"use client";

import { useNavigate } from "@tanstack/react-router";

import { MyFlightsList } from "./MyFlightsList";
import { CategorySwitcher } from "./CategorySwitcher";
import { MyHotelsList } from "./MyHotelsList";
import { CaretDown, X } from "@phosphor-icons/react";
import { IconButton, SwipeableDrawer } from "@mui/material";
import { OrdersTypeList } from "./OrdersTypeList";
import { useState } from "react";
import { OrderType } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import { orderTypesLabels } from "./labels";

interface MyTripsProps {
  category: "flights" | "hotels";
  initOrderType: OrderType;
}

export const MyTrips = ({ category, initOrderType }: MyTripsProps) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const [selectedOrderType, setSelectedOrderType] =
    useState<OrderType>(initOrderType);
  const [orderTypeDrawerOpen, setOrderTypeDrawerOpen] = useState(false);

  const orderTypeSwitcher = (
    <button
      onClick={() => setOrderTypeDrawerOpen(true)}
      className="text-primary active:border-accent-700 inline-flex items-center gap-2 rounded-2xl bg-white px-4 py-2 text-sm"
    >
      <CaretDown aria-hidden /> {t(orderTypesLabels[selectedOrderType])}
    </button>
  );

  return (
    <>
      <CategorySwitcher current={category} />
      <div className="mt-4">
        {category === "flights" ? (
          <MyFlightsList
            onOrderClick={(order) => {
              navigate({
                to: "/trips/$scope/$id",
                params: { scope: "flights", id: order.id },
              });
            }}
            orderType={selectedOrderType}
            orderTypeSwitcher={orderTypeSwitcher}
          />
        ) : category === "hotels" ? (
          <MyHotelsList
            onOrderClick={(order) => {
              navigate({
                to: "/trips/$scope/$id",
                params: { scope: "hotels", id: order.id },
              });
            }}
            orderType={selectedOrderType}
            orderTypeSwitcher={orderTypeSwitcher}
          />
        ) : null}
      </div>
      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setOrderTypeDrawerOpen(false)}
        onOpen={() => setOrderTypeDrawerOpen(true)}
        open={orderTypeDrawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <>
          <div className="mb-2 flex items-center">
            <IconButton
              onClick={() => setOrderTypeDrawerOpen(false)}
              aria-label="close drawer"
            >
              <X size={20} className="fill-interactive" width="bold" />
            </IconButton>

            <h2 className="grow text-center font-medium">
              {t("reservations.whichTripsToShow")}
            </h2>
            <div className="size-9 shrink-0" />
          </div>
          <OrdersTypeList
            value={selectedOrderType}
            onValueChange={(newValue) => {
              setSelectedOrderType(newValue);
              setOrderTypeDrawerOpen(false);
            }}
          />
        </>
      </SwipeableDrawer>
    </>
  );
};
