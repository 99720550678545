import { createContext, useContext, SetStateAction, Dispatch } from "react";
import { HotelBooking } from "../../types/types";

export interface HotelBookingContextValue {
  data: HotelBooking | null;
  setData: Dispatch<SetStateAction<HotelBooking | null>>;
  availabilityToken: string | null;
  setAvailabilityToken: (token: string | null) => void;
}

export const HotelBookingContext = createContext<HotelBookingContextValue>({
  data: null,
  setData: (data) => data,
  availabilityToken: null,
  setAvailabilityToken: () => {},
});

export const useHotelBookingContext = () => useContext(HotelBookingContext);
