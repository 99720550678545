import { useLayoutEffect } from "react";
import { useQuery } from "@tanstack/react-query";

import { BASE_API_URL } from "../routes";
import { CheckHotelsSearchProgressOutputDto } from "../types/codegen";
import { HotelSearchTokens } from "../types/types";
import api from "../api/api";
import { useHotelFiltersContext } from "../context/HotelFiltersContext";
import { useHotelSearchDataContext } from "../context/HotelSearchDataContext";
import { getNumberOfBookingNights } from "../utils/getNumberOfBookingNights";
import { BOARD_BASIS } from "../fallbackHotelsFiltersValues";
import { getPricesBasedOnProgressData } from "../utils/getPricesBasedOnProgressData";

export const useHotelSearchProgress = (tokens: HotelSearchTokens | null) => {
  const { filters, updateFilters } = useHotelFiltersContext();
  const { searchData } = useHotelSearchDataContext();

  const query = useQuery({
    queryKey: ["hotels", "search", "progress", tokens] as const,

    queryFn: ({ signal }) =>
      api.get<CheckHotelsSearchProgressOutputDto>(
        `${BASE_API_URL}/hotels/search/progress`,
        {
          params: tokens,
          signal,
        },
      ),
    select: (response) => response.data,
    enabled: Boolean(tokens),
    refetchInterval: (query) => {
      if (query.state.data?.data.data.status === "COMPLETED") {
        return false;
      }

      return 100;
    },
  });

  // using useLayoutEffect to avoid UI flickering
  useLayoutEffect(() => {
    if (query.isSuccess && query.data.data.status === "COMPLETED" && !filters) {
      const bookingNights = getNumberOfBookingNights({
        checkIn: searchData?.dates.checkIn,
        checkOut: searchData?.dates.checkOut,
      });
      const prices = getPricesBasedOnProgressData({
        minPrice: query.data.data.minPrice,
        maxPrice: query.data.data.maxPrice,
        bookingNights,
      });
      updateFilters({
        ...prices,
        boardBasis: query.data.data.boardBasis || BOARD_BASIS,
        starRating: query.data.data.stars?.map((star) => +star) || [
          1, 2, 3, 4, 5,
        ],
      });
    }
  }, [
    filters,
    updateFilters,
    searchData?.dates.checkIn,
    searchData?.dates.checkOut,
    query.isSuccess,
    query.data?.data.status,
    query.data?.data.minPrice,
    query.data?.data.maxPrice,
    query.data?.data.boardBasis,
    query.data?.data.stars,
  ]);

  return query;
};
