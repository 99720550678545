"use client";
import { useEffect, useMemo, useCallback } from "react";
import {
  ArrowLeft,
  ArrowRight,
  ArrowsLeftRight,
  CaretDown,
} from "@phosphor-icons/react";
import { useFlightSearchDataContext } from "@/app/lib/context/FlightSearchDataContext";
import { IconButton } from "@mui/material";
import { TripType, CabinClass, SortOrder } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import SingleDateCard from "@/app/ui/SingleDateCard";
import dayjs from "dayjs";
import { useRouter } from "@tanstack/react-router";
import { motion } from "motion/react";
import { FiltersIconButton } from "../FiltersIconButton";
import { FlightsSortValue } from "@/app/lib/types/types";

const sortOptionsLabels = {
  [SortOrder.ASC]: "flights.cheapFirst",
  [SortOrder.DESC]: "flights.expensiveFirst",
};

const cabinClassLabels = {
  [CabinClass.ECONOMY]: "flights.cabinClass.economy",
  [CabinClass.BUSINESS]: "flights.cabinClass.business",
  [CabinClass.PREMIUM_ECONOMY]: "flights.cabinClass.premiumEconomy",
  [CabinClass.FIRST_CLASS]: "flights.cabinClass.firstClass",
};

const activeDateIndex = 4;

type Props = {
  onFiltersButtonClick: () => void;
  onSortButtonClick: () => void;
  filtersSet: number | undefined;
  selectedSortValue: FlightsSortValue;
};

function Header({
  onFiltersButtonClick,
  onSortButtonClick,
  filtersSet,
  selectedSortValue,
}: Props) {
  const t = useTranslations();
  const { history } = useRouter();
  const { searchData, updateSearchData } = useFlightSearchDataContext();

  const departureDate = searchData!.dates.departure;

  // Generate 10 Mock Dates Data with Day and Month
  const dates = useMemo(() => {
    const dates = [];
    const currentDate = new Date(departureDate);

    // Adjust the start date to be 4 days before the given date
    currentDate.setDate(currentDate.getDate() - activeDateIndex);

    for (let i = 0; i < 10; i++) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }, [departureDate]);

  const passengerCount = useMemo(
    () => searchData!.adult + searchData!.child + searchData!.infantWithoutSeat,
    [searchData],
  );

  const handleDateChange = useCallback(
    (newDepartureDate: Date) => {
      if (dayjs(newDepartureDate).isSame(departureDate, "day")) {
        return;
      }

      updateSearchData((prevData) => {
        return {
          ...prevData,
          dates: {
            ...prevData?.dates,
            departure: newDepartureDate,
          },
        };
      });
    },
    [departureDate, updateSearchData],
  );

  // Scroll into active date
  useEffect(() => {
    const activeDate = dates[activeDateIndex];
    if (activeDate) {
      const element = document.getElementById(
        `${activeDate.getDate()}-${activeDate.getMonth()}`,
      );
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [dates]);

  return (
    <>
      <motion.div
        layoutId="hero"
        className="absolute top-0 z-20 h-38 w-full rounded-b-2xl bg-transparent bg-cover bg-no-repeat"
      />
      <div className="sticky top-0 z-20 flex flex-col gap-4 bg-slate-100 pt-4">
        <div className="align-center flex justify-between px-4">
          <IconButton onClick={() => history.back()} sx={{ padding: 0 }}>
            <ArrowLeft size={24} className="text-slate-1100 rtl:-scale-x-100" />
          </IconButton>

          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-center gap-2">
              <h3 className="text-slate-1100 max-w-[100px] truncate font-medium">
                {searchData!.from.city}
              </h3>
              {searchData!.tripType === TripType.ROUND_TRIP ? (
                <ArrowsLeftRight size={16} color="#003E2B" />
              ) : (
                <ArrowRight
                  size={16}
                  color="#003E2B"
                  className="rtl:-scale-x-100"
                />
              )}
              <h3 className="text-slate-1100 max-w-[100px] truncate font-medium">
                {searchData!.to.city}
              </h3>
            </div>
            <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
              {searchData!.tripType === TripType.ONE_WAY
                ? dayjs(searchData!.dates.departure).format("ddd, DD MMM")
                : `${dayjs(searchData!.dates.departure).format(
                    "ddd, DD MMM",
                  )} - ${dayjs(searchData!.dates.return).format("ddd, DD MMM")}`}
              , {passengerCount} {t("travellers.title")},{" "}
              {t(cabinClassLabels[searchData!.cabinClass])}
            </span>
          </div>

          <FiltersIconButton
            onClick={onFiltersButtonClick}
            filtersSetCount={filtersSet}
          />
        </div>

        {searchData!.tripType === TripType.ONE_WAY && (
          <div className="flex overflow-x-auto pb-4">
            <div className="flex gap-4 px-4">
              {dates.map((date, i) => (
                <SingleDateCard
                  key={`${date.getDate()}-${date.getMonth()}`}
                  date={date}
                  active={i === activeDateIndex}
                  onChange={() => handleDateChange(date)}
                  disabled={dayjs(date).isBefore(dayjs(), "day")}
                />
              ))}
            </div>
          </div>
        )}

        {/* TODO: hidden until DESC sorting option is enabled on BE */}
        <div className="hidden">
          {/* <div className="flex items-center justify-between"> */}
          <button
            onClick={onSortButtonClick}
            className="text-primary active:border-accent-700 inline-flex items-center gap-2 rounded-2xl bg-white px-4 py-2 text-sm"
          >
            <CaretDown aria-hidden />{" "}
            {t(sortOptionsLabels[selectedSortValue.order])}
          </button>
          <div />
        </div>
      </div>
    </>
  );
}

export default Header;
