import { queryOptions } from "@tanstack/react-query";

import api from "../api/api";
import {
  FindUserFlightReservationOutputDto,
  FindUserHotelReservationOutputDto,
} from "../types/codegen";

export const hotelReservationQueryOptions = (reservationId?: number) =>
  queryOptions({
    queryKey: ["reservations", "hotels", reservationId],
    queryFn: ({ queryKey: [, , reservationId], signal }) =>
      api.get<FindUserHotelReservationOutputDto>(
        `/hotels/reservations/${reservationId}`,
        {
          signal,
        },
      ),
    select: (response) => response.data?.data,
    enabled: Boolean(reservationId),
  });

export const flightReservationQueryOptions = (reservationId?: number) =>
  queryOptions({
    queryKey: ["reservations", "flights", reservationId],
    queryFn: ({ queryKey: [, , reservationId], signal }) =>
      api.get<FindUserFlightReservationOutputDto>(
        `/flights/reservations/${reservationId}`,
        {
          signal,
        },
      ),
    select: (response) => response.data?.data,
    enabled: Boolean(reservationId),
  });
