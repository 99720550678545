import { useTranslations } from "use-intl";
import { calculateOverallFlightTime } from "@/app/lib/utils/calculateFlightTime";
import { extractAirlineCarriers } from "@/app/lib/utils/extractAirlineCarriers";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";
import { FlightInfo, FlightOverallTime } from "../flights/FlightInfo";
import AirlineAvatarGroup from "../AirlineAvatarGroup";

function FlightSummary({
  selectedFlight,
}: {
  selectedFlight: SingleFightDetailsData;
}) {
  const t = useTranslations();
  const departureFlight = selectedFlight?.flights?.[0] || null;
  const departureSegments = departureFlight?.segments || [];
  const departureStops = departureFlight?.stops || [];
  const departureOverallTime = calculateOverallFlightTime(departureFlight);

  const returnFlight =
    ((selectedFlight?.flights?.length || 0) > 1 &&
      selectedFlight?.flights?.[selectedFlight?.flights.length - 1]) ||
    null;
  const returnSegments = returnFlight?.segments || [];
  const returnStops = returnFlight?.stops || [];

  const departureAirlines = extractAirlineCarriers(departureSegments) || [];
  const returnAirlines = extractAirlineCarriers(returnSegments) || [];
  const returnOverallTime = calculateOverallFlightTime(returnFlight);

  return (
    <div className="flex w-full flex-col">
      <div className="flex w-full">
        <span className="text-title text-base font-medium">
          {t("flights.flightSummary")}
        </span>
      </div>
      <span className="section-title mt-6">{t("flights.flightDetails")}</span>
      <div className="section-card flex flex-col gap-4">
        <div className="flex w-full items-center gap-2">
          <div className="flex w-fit">
            <AirlineAvatarGroup airlines={departureAirlines} />
          </div>
          <div>
            {departureAirlines?.length &&
              [...departureAirlines]
                ?.splice(0, 2)
                .map((airline, index, array) => {
                  const doesOperatingCarrierDiffer =
                    airline.name !== airline.operatingCarrier.name;

                  return (
                    <span
                      key={airline.name || index}
                      className="text-primary text-sm"
                    >
                      {airline.name || t("unknownAirline")}
                      {doesOperatingCarrierDiffer ? (
                        <>
                          {" "}
                          <span className="text-tertiary">
                            ({t("flights.operatedBy")}{" "}
                            {airline.operatingCarrier.name})
                          </span>
                        </>
                      ) : null}
                      {array.length > index + 1 ? ", " : ""}
                    </span>
                  );
                })}
            {departureAirlines?.length - 2 > 0 && (
              <span className="text-primary mr-1 text-sm">
                +{departureAirlines.length - 2}
              </span>
            )}
          </div>
        </div>

        <FlightInfo
          origin={{
            date: departureSegments[0].departure.date,
            time: departureSegments[0].departure.time,
            code: departureSegments[0].departure.airport.code,
          }}
          destination={{
            date: departureSegments[departureSegments.length - 1].arrival.date,
            time: departureSegments[departureSegments.length - 1].arrival.time,
            code: departureSegments[departureSegments.length - 1].arrival
              .airport.code,
          }}
        >
          <FlightOverallTime
            overallTime={t("durationValue", departureOverallTime)}
            stops={departureStops?.length}
          />
        </FlightInfo>
      </div>
      {/* Return flight if its round trip */}
      {returnFlight && (
        <div className="section-card mt-2 flex flex-col gap-4">
          <div className="flex w-full items-center gap-2">
            <div className="flex w-fit">
              <AirlineAvatarGroup airlines={returnAirlines} />
            </div>
            <div>
              {returnAirlines?.length &&
                [...returnAirlines]
                  ?.splice(0, 2)
                  .map((airline, index, array) => {
                    const doesOperatingCarrierDiffer =
                      airline.name !== airline.operatingCarrier.name;

                    return (
                      <span
                        key={airline.name || index}
                        className="text-primary text-sm"
                      >
                        {airline.name || t("unknownAirline")}
                        {doesOperatingCarrierDiffer ? (
                          <>
                            {" "}
                            <span className="text-tertiary">
                              ({t("flights.operatedBy")}{" "}
                              {airline.operatingCarrier.name})
                            </span>
                          </>
                        ) : null}
                        {array.length > index + 1 ? ", " : ""}
                      </span>
                    );
                  })}

              {returnAirlines?.length - 2 > 0 && (
                <span className="text-primary mr-1 text-sm">
                  +{returnAirlines.length - 2}
                </span>
              )}
            </div>
          </div>

          <FlightInfo
            origin={{
              date: returnSegments[0].departure.date,
              time: returnSegments[0].departure.time,
              code: returnSegments[0].departure.airport.code,
            }}
            destination={{
              date: returnSegments[returnSegments.length - 1].arrival.date,
              time: returnSegments[returnSegments.length - 1].arrival.time,
              code: returnSegments[returnSegments.length - 1].arrival.airport
                .code,
            }}
          >
            <FlightOverallTime
              overallTime={t("durationValue", returnOverallTime)}
              stops={returnStops.length}
            />
          </FlightInfo>
        </div>
      )}
    </div>
  );
}

export default FlightSummary;
