import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { HotelOffersContextProvider } from "@/app/lib/context/HotelOffersContext";
import { useHotelsTokensContext } from "@/app/lib/context/HotelsTokensContext";
import { hotelOfferQueryOptions } from "@/app/lib/hooks/useHotelOffer";
import { hotelRoomAvailabilityQueryOptions } from "@/app/lib/hooks/useHotelRoomAvailaility";
import { useRouteConfig } from "@/app/lib/hooks/useRouteConfig";
import Loader from "@/app/ui/AnimatedLoader";
import { HotelBookingHeader } from "@/app/ui/hotel/HotelBookingHeader";
import { HotelOfferError } from "@/app/ui/hotel/HotelOfferError";
import { OfferNotAvailableError } from "@/app/ui/OfferNotAvailableError";
import PageLoader from "@/app/ui/PageLoader";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet } from "@tanstack/react-router";
import { useEffect } from "react";

export const Route = createFileRoute(
  "/_authenticated/_hotels/_hotel-offer/hotels/$id",
)({
  component: RouteComponent,
});

function RouteComponent() {
  const { id } = Route.useParams();
  const { data: hotel } = useOptionalHotelDataContext();
  const { data: tokens } = useHotelsTokensContext();
  const { data: booking, setAvailabilityToken } = useHotelBookingContext();

  const routeConfig = useRouteConfig();

  // Fetches hotel offers based on the search result token
  const { data, isLoading, isError, error, isFetched } = useQuery(
    hotelOfferQueryOptions(
      tokens?.searchResultKey && tokens?.searchResultToken
        ? {
            hotelId: id,
            searchResultKey: tokens.searchResultKey,
            searchResultToken: tokens.searchResultToken,
          }
        : null,
    ),
  );

  const currentOffer = data?.find(
    (offer) =>
      offer.offerId === booking?.offerId &&
      offer.packageToken === booking?.packageToken,
  );

  // TODO: group all hooks (useEffects and query) in separate hook with booking logic

  // Fetches availability token once user has selected a room from the offer
  // Required for booking
  const {
    data: availabilityToken,
    isError: isAvailabilityError,
    error: availabilityError,
    isSuccess: isAvailabilityTokenQuerySuccess,
    isLoading: isAvailabilityTokenQueryLoading,
  } = useQuery(
    hotelRoomAvailabilityQueryOptions(
      booking &&
        currentOffer &&
        tokens?.searchResultKey &&
        tokens?.searchResultToken
        ? {
            hotelId: id,
            searchResultKey: tokens.searchResultKey,
            searchResultToken: tokens.searchResultToken,
            packageToken: currentOffer.packageToken,
            offerId: booking.offerId,
            roomTokens: booking.rooms.map((room) => room.roomToken),
          }
        : null,
    ),
  );

  useEffect(() => {
    if (isAvailabilityTokenQueryLoading) {
      setAvailabilityToken(null);
    }
  }, [isAvailabilityTokenQueryLoading, setAvailabilityToken]);

  useEffect(() => {
    if (isAvailabilityTokenQuerySuccess) {
      setAvailabilityToken(availabilityToken.availabilityToken);
    }
  }, [
    availabilityToken,
    isAvailabilityTokenQuerySuccess,
    setAvailabilityToken,
  ]);

  if (isLoading) {
    return (
      <PageLoader>
        <Loader icon="buildings" />
      </PageLoader>
    );
  }

  if (isFetched && !data?.length) {
    return <OfferNotAvailableError variant="hotel" />;
  }

  if (isError || isAvailabilityError) {
    return <HotelOfferError error={error || availabilityError} />;
  }

  const isGallery = routeConfig.hotelStep?.presentationMode === "modal";

  return (
    <HotelOffersContextProvider data={data!}>
      <HotelBookingHeader
        title={
          isGallery ? (
            <div className="flex items-center justify-center gap-2">
              <h3 className="text-title font-medium">{hotel?.name}</h3>
            </div>
          ) : null
        }
        {...(routeConfig.hotelStep &&
        routeConfig.hotelStep.presentationMode !== "modal"
          ? { step: routeConfig.hotelStep.stepNumber, showStepper: true }
          : { showStepper: false })}
        presentationMode={routeConfig.hotelStep?.presentationMode}
      />
      <Outlet />
    </HotelOffersContextProvider>
  );
}
