import { PropsWithChildren } from "react";

import { useFlightSearchTokens } from "../../hooks/useFlightSearchTokens";
import { FlightsTokensContext } from "./FlightsTokensContext";

export const FlightsTokensProvider = ({ children }: PropsWithChildren) => {
  const contextValue = useFlightSearchTokens();

  return (
    <FlightsTokensContext.Provider value={contextValue}>
      {children}
    </FlightsTokensContext.Provider>
  );
};
