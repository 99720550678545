import { useInfiniteQuery } from "@tanstack/react-query";

import { BASE_API_URL } from "../routes";
import { IHotelsFilters } from "../types/interfaces";
import { HotelsSortValue } from "../types/types";
import { FindHotelsOutputDto } from "../types/codegen";
import api from "../api/api";

const PAGE_SIZE = 20;

type Params = {
  searchResultKey: string | undefined;
  searchResultToken: string | undefined;
  filters: IHotelsFilters | null;
  bookingNights?: number;
  shouldFetch: boolean;
  sortValue: HotelsSortValue;
};

export const useHotelSearchResults = ({
  searchResultKey,
  searchResultToken,
  filters,
  bookingNights = 1,
  shouldFetch,
  sortValue,
}: Params) => {
  const params = filters
    ? {
        searchResultKey,
        searchResultToken,
        perPage: PAGE_SIZE,
        sortField: sortValue.field,
        sortOrder: sortValue.order,
        minPrice: filters.priceRange[0] * bookingNights,
        maxPrice: filters.priceRange[1] * bookingNights,
        stars: filters.starRating,
        boardBasis: filters.boardBasis,
      }
    : null;

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isError } =
    useInfiniteQuery({
      queryKey: ["hotels", "search", "results", params] as const,
      queryFn: ({ pageParam, signal }) =>
        api
          .get<FindHotelsOutputDto>(`${BASE_API_URL}/hotels`, {
            params: {
              ...params,
              page: pageParam,
            },
            signal,
          })
          .then((res) => res.data),
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.length === PAGE_SIZE ? pages.length + 1 : undefined,
      enabled: Boolean(
        searchResultKey && searchResultToken && shouldFetch && filters,
      ),
    });

  const isEmpty = data?.pages.flatMap((page) => page.data).length === 0;
  const hasReachedEnd = !hasNextPage;

  return {
    data,
    isLoading,
    isFetching,
    isError,
    isEmpty,
    hasReachedEnd,
    fetchNextPage,
  };
};
