import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";

import { getQiToken } from "@/app/lib/api/api";
import Footer from "@/app/ui/Footer";
import { MyTrip } from "@/app/ui/my-trips/MyTrip";

export const Route = createFileRoute("/_authenticated/trips_/$scope/$id")({
  params: {
    parse: (
      rawParams: Record<string, string>,
    ): { scope: "flights" | "hotels"; id: string } => {
      if (!["flights", "hotels"].includes(rawParams.scope)) {
        throw new Error("Invalid scope");
      }
      return {
        scope: rawParams.scope as "flights" | "hotels",
        id: rawParams.id,
      };
    },
  },
  component: RouteComponent,
});

function RouteComponent() {
  const qi = getQiToken();
  const { scope, id } = Route.useParams();

  return (
    <main
      className={clsx({
        main: !qi,
        "main-qi": !!qi,
      })}
    >
      <div className="min-h-full w-full overflow-auto p-4">
        <MyTrip scope={scope} orderId={id} />
      </div>

      <Footer />
    </main>
  );
}
