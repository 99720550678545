import Stepper from "@/app/ui/flight-purchase/Stepper";
import { WhatsAppButton } from "@/app/ui/WhatsAppButton";
import { useFlightSelectionPayloadContext } from "@/app/lib/context/FlightSelectionPayloadContext";
import { useFlightSearchDataContext } from "@/app/lib/context/FlightSearchDataContext";
import { SingleFlightDetailsProvider } from "@/app/lib/context/SingleFlightDetailsContext";
import {
  flightAvailabilityQueryOptions,
  transformFlightAvailabilityData,
} from "@/app/lib/hooks/useFlightAvailability";
import CancelFlightDrawer from "@/app/ui/flight-purchase/CancelFlightPurchase";
import {
  createFileRoute,
  Navigate,
  Outlet,
  useMatchRoute,
  useRouter,
} from "@tanstack/react-router";
import { useState } from "react";
import Header from "@/app/ui/flight-purchase/Header";
import { getQiToken } from "@/app/lib/api/api";
import { motion } from "motion/react";
import clsx from "clsx";
import { TravellersProvider } from "@/app/lib/context/TravellersContext";
import { useQuery } from "@tanstack/react-query";
import { FlightOfferError } from "@/app/ui/flight-purchase/FlightOfferError";
import * as Sentry from "@sentry/react";
import { usePurchaseStateContext } from "@/app/lib/context/PurchaseStateContext";
import { BookingError } from "@/app/ui/errors/BookingError";
import { formatCurrency } from "@/app/lib/utils/currency";
import { useRouteConfig } from "@/app/lib/hooks/useRouteConfig";

export const Route = createFileRoute("/_authenticated/_flights/_flight-offer")({
  component: RouteComponent,
  errorComponent: (props) => {
    Sentry.captureException(props.error);
    return <BookingError {...props} type="flights" />;
  },
});

function RouteComponent() {
  return (
    <TravellersProvider>
      <Content />
    </TravellersProvider>
  );
}

function Content() {
  const { history } = useRouter();
  const routeConfig = useRouteConfig();
  const { selectedFlightPayload, selectedFlightFromOfferList } =
    useFlightSelectionPayloadContext();
  const { searchData } = useFlightSearchDataContext();
  const { purchaseState } = usePurchaseStateContext();
  const { data, isError, error, isLoading } = useQuery(
    flightAvailabilityQueryOptions(selectedFlightPayload),
  );

  const [cancelFlightOpen, setCancelFlightOpen] = useState(false);

  const matches = useMatchRoute();

  const isTicketPurchasedRoute = matches({
    to: "/flights/offer/$id/step/final",
    from: "/",
  });

  const flightOffer = {
    ...selectedFlightFromOfferList,
    ...data,
    flights:
      selectedFlightFromOfferList?.flights.map((flight) =>
        transformFlightAvailabilityData(flight),
      ) || data?.flights,
    index: selectedFlightFromOfferList?.index,
    totalPrice: formatCurrency(
      data ? data.totalPrice : selectedFlightFromOfferList?.totalPrice,
      "IQD",
    ),
  };

  if (!isTicketPurchasedRoute && purchaseState.wasPurchaseDone) {
    return <Navigate to="/" />;
  }

  if (isError) return <FlightOfferError error={error} />;
  const qi = getQiToken();
  return (
    <>
      <motion.div
        layoutId="hero"
        className="bg-hero-pattern absolute top-0 z-20 w-full rounded-b-2xl bg-cover bg-no-repeat"
      />
      <main
        className={clsx("no-footer", {
          "with-drawer": !!routeConfig.drawer,
          main: !qi,
          "main-qi": !!qi,
        })}
      >
        <div className="mt-2">
          {!isTicketPurchasedRoute ? (
            <Header
              searchData={searchData!}
              onCancel={() => setCancelFlightOpen(true)}
              onBack={() => history.back()}
            />
          ) : null}

          {routeConfig.flightStep ? (
            <Stepper
              stepNumber={routeConfig.flightStep.stepNumber}
              stepTitle={routeConfig.flightStep.stepTitle}
            />
          ) : null}

          <div className="flex flex-1 flex-col gap-4 pb-4">
            <SingleFlightDetailsProvider
              data={flightOffer}
              isAdditionalDataLoading={isLoading}
            >
              <Outlet />
            </SingleFlightDetailsProvider>
          </div>

          <CancelFlightDrawer
            open={cancelFlightOpen}
            closeDrawer={() => setCancelFlightOpen(false)}
          />

          {!isTicketPurchasedRoute && <WhatsAppButton bottomPosition="20vh" />}
        </div>
      </main>
    </>
  );
}
