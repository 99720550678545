import { useEffect, useMemo, useState } from "react";
import {
  createRootRoute,
  Outlet,
  useRouterState,
} from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useQuery } from "@tanstack/react-query";
import { IntlProvider } from "use-intl";
import dayjs from "dayjs";
import "dayjs/locale/ar";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import enMessages from "../../messages/en.json";
import arMessages from "../../messages/ar.json";
import { SnackbarProvider } from "@/app/lib/context/SnackbarContext";
import { FlightSearchDataProvider } from "@/app/lib/context/FlightSearchDataContext";
import { FlightSelectionPayloadProvider } from "@/app/lib/context/FlightSelectionPayloadContext";
import { HotelDataProvider } from "@/app/lib/context/HotelDataContext";
import { applicationStateQueryOptions } from "../app/lib/hooks/useApplicationStateTurnedOff";
import { isProduction, isStaging } from "@/app/lib/constants";
import { HotelBookingContextProvider } from "@/app/lib/context/HotelBookingContext";
import { HotelSearchDataProvider } from "@/app/lib/context/HotelSearchDataContext";
import { HotelFiltersProvider } from "@/app/lib/context/HotelFiltersContext";
import { HotelSortProvider } from "@/app/lib/context/HotelSortContext";
import { FlightSortProvider } from "@/app/lib/context/FlightSortContext";
import { FlightFiltersProvider } from "@/app/lib/context/FlightFiltersContext";
import { UnderMaintenance } from "../app/ui/UnderMaintenance";
import { NetworkConnectionGuard } from "@/app/ui/NetworkConnectionGuard";
import { MuiThemeProvider } from "@/app/lib/context/MuiThemeProvider";
import { getDir } from "@/app/lib/hooks/useLocaleInfo";
import Loader from "../app/ui/AnimatedLoader";
import PageLoader from "../app/ui/PageLoader";
import api, { apiV2 } from "@/app/lib/api/api";

const messages = {
  en: enMessages,
  ar: arMessages,
};

type FlightBookingStep = {
  stepNumber: number;
  stepTitle: string;
};

type HotelBookingStep = {
  stepNumber: number;
  presentationMode?: "modal";
};

declare module "@tanstack/react-router" {
  interface StaticDataRouteOption {
    hotelStep?: HotelBookingStep;
    flightStep?: FlightBookingStep;
    drawer?: boolean;
  }
}

const defaultLocale = "ar";

const RouteComponent = () => {
  const router = useRouterState();
  const [locale, setLocale] = useState<keyof typeof messages>();
  const { data: underMaintenance, isLoading } = useQuery(
    applicationStateQueryOptions(),
  );

  useEffect(() => {
    my?.setNavigationBar(
      router.location.pathname === "/"
        ? {
            backgroundColor: "#003E2B",
            image: `${location.origin}/tzlogo.png`,
          }
        : {
            backgroundColor: "#F9F9FB",
            // this is a workaround to hide the logo, as there is no api explicitely doing that
            // title and image are mutually exclusive, so setting title (must be non-empty string) hides the logo
            title: " ",
          },
    );
  }, [router.location.pathname]);

  useEffect(() => {
    // TODO: remove this statement when we decide to support eng and ar versions on production
    if (isProduction) {
      setLocale(defaultLocale);
      return;
    }

    if (!my?.getSystemInfo) {
      setLocale(defaultLocale);
      return;
    }

    my.getSystemInfo({
      success: (res) => {
        const fetchedLocale = res.language.split("-")[0];

        const isAllowedLocale = (
          locale: string,
        ): locale is keyof typeof messages => {
          return Object.keys(messages).includes(fetchedLocale);
        };

        setLocale(
          isAllowedLocale(fetchedLocale) ? fetchedLocale : defaultLocale,
        );
      },
      fail: () => {
        setLocale(defaultLocale);
      },
    });
  }, []);

  useEffect(() => {
    if (!locale) {
      return;
    }

    api.defaults.headers.common["Accept-Language"] = locale;
    apiV2.defaults.headers.common["Accept-Language"] = locale;
    dayjs.locale(locale);
    document.documentElement.dir = getDir(locale);
  }, [locale]);

  const cache = useMemo(() => {
    const dir = getDir(locale || defaultLocale);

    return createCache({
      key: `muicache-${dir}`,
      stylisPlugins: [prefixer, ...(dir === "rtl" ? [rtlPlugin] : [])],
    });
  }, [locale]);

  if (!locale) {
    return null;
  }

  return (
    <>
      <IntlProvider locale={locale} messages={messages[locale]}>
        <CacheProvider value={cache}>
          <MuiThemeProvider>
            <SnackbarProvider>
              {isLoading || underMaintenance ? (
                <main className="h-dvh w-dvh">
                  {isLoading ? (
                    <PageLoader>
                      <Loader />
                    </PageLoader>
                  ) : (
                    <UnderMaintenance />
                  )}
                </main>
              ) : (
                // TODO: Move this to a specific route
                <FlightSearchDataProvider>
                  <FlightSelectionPayloadProvider>
                    <FlightSortProvider>
                      <FlightFiltersProvider>
                        <HotelSearchDataProvider>
                          <HotelFiltersProvider>
                            <HotelSortProvider>
                              <HotelDataProvider>
                                <HotelBookingContextProvider>
                                  <NetworkConnectionGuard>
                                    <Outlet />
                                  </NetworkConnectionGuard>
                                </HotelBookingContextProvider>
                              </HotelDataProvider>
                            </HotelSortProvider>
                          </HotelFiltersProvider>
                        </HotelSearchDataProvider>
                      </FlightFiltersProvider>
                    </FlightSortProvider>
                  </FlightSelectionPayloadProvider>
                </FlightSearchDataProvider>
              )}
            </SnackbarProvider>
          </MuiThemeProvider>
        </CacheProvider>
      </IntlProvider>

      {isStaging ? (
        <div dir="ltr">
          <ReactQueryDevtools initialIsOpen={false} />
          <TanStackRouterDevtools />
        </div>
      ) : null}
    </>
  );
};

export const Route = createRootRoute({
  component: RouteComponent,
});
