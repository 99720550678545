import { isStaging } from "./constants";

const getTrace = () => {
  try {
    throw new Error();
  } catch (error) {
    if (error instanceof Error) {
      return error.stack?.split("\n").slice(3).join("\n");
    }
    return "unknown";
  }
};

export type LogType = "log" | "error" | "warn" | "info";

export type LogEntry = {
  type: LogType;
  args: unknown[];
  trace: string | undefined;
  timestamp: number;
};

class StackStore {
  private stack: LogEntry[] = [];
  private subscribers = new Set<() => void>();

  getStack() {
    return this.stack;
  }

  getStackSize() {
    return this.stack.length;
  }

  subscribe(callback: () => void) {
    this.subscribers.add(callback);
    return () => this.subscribers.delete(callback);
  }

  push({ type, args }: { type: LogType; args: unknown[] }) {
    this.stack.push({ type, args, trace: getTrace(), timestamp: Date.now() });
    this.notify();
  }

  clear() {
    this.stack = [];
    this.notify();
  }

  private notify() {
    this.subscribers.forEach((callback) => {
      callback();
    });
  }
}

export const store = new StackStore();

if (isStaging) {
  const oldConsole = {
    log: console.log,
    error: console.error,
    warn: console.warn,
    info: console.info,
  };

  window.console.log = function (...args) {
    store.push({ type: "log", args });
    oldConsole.log(...args);
  };

  window.console.error = function (...args) {
    store.push({ type: "error", args });
    oldConsole.error(...args);
  };

  window.console.warn = function (...args) {
    store.push({ type: "warn", args });
    oldConsole.warn(...args);
  };

  window.console.info = function (...args) {
    store.push({ type: "info", args });
    oldConsole.info(...args);
  };
}
