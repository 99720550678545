import Footer from "@/app/ui/Footer";
import { Search } from "@/app/ui/search/Search";
import { getQiToken } from "@/app/lib/api/api";
import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { motion } from "motion/react";

export const Route = createFileRoute("/_authenticated/")({
  validateSearch: (
    search: Record<string, unknown>,
  ): { category?: "flights" | "hotels" } => {
    return {
      category: search.category as "flights" | "hotels",
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const { category } = Route.useSearch();
  const qi = getQiToken();
  return (
    <>
      <motion.div
        layoutId="hero"
        className="bg-hero-pattern absolute top-0 h-96 w-full bg-cover bg-no-repeat"
      />
      <main className={clsx({ main: !qi, "main-qi": !!qi })}>
        <div className="z-10 w-full p-4">
          <div className="flex w-full max-w-5xl flex-col items-center justify-between font-mono text-sm">
            <Search category={category || "flights"} />
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}
