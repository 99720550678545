import { OfferIsNotAvailableAnymoreOutputDto } from "@/app/lib/types/codegen";
import { AxiosError } from "axios";
import { isOfferIsNotAvailableAnymoreError } from "@/app/lib/assertions";
import { OfferNotAvailableError } from "../OfferNotAvailableError";
import { GeneralPageError } from "../errors/GeneralPageError";

interface HotelOfferErrorProps {
  error: Error | AxiosError<OfferIsNotAvailableAnymoreOutputDto> | null;
}

export const HotelOfferError = ({ error }: HotelOfferErrorProps) => {
  if (error && isOfferIsNotAvailableAnymoreError(error)) {
    return <OfferNotAvailableError variant="hotel" />;
  }
  return (
    <GeneralPageError
      backOptions={{ to: "/", search: { category: "hotels" } }}
    />
  );
};
