"use client";

import { ReactNode, useMemo } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useLocaleInfo } from "../hooks/useLocaleInfo";

export const MuiThemeProvider = ({ children }: { children: ReactNode }) => {
  const { dir } = useLocaleInfo();

  const theme = useMemo(
    () =>
      createTheme({
        direction: dir,
        // it's not possible to use CSS variables here:
        // https://github.com/mui/material-ui/issues/12827
        palette: {
          primary: {
            main: "#003E2B", // same as --color-surface-accent-stronger
          },
          error: {
            main: "#DE370C", // same as --color-semantic-error-strong
          },
          success: {
            main: "#2e8285", // same as --color-semantic-success-strong
          },
        },
        typography: {
          fontFamily: `"Helvetica Neue", "Roboto", "sans-serif"`,
        },
        components: {
          MuiBadge: {
            styleOverrides: {
              badge: {
                height: "14px",
                minWidth: "14px",
                fontWeight: "bold",
                fontSize: "10px",
                lineHeight: "12px",
                padding: 0,
              },
            },
          },
          MuiLink: {
            styleOverrides: {
              root: {
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: 14,
              },
            },
          },
          MuiRadio: {
            styleOverrides: {
              root: {
                "& .MuiSvgIcon-root": {
                  fontSize: 20,
                },
                "&.Mui-checked": {
                  color: "#003E2B", // Replace with your desired color
                },
              },
            },
          },
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                "& .MuiBottomNavigationAction-label": {
                  marginTop: "2px",
                  fontWeight: "bold",
                  fontSize: "12px",
                  lineHeight: "16px",
                },
                "& .Mui-selected": {
                  color: "var(--color-surface-accent-stronger)",
                  zIndex: 2,
                  "& .MuiTouchRipple-root": {
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    maxWidth: "74px",
                    opacity: 0.4,
                    background:
                      "radial-gradient(circle at 50% 50%, #A7FDC3 40%, #FFFFFF 60%)",
                    zIndex: -1,
                  },
                },
              },
            },
          },
          MuiChip: {
            styleOverrides: {
              root: {
                variants: [
                  {
                    props: { color: "primary" },
                    style: {
                      backgroundColor: "#003E2B",
                      color: "#fff",
                    },
                  },
                  {
                    props: { color: "success" },
                    style: {
                      backgroundColor: "var(--color-core-rahal-100)",
                      color: "var(--color-core-rahal-600)",
                    },
                  },
                  {
                    props: { color: "warning" },
                    style: {
                      backgroundColor: "rgba(251, 230, 208, 0.5)",
                      color: "var(--color-amber-600)",
                    },
                  },
                  {
                    props: { color: "error" },
                    style: {
                      backgroundColor: "rgba(254, 243, 242, 1)",
                      color: "var(--color-semantic-error-strong)",
                    },
                  },
                ],
              },
            },
          },
          MuiCheckbox: {
            styleOverrides: {
              root: {
                "&.Mui-checked": {
                  backgroundColor: "#fff",
                  color: "#003E2B",
                },
              },
            },
          },
          MuiSlider: {
            styleOverrides: {
              root: {
                height: 6,
              },
              rail: {
                backgroundColor: "var(--color-stroke-neutral-soft)",
                opacity: 1,
              },
              thumb: {
                width: 16,
                height: 24,
                backgroundColor: "var(--color-surface-accent-medium)",
                borderRadius: "4px",
                border: "7px solid var(--color-surface-accent-stronger)",
              },
            },
          },
          MuiSnackbarContent: {
            styleOverrides: {
              root: {
                maxWidth: "var(--container-2xs)",
                borderRadius: "var(--radius-lg)",
                backgroundColor: "var(--color-zinc-800)",
                boxShadow: "var(--shadow-md)",
              },
            },
          },
          MuiButton: {
            styleOverrides: {
              root: {
                variants: [
                  {
                    props: { color: "primary", variant: "contained" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "var(--color-surface-pressed-accent-strong)",
                      },
                      "&:disabled, &:disabled:focus": {
                        backgroundColor:
                          "var(--color-surface-disabled-accent-strong)",
                        color: "var(--color-text-inverted)",
                      },
                    },
                  },
                  {
                    props: { color: "primary", variant: "outlined" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-surface-accent-stronger) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { color: "primary", variant: "text" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-surface-accent-stronger) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { color: "error", variant: "contained" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "var(--color-semantic-error-pressed-strong)",
                      },
                    },
                  },
                  {
                    props: { color: "error", variant: "outlined" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-semantic-error-strong) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { color: "error", variant: "text" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-semantic-error-strong) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { color: "success", variant: "contained" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "var(--color-semantic-success-pressed-strong)",
                      },
                    },
                  },
                  {
                    props: { color: "success", variant: "outlined" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-semantic-success-strong) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { color: "success", variant: "text" },
                    style: {
                      "&:focus:not([disabled])": {
                        backgroundColor:
                          "rgb(from var(--color-semantic-success-strong) r g b / 10%)",
                      },
                    },
                  },
                  {
                    props: { size: "small" },
                    style: {
                      fontSize: "14px",
                      lineHeight: "20px",
                      paddingTop: "calc(var(--spacing)*2)",
                      paddingBottom: "calc(var(--spacing)*2)",
                      paddingLeft: "calc(var(--spacing)*4)",
                      paddingRight: "calc(var(--spacing)*4)",
                    },
                  },
                  {
                    props: { size: "medium" },
                    style: {
                      fontSize: "16px",
                      lineHeight: "22px",
                      paddingTop: "calc(var(--spacing)*3)",
                      paddingBottom: "calc(var(--spacing)*3)",
                      paddingLeft: "calc(var(--spacing)*6)",
                      paddingRight: "calc(var(--spacing)*6)",
                    },
                  },
                  {
                    props: { size: "large" },
                    style: {
                      fontSize: "18px",
                      lineHeight: "26px",
                      paddingTop: "calc(var(--spacing)*3)",
                      paddingBottom: "calc(var(--spacing)*3)",
                      paddingLeft: "calc(var(--spacing)*8)",
                      paddingRight: "calc(var(--spacing)*8)",
                    },
                  },
                  {
                    props: {
                      variant: "contained",
                      style: {
                        "&:disabled, &:disabled:focus": {
                          backgroundColor:
                            "var(--color-surface-disabled-accent-strong)",
                          borderColor:
                            "var(--color-surface-disabled-accent-strong)",
                          color: "var(--color-text-inverted)",
                        },
                      },
                    },
                  },
                  {
                    props: {
                      variant: "outlined",
                      style: {
                        "&:disabled, &:disabled:focus": {
                          backgroundColor: "transparent",
                          borderColor:
                            "var(--color-surface-disabled-accent-strong)",
                          color: "var(--color-text-disabled-interactive)",
                        },
                      },
                    },
                  },
                  {
                    props: {
                      variant: "text",
                      style: {
                        "&:disabled, &:disabled:focus": {
                          backgroundColor: "transparent",
                          color: "var(--color-text-disabled-interactive)",
                        },
                      },
                    },
                  },
                ],
                textTransform: "none",
                fontWeight: "var(--font-weight-bold)",
                borderRadius: "999px",
              },
            },
          },
          MuiDrawer: {
            styleOverrides: {
              paper: {
                margin: "auto",
                maxWidth: "var(--container-xl)",
                borderRadius: "32px 32px 0 0",
                padding: "16px 16px 32px 16px",
              },
            },
          },
          MuiTextField: {
            defaultProps: {
              variant: "outlined",
            },
            styleOverrides: {
              root: {
                variants: [
                  {
                    props: { variant: "outlined" },
                    style: {
                      "& .MuiInputBase-root": {
                        borderRadius: "16px",
                      },
                      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#E7E9EE",
                        "legend > span": {
                          display: "none",
                        },
                      },
                      "& .MuiOutlinedInput-input": {
                        paddingTop: "28px",
                        paddingBottom: "6px",
                      },
                      "& .MuiInputLabel-shrink": {
                        transform: "translate(14px, 6px) scale(0.75)",
                      },
                      "& .MuiInputAdornment-root": {
                        transform: "translateY(10px)",
                      },
                    },
                  },
                ],
                "& .MuiSelect-select": {
                  borderRadius: "16px !important",
                },
                "& .MuiSvgIcon-root": {
                  display: "none",
                },
                "& .Mui-focused": {
                  "& .MuiInputBase-input": {
                    borderColor: "var(--color-surface-accent-stronger)",
                  },
                },
              },
            },
          },
          MuiMobileStepper: {
            styleOverrides: {
              root: {
                width: "100%",
                paddingInline: 0,
                backgroundColor: "transparent",
                "& .MuiLinearProgress-root": {
                  width: "100%",
                  background: "#E7E9EE",
                  borderRadius: "4px",
                },
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "var(--color-surface-accent-stronger)",
                  borderRadius: "4px",
                },
              },
            },
          },
        },
      }),
    [dir],
  );

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
