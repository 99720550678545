import { CaretRight } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";

import { HotelOfferTotalPrice } from "./HotelOfferTotalPrice";

export function HotelOfferSummary() {
  const t = useTranslations();

  const { data: booking } = useHotelBookingContext();

  if (!booking) {
    return null;
  }

  return (
    <div className="mb-2 flex items-center justify-between">
      <h5 className="text-primary text-sm font-semibold">
        {t("hotels.totalPrice")}
      </h5>

      <span className="text-primary text-md flex items-center gap-1 font-semibold">
        <HotelOfferTotalPrice />
        <CaretRight color="#6A778A" size={16} className="rtl:-scale-x-100" />
      </span>
    </div>
  );
}
