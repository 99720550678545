/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthenticatedImport } from './routes/_authenticated'
import { Route as AuthenticatedIndexImport } from './routes/_authenticated/index'
import { Route as AuthenticatedTripsImport } from './routes/_authenticated/trips'
import { Route as AuthenticatedTravelersImport } from './routes/_authenticated/travelers'
import { Route as AuthenticatedHotelsImport } from './routes/_authenticated/_hotels'
import { Route as AuthenticatedFlightsImport } from './routes/_authenticated/_flights'
import { Route as AuthenticatedHotelsHotelOfferImport } from './routes/_authenticated/_hotels/_hotel-offer'
import { Route as AuthenticatedFlightsFlightOfferImport } from './routes/_authenticated/_flights/_flight-offer'
import { Route as AuthenticatedTripsScopeIdImport } from './routes/_authenticated/trips_.$scope.$id'
import { Route as AuthenticatedHotelsHotelsListImport } from './routes/_authenticated/_hotels/hotels.list'
import { Route as AuthenticatedFlightsFlightsListImport } from './routes/_authenticated/_flights/flights.list'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdTravelersImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.travelers'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdSummaryImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.summary'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdRoomsImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.rooms'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdDetailsImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.details'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdConfirmationImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.confirmation'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.rooms_.images'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.details_.images'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.rooms_.images_.fullscreen'
import { Route as AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenImport } from './routes/_authenticated/_hotels/_hotel-offer/hotels.$id.details_.images_.fullscreen'
import { Route as AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalImport } from './routes/_authenticated/_flights/_flight-offer/flights.offer.$id.step.final'
import { Route as AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Import } from './routes/_authenticated/_flights/_flight-offer/flights.offer.$id.step.3'
import { Route as AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Import } from './routes/_authenticated/_flights/_flight-offer/flights.offer.$id.step.2'
import { Route as AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Import } from './routes/_authenticated/_flights/_flight-offer/flights.offer.$id.step.1'

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: '/_authenticated',
  getParentRoute: () => rootRoute,
} as any)

const AuthenticatedIndexRoute = AuthenticatedIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTripsRoute = AuthenticatedTripsImport.update({
  id: '/trips',
  path: '/trips',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedTravelersRoute = AuthenticatedTravelersImport.update({
  id: '/travelers',
  path: '/travelers',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelsRoute = AuthenticatedHotelsImport.update({
  id: '/_hotels',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedFlightsRoute = AuthenticatedFlightsImport.update({
  id: '/_flights',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelsHotelOfferRoute =
  AuthenticatedHotelsHotelOfferImport.update({
    id: '/_hotel-offer',
    getParentRoute: () => AuthenticatedHotelsRoute,
  } as any)

const AuthenticatedFlightsFlightOfferRoute =
  AuthenticatedFlightsFlightOfferImport.update({
    id: '/_flight-offer',
    getParentRoute: () => AuthenticatedFlightsRoute,
  } as any)

const AuthenticatedTripsScopeIdRoute = AuthenticatedTripsScopeIdImport.update({
  id: '/trips_/$scope/$id',
  path: '/trips/$scope/$id',
  getParentRoute: () => AuthenticatedRoute,
} as any)

const AuthenticatedHotelsHotelsListRoute =
  AuthenticatedHotelsHotelsListImport.update({
    id: '/hotels/list',
    path: '/hotels/list',
    getParentRoute: () => AuthenticatedHotelsRoute,
  } as any)

const AuthenticatedFlightsFlightsListRoute =
  AuthenticatedFlightsFlightsListImport.update({
    id: '/flights/list',
    path: '/flights/list',
    getParentRoute: () => AuthenticatedFlightsRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdRoute =
  AuthenticatedHotelsHotelOfferHotelsIdImport.update({
    id: '/hotels/$id',
    path: '/hotels/$id',
    getParentRoute: () => AuthenticatedHotelsHotelOfferRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute =
  AuthenticatedHotelsHotelOfferHotelsIdTravelersImport.update({
    id: '/travelers',
    path: '/travelers',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute =
  AuthenticatedHotelsHotelOfferHotelsIdSummaryImport.update({
    id: '/summary',
    path: '/summary',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute =
  AuthenticatedHotelsHotelOfferHotelsIdRoomsImport.update({
    id: '/rooms',
    path: '/rooms',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute =
  AuthenticatedHotelsHotelOfferHotelsIdDetailsImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute =
  AuthenticatedHotelsHotelOfferHotelsIdConfirmationImport.update({
    id: '/confirmation',
    path: '/confirmation',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute =
  AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesImport.update({
    id: '/rooms_/images',
    path: '/rooms/images',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute =
  AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesImport.update({
    id: '/details_/images',
    path: '/details/images',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute =
  AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenImport.update({
    id: '/rooms_/images_/fullscreen',
    path: '/rooms/images/fullscreen',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute =
  AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenImport.update({
    id: '/details_/images_/fullscreen',
    path: '/details/images/fullscreen',
    getParentRoute: () => AuthenticatedHotelsHotelOfferHotelsIdRoute,
  } as any)

const AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute =
  AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalImport.update({
    id: '/flights/offer/$id/step/final',
    path: '/flights/offer/$id/step/final',
    getParentRoute: () => AuthenticatedFlightsFlightOfferRoute,
  } as any)

const AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route =
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Import.update({
    id: '/flights/offer/$id/step/3',
    path: '/flights/offer/$id/step/3',
    getParentRoute: () => AuthenticatedFlightsFlightOfferRoute,
  } as any)

const AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route =
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Import.update({
    id: '/flights/offer/$id/step/2',
    path: '/flights/offer/$id/step/2',
    getParentRoute: () => AuthenticatedFlightsFlightOfferRoute,
  } as any)

const AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route =
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Import.update({
    id: '/flights/offer/$id/step/1',
    path: '/flights/offer/$id/step/1',
    getParentRoute: () => AuthenticatedFlightsFlightOfferRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_authenticated': {
      id: '/_authenticated'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedImport
      parentRoute: typeof rootRoute
    }
    '/_authenticated/_flights': {
      id: '/_authenticated/_flights'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedFlightsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotels': {
      id: '/_authenticated/_hotels'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedHotelsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/travelers': {
      id: '/_authenticated/travelers'
      path: '/travelers'
      fullPath: '/travelers'
      preLoaderRoute: typeof AuthenticatedTravelersImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/trips': {
      id: '/_authenticated/trips'
      path: '/trips'
      fullPath: '/trips'
      preLoaderRoute: typeof AuthenticatedTripsImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/': {
      id: '/_authenticated/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthenticatedIndexImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_flights/_flight-offer': {
      id: '/_authenticated/_flights/_flight-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedFlightsFlightOfferImport
      parentRoute: typeof AuthenticatedFlightsImport
    }
    '/_authenticated/_hotels/_hotel-offer': {
      id: '/_authenticated/_hotels/_hotel-offer'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferImport
      parentRoute: typeof AuthenticatedHotelsImport
    }
    '/_authenticated/_flights/flights/list': {
      id: '/_authenticated/_flights/flights/list'
      path: '/flights/list'
      fullPath: '/flights/list'
      preLoaderRoute: typeof AuthenticatedFlightsFlightsListImport
      parentRoute: typeof AuthenticatedFlightsImport
    }
    '/_authenticated/_hotels/hotels/list': {
      id: '/_authenticated/_hotels/hotels/list'
      path: '/hotels/list'
      fullPath: '/hotels/list'
      preLoaderRoute: typeof AuthenticatedHotelsHotelsListImport
      parentRoute: typeof AuthenticatedHotelsImport
    }
    '/_authenticated/trips_/$scope/$id': {
      id: '/_authenticated/trips_/$scope/$id'
      path: '/trips/$scope/$id'
      fullPath: '/trips/$scope/$id'
      preLoaderRoute: typeof AuthenticatedTripsScopeIdImport
      parentRoute: typeof AuthenticatedImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id'
      path: '/hotels/$id'
      fullPath: '/hotels/$id'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation'
      path: '/confirmation'
      fullPath: '/hotels/$id/confirmation'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdConfirmationImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/details': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/details'
      path: '/details'
      fullPath: '/hotels/$id/details'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms'
      path: '/rooms'
      fullPath: '/hotels/$id/rooms'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/summary': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/summary'
      path: '/summary'
      fullPath: '/hotels/$id/summary'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdSummaryImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers'
      path: '/travelers'
      fullPath: '/hotels/$id/travelers'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdTravelersImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images'
      path: '/details/images'
      fullPath: '/hotels/$id/details/images'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images'
      path: '/rooms/images'
      fullPath: '/hotels/$id/rooms/images'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1': {
      id: '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1'
      path: '/flights/offer/$id/step/1'
      fullPath: '/flights/offer/$id/step/1'
      preLoaderRoute: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Import
      parentRoute: typeof AuthenticatedFlightsFlightOfferImport
    }
    '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2': {
      id: '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2'
      path: '/flights/offer/$id/step/2'
      fullPath: '/flights/offer/$id/step/2'
      preLoaderRoute: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Import
      parentRoute: typeof AuthenticatedFlightsFlightOfferImport
    }
    '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3': {
      id: '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3'
      path: '/flights/offer/$id/step/3'
      fullPath: '/flights/offer/$id/step/3'
      preLoaderRoute: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Import
      parentRoute: typeof AuthenticatedFlightsFlightOfferImport
    }
    '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final': {
      id: '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final'
      path: '/flights/offer/$id/step/final'
      fullPath: '/flights/offer/$id/step/final'
      preLoaderRoute: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalImport
      parentRoute: typeof AuthenticatedFlightsFlightOfferImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen'
      path: '/details/images/fullscreen'
      fullPath: '/hotels/$id/details/images/fullscreen'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
    '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen': {
      id: '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen'
      path: '/rooms/images/fullscreen'
      fullPath: '/hotels/$id/rooms/images/fullscreen'
      preLoaderRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenImport
      parentRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdImport
    }
  }
}

// Create and export the route tree

interface AuthenticatedFlightsFlightOfferRouteChildren {
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route
  AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route
  AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute: typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute
}

const AuthenticatedFlightsFlightOfferRouteChildren: AuthenticatedFlightsFlightOfferRouteChildren =
  {
    AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route:
      AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route,
    AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route:
      AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route,
    AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route:
      AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route,
    AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute:
      AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute,
  }

const AuthenticatedFlightsFlightOfferRouteWithChildren =
  AuthenticatedFlightsFlightOfferRoute._addFileChildren(
    AuthenticatedFlightsFlightOfferRouteChildren,
  )

interface AuthenticatedFlightsRouteChildren {
  AuthenticatedFlightsFlightOfferRoute: typeof AuthenticatedFlightsFlightOfferRouteWithChildren
  AuthenticatedFlightsFlightsListRoute: typeof AuthenticatedFlightsFlightsListRoute
}

const AuthenticatedFlightsRouteChildren: AuthenticatedFlightsRouteChildren = {
  AuthenticatedFlightsFlightOfferRoute:
    AuthenticatedFlightsFlightOfferRouteWithChildren,
  AuthenticatedFlightsFlightsListRoute: AuthenticatedFlightsFlightsListRoute,
}

const AuthenticatedFlightsRouteWithChildren =
  AuthenticatedFlightsRoute._addFileChildren(AuthenticatedFlightsRouteChildren)

interface AuthenticatedHotelsHotelOfferHotelsIdRouteChildren {
  AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute
  AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute
  AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute
  AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute
  AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute
  AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute
  AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute
  AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute
  AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

const AuthenticatedHotelsHotelOfferHotelsIdRouteChildren: AuthenticatedHotelsHotelOfferHotelsIdRouteChildren =
  {
    AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute:
      AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute,
    AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute:
      AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute,
    AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute:
      AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute,
    AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute:
      AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute,
    AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute:
      AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute,
    AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute:
      AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute,
    AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute:
      AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute,
    AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute:
      AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute,
    AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute:
      AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute,
  }

const AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren =
  AuthenticatedHotelsHotelOfferHotelsIdRoute._addFileChildren(
    AuthenticatedHotelsHotelOfferHotelsIdRouteChildren,
  )

interface AuthenticatedHotelsHotelOfferRouteChildren {
  AuthenticatedHotelsHotelOfferHotelsIdRoute: typeof AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren
}

const AuthenticatedHotelsHotelOfferRouteChildren: AuthenticatedHotelsHotelOfferRouteChildren =
  {
    AuthenticatedHotelsHotelOfferHotelsIdRoute:
      AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren,
  }

const AuthenticatedHotelsHotelOfferRouteWithChildren =
  AuthenticatedHotelsHotelOfferRoute._addFileChildren(
    AuthenticatedHotelsHotelOfferRouteChildren,
  )

interface AuthenticatedHotelsRouteChildren {
  AuthenticatedHotelsHotelOfferRoute: typeof AuthenticatedHotelsHotelOfferRouteWithChildren
  AuthenticatedHotelsHotelsListRoute: typeof AuthenticatedHotelsHotelsListRoute
}

const AuthenticatedHotelsRouteChildren: AuthenticatedHotelsRouteChildren = {
  AuthenticatedHotelsHotelOfferRoute:
    AuthenticatedHotelsHotelOfferRouteWithChildren,
  AuthenticatedHotelsHotelsListRoute: AuthenticatedHotelsHotelsListRoute,
}

const AuthenticatedHotelsRouteWithChildren =
  AuthenticatedHotelsRoute._addFileChildren(AuthenticatedHotelsRouteChildren)

interface AuthenticatedRouteChildren {
  AuthenticatedFlightsRoute: typeof AuthenticatedFlightsRouteWithChildren
  AuthenticatedHotelsRoute: typeof AuthenticatedHotelsRouteWithChildren
  AuthenticatedTravelersRoute: typeof AuthenticatedTravelersRoute
  AuthenticatedTripsRoute: typeof AuthenticatedTripsRoute
  AuthenticatedIndexRoute: typeof AuthenticatedIndexRoute
  AuthenticatedTripsScopeIdRoute: typeof AuthenticatedTripsScopeIdRoute
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedFlightsRoute: AuthenticatedFlightsRouteWithChildren,
  AuthenticatedHotelsRoute: AuthenticatedHotelsRouteWithChildren,
  AuthenticatedTravelersRoute: AuthenticatedTravelersRoute,
  AuthenticatedTripsRoute: AuthenticatedTripsRoute,
  AuthenticatedIndexRoute: AuthenticatedIndexRoute,
  AuthenticatedTripsScopeIdRoute: AuthenticatedTripsScopeIdRoute,
}

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
)

export interface FileRoutesByFullPath {
  '': typeof AuthenticatedHotelsHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRoute
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsHotelsListRoute
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id': typeof AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren
  '/hotels/$id/confirmation': typeof AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute
  '/hotels/$id/details': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute
  '/hotels/$id/rooms': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute
  '/hotels/$id/summary': typeof AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute
  '/hotels/$id/travelers': typeof AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute
  '/hotels/$id/details/images': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute
  '/hotels/$id/rooms/images': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute
  '/hotels/$id/details/images/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/hotels/$id/rooms/images/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRoutesByTo {
  '': typeof AuthenticatedHotelsHotelOfferRouteWithChildren
  '/travelers': typeof AuthenticatedTravelersRoute
  '/trips': typeof AuthenticatedTripsRoute
  '/': typeof AuthenticatedIndexRoute
  '/flights/list': typeof AuthenticatedFlightsFlightsListRoute
  '/hotels/list': typeof AuthenticatedHotelsHotelsListRoute
  '/trips/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/hotels/$id': typeof AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren
  '/hotels/$id/confirmation': typeof AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute
  '/hotels/$id/details': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute
  '/hotels/$id/rooms': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute
  '/hotels/$id/summary': typeof AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute
  '/hotels/$id/travelers': typeof AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute
  '/hotels/$id/details/images': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute
  '/hotels/$id/rooms/images': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute
  '/flights/offer/$id/step/1': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route
  '/flights/offer/$id/step/2': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route
  '/flights/offer/$id/step/3': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route
  '/flights/offer/$id/step/final': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute
  '/hotels/$id/details/images/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/hotels/$id/rooms/images/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_authenticated': typeof AuthenticatedRouteWithChildren
  '/_authenticated/_flights': typeof AuthenticatedFlightsRouteWithChildren
  '/_authenticated/_hotels': typeof AuthenticatedHotelsRouteWithChildren
  '/_authenticated/travelers': typeof AuthenticatedTravelersRoute
  '/_authenticated/trips': typeof AuthenticatedTripsRoute
  '/_authenticated/': typeof AuthenticatedIndexRoute
  '/_authenticated/_flights/_flight-offer': typeof AuthenticatedFlightsFlightOfferRouteWithChildren
  '/_authenticated/_hotels/_hotel-offer': typeof AuthenticatedHotelsHotelOfferRouteWithChildren
  '/_authenticated/_flights/flights/list': typeof AuthenticatedFlightsFlightsListRoute
  '/_authenticated/_hotels/hotels/list': typeof AuthenticatedHotelsHotelsListRoute
  '/_authenticated/trips_/$scope/$id': typeof AuthenticatedTripsScopeIdRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id': typeof AuthenticatedHotelsHotelOfferHotelsIdRouteWithChildren
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation': typeof AuthenticatedHotelsHotelOfferHotelsIdConfirmationRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/details': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/summary': typeof AuthenticatedHotelsHotelOfferHotelsIdSummaryRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers': typeof AuthenticatedHotelsHotelOfferHotelsIdTravelersRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesRoute
  '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep1Route
  '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep2Route
  '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStep3Route
  '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final': typeof AuthenticatedFlightsFlightOfferFlightsOfferIdStepFinalRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdDetailsImagesFullscreenRoute
  '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen': typeof AuthenticatedHotelsHotelOfferHotelsIdRoomsImagesFullscreenRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/trips/$scope/$id'
    | '/hotels/$id'
    | '/hotels/$id/confirmation'
    | '/hotels/$id/details'
    | '/hotels/$id/rooms'
    | '/hotels/$id/summary'
    | '/hotels/$id/travelers'
    | '/hotels/$id/details/images'
    | '/hotels/$id/rooms/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
    | '/hotels/$id/details/images/fullscreen'
    | '/hotels/$id/rooms/images/fullscreen'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/travelers'
    | '/trips'
    | '/'
    | '/flights/list'
    | '/hotels/list'
    | '/trips/$scope/$id'
    | '/hotels/$id'
    | '/hotels/$id/confirmation'
    | '/hotels/$id/details'
    | '/hotels/$id/rooms'
    | '/hotels/$id/summary'
    | '/hotels/$id/travelers'
    | '/hotels/$id/details/images'
    | '/hotels/$id/rooms/images'
    | '/flights/offer/$id/step/1'
    | '/flights/offer/$id/step/2'
    | '/flights/offer/$id/step/3'
    | '/flights/offer/$id/step/final'
    | '/hotels/$id/details/images/fullscreen'
    | '/hotels/$id/rooms/images/fullscreen'
  id:
    | '__root__'
    | '/_authenticated'
    | '/_authenticated/_flights'
    | '/_authenticated/_hotels'
    | '/_authenticated/travelers'
    | '/_authenticated/trips'
    | '/_authenticated/'
    | '/_authenticated/_flights/_flight-offer'
    | '/_authenticated/_hotels/_hotel-offer'
    | '/_authenticated/_flights/flights/list'
    | '/_authenticated/_hotels/hotels/list'
    | '/_authenticated/trips_/$scope/$id'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/details'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/summary'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images'
    | '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1'
    | '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2'
    | '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3'
    | '/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen'
    | '/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_authenticated"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/_flights",
        "/_authenticated/_hotels",
        "/_authenticated/travelers",
        "/_authenticated/trips",
        "/_authenticated/",
        "/_authenticated/trips_/$scope/$id"
      ]
    },
    "/_authenticated/_flights": {
      "filePath": "_authenticated/_flights.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_flights/_flight-offer",
        "/_authenticated/_flights/flights/list"
      ]
    },
    "/_authenticated/_hotels": {
      "filePath": "_authenticated/_hotels.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/_hotels/_hotel-offer",
        "/_authenticated/_hotels/hotels/list"
      ]
    },
    "/_authenticated/travelers": {
      "filePath": "_authenticated/travelers.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/trips": {
      "filePath": "_authenticated/trips.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/": {
      "filePath": "_authenticated/index.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_flights/_flight-offer": {
      "filePath": "_authenticated/_flights/_flight-offer.tsx",
      "parent": "/_authenticated/_flights",
      "children": [
        "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1",
        "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2",
        "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3",
        "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final"
      ]
    },
    "/_authenticated/_hotels/_hotel-offer": {
      "filePath": "_authenticated/_hotels/_hotel-offer.tsx",
      "parent": "/_authenticated/_hotels",
      "children": [
        "/_authenticated/_hotels/_hotel-offer/hotels/$id"
      ]
    },
    "/_authenticated/_flights/flights/list": {
      "filePath": "_authenticated/_flights/flights.list.tsx",
      "parent": "/_authenticated/_flights"
    },
    "/_authenticated/_hotels/hotels/list": {
      "filePath": "_authenticated/_hotels/hotels.list.tsx",
      "parent": "/_authenticated/_hotels"
    },
    "/_authenticated/trips_/$scope/$id": {
      "filePath": "_authenticated/trips_.$scope.$id.tsx",
      "parent": "/_authenticated"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer",
      "children": [
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/details",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/summary",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen",
        "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen"
      ]
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.confirmation.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/details": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.details.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.rooms.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/summary": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.summary.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.travelers.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.details_.images.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.rooms_.images.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1": {
      "filePath": "_authenticated/_flights/_flight-offer/flights.offer.$id.step.1.tsx",
      "parent": "/_authenticated/_flights/_flight-offer"
    },
    "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2": {
      "filePath": "_authenticated/_flights/_flight-offer/flights.offer.$id.step.2.tsx",
      "parent": "/_authenticated/_flights/_flight-offer"
    },
    "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3": {
      "filePath": "_authenticated/_flights/_flight-offer/flights.offer.$id.step.3.tsx",
      "parent": "/_authenticated/_flights/_flight-offer"
    },
    "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final": {
      "filePath": "_authenticated/_flights/_flight-offer/flights.offer.$id.step.final.tsx",
      "parent": "/_authenticated/_flights/_flight-offer"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/details_/images_/fullscreen": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.details_.images_.fullscreen.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    },
    "/_authenticated/_hotels/_hotel-offer/hotels/$id/rooms_/images_/fullscreen": {
      "filePath": "_authenticated/_hotels/_hotel-offer/hotels.$id.rooms_.images_.fullscreen.tsx",
      "parent": "/_authenticated/_hotels/_hotel-offer/hotels/$id"
    }
  }
}
ROUTE_MANIFEST_END */
