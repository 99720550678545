import { PropsWithChildren, useMemo } from "react";

import { TFlightFormValues } from "@/app/lib/types/types";
import { initialFlightsValues } from "../../initialFlightsValues";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { FlightSearchDataContext } from "./FlightSearchDataContext";

export const FlightSearchDataProvider = ({ children }: PropsWithChildren) => {
  const [searchData, setSearchData] = useLocalStorage<
    TFlightFormValues | undefined
  >("@flights-search3", initialFlightsValues);

  const contextValue = useMemo(
    () => ({
      searchData,
      updateSearchData: setSearchData,
    }),
    [searchData, setSearchData],
  );

  return (
    <FlightSearchDataContext.Provider value={contextValue}>
      {children}
    </FlightSearchDataContext.Provider>
  );
};
