import dayjs from "dayjs";

export const getNumberOfBookingNights = ({
  checkIn,
  checkOut,
}: {
  checkIn?: string;
  checkOut?: string;
}) => {
  return dayjs(checkOut).diff(checkIn, "day");
};
