"use client";

import { TextField as MuiTextField } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { styled } from "@mui/material/styles";

export const Puller = styled("div")(() => ({
  width: "98px",
  minHeight: 8,
  backgroundColor: "#E7E9EE",
  borderRadius: 3,
  top: 12,
  margin: "12px auto",
}));

export const TelInput = styled(MuiTelInput)(() => ({
  "& .MuiInputBase-root": {
    borderRadius: "16px",
    backgroundColor: "white",
    border: "1px solid #E7E9EE",
  },
  "& .MuiFilledInput-root": {
    borderRadius: "16px",
    paddingLeft: "4px",
  },

  "& .MuiFilledInput-root:after": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover": {
    background: "white",
  },
  "& .MuiFilledInput-root:focus": {
    background: "none",
  },
  "& .MuiFilledInput-root:before": {
    border: "none",
  },
  "& .MuiFilledInput-root:hover:before": {
    border: "none",
    background: "none",
    width: 0,
  },
  "& .MuiInputLabel-root": {
    color: "var(--color-text-placeholder)",
    fontSize: "16px",
    lineHeight: "22px",
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "var(--color-text-placeholder)",
    background: "none",
  },
  "& .MuiInputBase-input": {
    color: "var(--color-text-label)",
  },
  "[dir='rtl'] & .MuiInputBase-input": {
    textAlign: "end",
  },
  "& .MuiInputBase-input:focus": {
    backgroundColor: "none",
  },
  "& .Mui-error.MuiInputBase-root": {
    border: "1px solid var(--color-semantic-error-strong)",
  },
}));

export { MuiTextField as TextField };
