import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useTranslations } from "use-intl";
import { Check } from "@phosphor-icons/react";

import { OrderType } from "@/app/lib/types/enums";
import { orderTypesLabels } from "./labels";

type Props = {
  value: OrderType;
  onValueChange: (value: OrderType) => void;
};

// FIXME: when user select option and close drawer, then reopen, selected option before is still selected
export const OrdersTypeList = ({ value, onValueChange }: Props) => {
  const t = useTranslations();
  const [currentOrderType, setCurrentOrderType] = useState<OrderType>(value);

  return (
    <div className="relative w-full">
      <ToggleButtonGroup
        className="flex w-full flex-col"
        exclusive
        size="small"
        color="primary"
        aria-label="orders type options"
      >
        {(Object.keys(OrderType) as Array<keyof typeof OrderType>).map(
          (orderTypeKey) => (
            <ToggleButton
              key={orderTypeKey}
              value={OrderType[orderTypeKey]}
              onChange={() => setCurrentOrderType(OrderType[orderTypeKey])}
              sx={{
                border: "none",
                borderBottom: "1px solid var(--color-stroke-neutral-soft)",
                borderBottomLeftRadius: "0",
                borderBottomRightRadius: "0",
                justifyContent: "flex-end",
                textTransform: "unset",
                padding: "16px 8px",
              }}
            >
              <div className="flex w-full">
                <span className="text-primary inline-flex grow items-center gap-4 text-base font-medium">
                  {t(orderTypesLabels[orderTypeKey])}
                </span>

                {currentOrderType === OrderType[orderTypeKey] ? (
                  <Check size={20} className="fill-interactive" weight="bold" />
                ) : null}
              </div>
            </ToggleButton>
          ),
        )}
      </ToggleButtonGroup>
      <div className="m-4">
        <Button
          fullWidth
          onClick={() => onValueChange(currentOrderType)}
          variant="contained"
          size="large"
        >
          {t("done")}
        </Button>
      </div>
    </div>
  );
};
