import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { THotelFormValues } from "../../types/types";

export interface HotelSearchDataContextValue {
  searchData: THotelFormValues | null;
  updateSearchData: Dispatch<SetStateAction<THotelFormValues | null>>;
}

export const HotelSearchDataContext =
  createContext<HotelSearchDataContextValue | null>(null);

export const useHotelSearchDataContext = () => {
  const context = useContext(HotelSearchDataContext);
  if (!context) {
    throw new Error(
      "useHotelSearchDataContext must be used within a HotelSearchDataProvider",
    );
  }
  return context;
};
