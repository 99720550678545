import { Fragment, useId, useState } from "react";
import { Star } from "@phosphor-icons/react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Link,
  Radio,
  RadioGroup,
} from "@mui/material";
import { FormikProps } from "formik";
import { useTranslations } from "use-intl";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { getAgeFromDateOfBirth } from "@/app/lib/getAgefromDateOfBirth";
import { HotelBooking, THotelSearchResult } from "@/app/lib/types/types";
import MoreDetailsDrawer from "../flight-purchase/MoreDetailsDrawer";
import { HotelRoomTravelers } from "./HotelRoomTravelers";
import { HotelRoom } from "./HotelRoom";
import { HotelOfferTotalPrice } from "./HotelOfferTotalPrice";
import { HotelName } from "../hotels/HotelName";
import { boardIcons } from "../hotels/BoardIcons";
import { MIN_ADULT_AGE } from "@/app/lib/constants";

export interface SummaryFormValues {
  roomAssigments: Record<string, { travelers: string[] }>;
  leadTraveler: string;
  termsAndConditions: boolean;
}

export const HotelBookingSummary = ({
  hotel,
  hotelBookingData,
  form,
}: {
  hotel: THotelSearchResult;
  hotelBookingData: HotelBooking;
  form: FormikProps<SummaryFormValues>;
}) => {
  const t = useTranslations();
  const { getSelectedTravellers } = useTravellersContext();
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const checkboxId = useId();

  const assignedTravelers = Object.values(form.values.roomAssigments).flatMap(
    (assigment) => assigment.travelers,
  );

  return (
    <div className="flex w-full flex-col px-4">
      <div className="mb-4 text-base font-medium">
        {t("hotels.bookingSummary")}
      </div>

      <div className="section-title mb-2">
        {t("hotels.overview.hotelDetails")}
      </div>
      <div className="section-card flex gap-4">
        <div>
          {hotel.mainImageUrl && (
            <div className="flex-end flex w-full">
              <img
                className="min-h-32 min-w-24 rounded-2xl object-cover"
                width="90"
                height="130"
                src={hotel.mainImageUrl}
                alt={hotel.name}
              />
            </div>
          )}
        </div>

        <div>
          <span className="text-title mb-1 font-bold">
            <HotelName name={hotel.name} />
          </span>
          <div className="flex flex-col gap-2">
            {!!hotel.stars && (
              <div className="flex items-center gap-2">
                <div className="flex">
                  {Array.from(Array(Number(hotel.stars))).map((_, index) => (
                    <Star key={index} size={12} color="#EF9C43" weight="fill" />
                  ))}
                </div>
                <span className="text-secondary text-sm">
                  {t("hotels.card.stars", { stars: hotel.stars })}
                </span>
              </div>
            )}
            {hotel?.boardBasis?.map((bb) => (
              <div key={bb} className="flex items-center">
                <div className="text-primary flex items-center gap-2 text-xs">
                  {boardIcons[bb]}
                  {t(`hotels.boardBasis.${bb}`)}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="section-title mt-4">{t("hotels.roomDetails")}</div>
      <div className="text-primary section-card flex flex-col gap-2">
        {hotelBookingData.rooms.map((room, index) => (
          <Fragment key={room.roomToken}>
            {index !== 0 ? <Divider /> : null}

            <div className="flex w-full flex-col gap-2">
              <HotelRoom
                room={room}
                hotel={hotel}
                visitorsCount={getSelectedTravellers.length}
              />
              <div className="mt-2">
                <HotelRoomTravelers
                  onTravelersSelect={(travelers) => {
                    form.setFieldValue("roomAssigments", {
                      ...form.values.roomAssigments,
                      [room.roomToken]: {
                        travelers,
                      },
                    });
                  }}
                  selectedTravelers={
                    form.values.roomAssigments[room.roomToken]?.travelers ?? []
                  }
                  travelers={getSelectedTravellers.map((traveler) => {
                    return {
                      ...traveler,
                      // check if the traveler is already assigned to a room
                      available: !assignedTravelers.includes(traveler.id),
                    };
                  })}
                />
              </div>
            </div>
          </Fragment>
        ))}
      </div>

      <div className="section-title mt-4">{t("travellers.mainTraveller")}</div>
      <div className="section-card p-0">
        <div className="text-tertiary p-4 pb-2 text-sm">
          {t("travellers.markMainTraveller")}
        </div>

        <RadioGroup
          aria-labelledby="cabin-class-radio-group"
          name="leadTraveler"
          value={form.values.leadTraveler}
          onChange={form.handleChange}
        >
          {getSelectedTravellers.map((traveller) => (
            <FormControlLabel
              key={traveller.id}
              className="flex gap-4"
              value={traveller.id}
              control={<Radio />}
              disabled={
                getAgeFromDateOfBirth(traveller.birthDate) < MIN_ADULT_AGE
              }
              label={
                <div className="flex items-center gap-2">
                  <div className="grow">
                    <div className="text-title font-medium">
                      {traveller.firstName} {traveller.lastName}
                    </div>
                    <div className="text-tertiary text-xs">
                      {traveller.birthDate && (
                        <>
                          {t("hotels.overview.age")}{" "}
                          {getAgeFromDateOfBirth(traveller.birthDate)}{" "}
                        </>
                      )}
                    </div>
                  </div>
                  {form.values.leadTraveler === traveller.id ? (
                    <div className="text-xs font-medium">
                      {t("travellers.mainTraveller")}
                    </div>
                  ) : null}
                </div>
              }
              sx={{
                m: 0,
                p: 2,
                "&:not(:last-of-type)": {
                  borderBottom: "1px solid #E5E5E5",
                },
                ".MuiFormControlLabel-label": {
                  width: "100%",
                },
              }}
            />
          ))}
        </RadioGroup>
      </div>

      <div className="section-card mt-2">
        <div className="text-title text-sm">{t("hotels.bookingPolicy")}</div>
      </div>

      <div className="section-title mt-4">{t("summary")}</div>
      <div className="section-card flex gap-2">
        <div className="shrink-0">
          <Checkbox
            id={checkboxId}
            checked={form.values.termsAndConditions}
            onChange={(e, checked) => {
              form.setFieldValue("termsAndConditions", checked);
            }}
            sx={{ p: 0 }}
          />
        </div>

        <div className="flex flex-col gap-1 pt-1">
          <label
            className="text-primary text-sm font-semibold"
            htmlFor={checkboxId}
          >
            {t("hotels.acceptGeneralTerms")}
          </label>
          <div className="text-tertiary text-sm">
            {t("hotels.acceptGeneralTermsWarning")}
          </div>
          <Link
            onClick={() => setMoreDetailsOpen(true)}
            sx={{ fontSize: "14px" }}
          >
            {t("moreDetails")}
          </Link>
        </div>
      </div>

      <div className="mt-2 flex flex-col gap-2 rounded-3xl bg-white p-4 text-sm">
        <div className="flex justify-between">
          <div className="text-tertiary">{t("total")}</div>
          <div className="text-primary font-bold">
            <HotelOfferTotalPrice />
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-tertiary">{t("paymentMethod")}</div>
          <div className="text-primary font-bold">{t("qiCard")}</div>
        </div>
      </div>

      <MoreDetailsDrawer
        open={moreDetailsOpen}
        onClose={() => setMoreDetailsOpen(false)}
      />
    </div>
  );
};
