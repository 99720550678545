import { SlidersHorizontal } from "@phosphor-icons/react";
import { Badge, IconButton } from "@mui/material";

import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

type Props = {
  onClick: () => void;
  filtersSetCount: number | undefined;
};

export function FiltersIconButton({ onClick, filtersSetCount }: Props) {
  const { dir } = useLocaleInfo();

  return (
    <IconButton onClick={onClick} sx={{ padding: 0 }}>
      <Badge
        badgeContent={filtersSetCount}
        color="primary"
        anchorOrigin={{
          vertical: "top",
          horizontal: dir === "ltr" ? "right" : "left",
        }}
      >
        <SlidersHorizontal size={24} className="fill-slate-1100" aria-hidden />
      </Badge>
    </IconButton>
  );
}
