import { useState, useEffect, useMemo, PropsWithChildren } from "react";
import { useQuery } from "@tanstack/react-query";
import { useTranslations } from "use-intl";
import {
  useNewTravelerMutation,
  useUpdateTravelerMutation,
  useDeleteTravelerMutation,
  travelersQueryOptions,
} from "@/app/lib/hooks/useTravellers";
import type {
  CreateTravelerBodyInputDto,
  UpdateTravelerBodyInputDto,
} from "@/app/lib/types/codegen";
import { getAgeFromDateOfBirth } from "../../getAgefromDateOfBirth";
import { my } from "../../api/api";
import { MIN_ADULT_AGE } from "../../constants";
import { getTravelerType } from "../../utils/transformTraveler";
import { Traveller } from "../../types/types";
import { TravellersContext } from "./TravellersContext";

export const TravellersProvider = ({ children }: PropsWithChildren) => {
  const { data, isError, isFetching } = useQuery(
    travelersQueryOptions({ page: 1 }),
  );
  const [selectedTravellers, setSelectedTravellers] = useState<string[]>([]);
  const [leadTraveller, setLeadTraveller] = useState<Traveller | null>(null);
  const createTraveler = useNewTravelerMutation();
  const updateTraveler = useUpdateTravelerMutation();
  const deleteTraveler = useDeleteTravelerMutation();
  const t = useTranslations();

  const getSelectedTravellers = useMemo(
    () =>
      data?.filter((traveller) => selectedTravellers.includes(traveller.id)) ||
      [],
    [data, selectedTravellers],
  );

  const handleLeadTraveller = (traveller: Traveller) => {
    setLeadTraveller(traveller);
  };

  const addTraveller = async (traveller: CreateTravelerBodyInputDto) => {
    try {
      await createTraveler.mutateAsync(traveller);
    } catch {
      my?.showToast({
        type: "exception",
        content: t("errorGeneral"),
        duration: 3000,
      });
    }
  };

  const removeTraveller = async (id: string) => {
    await deleteTraveler.mutateAsync(id);
    setSelectedTravellers(
      selectedTravellers.filter((travellerId) => travellerId !== id),
    );
  };

  const editTraveller = async (
    travelerId: string,
    data: UpdateTravelerBodyInputDto,
  ) => {
    try {
      await updateTraveler.mutateAsync({ travelerId, data });
    } catch {
      my?.showToast({
        type: "exception",
        content: t("errorGeneral"),
        duration: 3000,
      });
    }
  };

  const toggleTravellerSelection = (id: string) => {
    setSelectedTravellers((prev) => {
      if (prev.includes(id)) {
        if (leadTraveller?.id === id) {
          setLeadTraveller(null);
        }
        return prev.filter((travellerId) => travellerId !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  useEffect(() => {
    if (getSelectedTravellers?.length > 0 && !leadTraveller) {
      const oldEnoughTraveller = getSelectedTravellers.find(
        (traveller) =>
          getAgeFromDateOfBirth(traveller.birthDate) >= MIN_ADULT_AGE,
      );
      if (oldEnoughTraveller) {
        setLeadTraveller(oldEnoughTraveller);
      }
    }
  }, [getSelectedTravellers, leadTraveller]);

  const isReady = !isError && !isFetching;

  const selectedTravellersByAge = getSelectedTravellers.reduce(
    (acc, traveller) => {
      const travellerType = getTravelerType(traveller);
      if (travellerType === "adult") {
        return {
          ...acc,
          adults: acc.adults + 1,
        };
      }

      if (travellerType === "child") {
        return {
          ...acc,
          children: acc.children + 1,
        };
      }

      return {
        ...acc,
        infants: acc.infants + 1,
      };
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  return (
    <TravellersContext.Provider
      value={{
        travellers: isReady ? data || [] : [],
        selectedTravellers,
        addTraveller,
        leadTraveller,
        removeTraveller,
        toggleTravellerSelection,
        editTraveller,
        isReady,
        handleLeadTraveller,
        getSelectedTravellers,
        selectedTravellersByAge,
      }}
    >
      {children}
    </TravellersContext.Provider>
  );
};
