import { isNaN } from "formik";
import { string, object, ref, date, number, array, lazy } from "yup";

export const convertArabicNumberToEnglish = (n: string) => {
  const ar = "٠١٢٣٤٥٦٧٨٩".split("");
  const en = "0123456789".split("");

  const englishNumberStr = n
    .replace(/[٠١٢٣٤٥٦٧٨٩]/g, (x) => en[ar.indexOf(x)])
    .replace(/[^\d]/g, "");

  return Number(englishNumberStr);
};

export const roomsValidationSchema = array()
  .of(
    object().shape({
      adults: number().integer().min(1),
      children: number().integer(),
      childrenAges: array().of(
        lazy((value) =>
          (isNaN(Number(value))
            ? string().transform(convertArabicNumberToEnglish)
            : number().integer()
          )
            .min(0, "hotels.validation.childrenAge")
            .max(12, "hotels.validation.childrenAge")
            .required(),
        ),
      ),
    }),
  )
  .min(1, "hotels.validation.roomsMin")
  .max(5, "hotels.validation.roomsMax")
  .required();

export const validationSchema = object().shape({
  destination: object().shape({
    name: string().required("hotels.validation.destination"),
    id: number().required(),
  }),
  dates: object().shape({
    checkIn: date().required("hotels.validation.dates.checkIn"),
    checkOut: date()
      .min(ref("checkIn"), "hotels.validation.dates.min")
      .required("hotels.validation.dates.checkOut"),
  }),
  rooms: roomsValidationSchema,
  travelPurpose: string().required(),
});
