import { Drawer as DrawerBase, SwipeableDrawerProps } from "@mui/material";

import { getQiToken } from "@/app/lib/api/api";

type Props = Pick<SwipeableDrawerProps, "open" | "children"> & {
  hasFullHeight?: boolean;
  heightOffset?: number;
};

export function Drawer({
  open,
  children,
  hasFullHeight,
  heightOffset = 0,
}: Props) {
  const qi = getQiToken();
  const heightOffsetWithUnit = `${heightOffset}px`;

  const heightStyle = hasFullHeight
    ? qi
      ? `calc(90vh - ${heightOffsetWithUnit})`
      : `calc(100vh - ${heightOffsetWithUnit})`
    : "auto";

  return (
    <DrawerBase
      anchor="bottom"
      onClose={() => {}}
      open={open}
      sx={{
        "& .MuiDrawer-paper": {
          height: heightStyle,
        },
      }}
    >
      {children}
    </DrawerBase>
  );
}
