import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { HotelsSortValue } from "../../types/types";

export interface HotelSortContextValue {
  sortValue: HotelsSortValue;
  updateSortValue: Dispatch<SetStateAction<HotelsSortValue>>;
  resetSortValue: () => void;
}

export const HotelSortContext = createContext<HotelSortContextValue | null>(
  null,
);

export const useHotelSortContext = () => {
  const context = useContext(HotelSortContext);
  if (!context) {
    throw new Error(
      "useHotelSortContext must be used within a HotelSortProvider",
    );
  }
  return context;
};
