import { useEffect, useState } from "react";
import { NoNetworkConnectionPageError } from "./errors/NoNetworkConnectionPageError";

export const NetworkConnectionGuard = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    const updateNetworkStatus = () => {
      setOnline(navigator.onLine);
    };

    window.addEventListener("load", updateNetworkStatus);
    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    return () => {
      window.removeEventListener("load", updateNetworkStatus);
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  if (isOnline) {
    return children;
  }

  return <NoNetworkConnectionPageError />;
};
