import { createContext, useContext } from "react";
import {
  FindFlightOffersDestination,
  GetFlightAvailabilityTokenFlight,
  GetFlightAvailabilityTokenOutputData,
  GetFlightAvailabilityTokenSegment,
} from "../../types/codegen";

export interface SingleFlightSelectionContextValue {
  singleFlightDetails: SingleFightDetailsData;
  departureFlight: GetFlightAvailabilityTokenFlight | null;
  returnFlight: GetFlightAvailabilityTokenFlight | null;
  departureSegments: GetFlightAvailabilityTokenSegment[] | null;
  returnSegments: GetFlightAvailabilityTokenSegment[] | null;
  origin: FindFlightOffersDestination | null;
  destination: FindFlightOffersDestination | null;
  isAdditionalDataLoading: boolean;
}

export interface SingleFightDetailsData
  extends Omit<
    Partial<GetFlightAvailabilityTokenOutputData>,
    "totalPrice" | "flights"
  > {
  totalPrice: string;
  flights: GetFlightAvailabilityTokenFlight[] | undefined;
}

export const SingleFlightSelectionContext =
  createContext<SingleFlightSelectionContextValue | null>(null);

export const useSingleFlightSelectionContext = () => {
  const context = useContext(SingleFlightSelectionContext);
  if (!context) {
    throw new Error(
      "useSingleFlightSelectionContext must be used within a SingleFlightDetailsProvider",
    );
  }
  return context;
};
