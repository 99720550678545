import { createContext, useContext } from "react";
import { HotelOffer } from "../../types/types";

export interface HotelOffersContextValue {
  readonly data: HotelOffer[];
}

export const HotelOffersContext = createContext<HotelOffersContextValue>({
  data: [],
});

export const useHotelOffersContext = () => useContext(HotelOffersContext);
