import { PropsWithChildren, useMemo, useState } from "react";
import { FlightSortContext, FlightSortContextValue } from "./FlightSortContext";
import { FlightsSortValue } from "../../types/types";
import { FlightsSortOptions, SortOrder } from "../../types/enums";

const DEFAULT_SORT_VALUE: FlightsSortValue = {
  field: FlightsSortOptions.PRICE,
  order: SortOrder.ASC,
};

export const FlightSortProvider = ({ children }: PropsWithChildren) => {
  const [sortValue, setSortValue] =
    useState<FlightSortContextValue["sortValue"]>(DEFAULT_SORT_VALUE);

  const contextValue = useMemo<FlightSortContextValue>(
    () => ({
      sortValue,
      updateSortValue: setSortValue,
      resetSortValue: () => setSortValue(DEFAULT_SORT_VALUE),
    }),
    [sortValue],
  );

  return (
    <FlightSortContext.Provider value={contextValue}>
      {children}
    </FlightSortContext.Provider>
  );
};
