import { PropsWithChildren, useMemo, useState } from "react";
import {
  FlightFiltersContext,
  FlightFiltersContextValue,
} from "./FlightFiltersContext";

export const FlightFiltersProvider = ({ children }: PropsWithChildren) => {
  const [defaultFilters, setDefaultFilters] =
    useState<FlightFiltersContextValue["defaultFilters"]>(null);
  const [filters, setFilters] =
    useState<FlightFiltersContextValue["filters"]>(null);

  const contextValue = useMemo<FlightFiltersContextValue>(
    () => ({
      filters,
      defaultFilters,
      updateFilters: (value) => {
        if (filters === null) {
          setDefaultFilters(value);
        }
        setFilters(value);
      },
      resetFilters: () => {
        setFilters(null);
        setDefaultFilters(null);
      },
    }),
    [filters, defaultFilters],
  );

  return (
    <FlightFiltersContext.Provider value={contextValue}>
      {children}
    </FlightFiltersContext.Provider>
  );
};
