import { OfferIsNotAvailableAnymoreOutputDto } from "@/app/lib/types/codegen";
import { AxiosError } from "axios";
import { isOfferIsNotAvailableAnymoreError } from "@/app/lib/assertions";
import { OfferNotAvailableError } from "../OfferNotAvailableError";
import { GeneralPageError } from "../errors/GeneralPageError";

interface FlightOfferErrorProps {
  error: Error | AxiosError<OfferIsNotAvailableAnymoreOutputDto>;
}

export const FlightOfferError = ({ error }: FlightOfferErrorProps) => {
  if (isOfferIsNotAvailableAnymoreError(error)) {
    return <OfferNotAvailableError variant="flight" />;
  }

  return (
    <GeneralPageError
      backOptions={{ to: "/", search: { category: "flights" } }}
    />
  );
};
