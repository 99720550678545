import { PropsWithChildren, useMemo, useState } from "react";

import { HotelsSortValue } from "../../types/types";
import { HotelsSortOptions, SortOrder } from "../../types/enums";
import { HotelSortContext, HotelSortContextValue } from "./HotelSortContext";

const DEFAULT_SORT_VALUE: HotelsSortValue = {
  field: HotelsSortOptions.RATING,
  order: SortOrder.DESC,
};

export const HotelSortProvider = ({ children }: PropsWithChildren) => {
  const [sortValue, setSortValue] =
    useState<HotelSortContextValue["sortValue"]>(DEFAULT_SORT_VALUE);

  const contextValue = useMemo<HotelSortContextValue>(
    () => ({
      sortValue,
      updateSortValue: setSortValue,
      resetSortValue: () => setSortValue(DEFAULT_SORT_VALUE),
    }),
    [sortValue],
  );

  return (
    <HotelSortContext.Provider value={contextValue}>
      {children}
    </HotelSortContext.Provider>
  );
};
