import useInfiniteScroll from "react-infinite-scroll-hook";
import {
  mapFlightReservation,
  useFlightsReservations,
} from "@/app/lib/hooks/useFlightsReservations";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import SingleAvailableFlight from "../SingleAvailableFlight";
import NoResultsError from "../NoResultsError";
import type { FindOrdersReservationsForFlightOrder } from "@/app/lib/types/codegen";
import PageLoader from "../PageLoader";
import { OrderType } from "@/app/lib/types/enums";
import { OrderCardWithoutReservation } from "../OrderCardWithoutReservation";

export const MyFlightsList = ({
  onOrderClick,
  orderType,
  orderTypeSwitcher,
}: {
  onOrderClick: (order: FindOrdersReservationsForFlightOrder) => void;
  orderType: OrderType;
  orderTypeSwitcher: React.ReactNode;
}) => {
  const t = useTranslations();
  const {
    data,
    isLoading,
    isFetching,
    isError,
    hasReachedEnd,
    isEmpty,
    fetchNextPage,
  } = useFlightsReservations({ orderType });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !hasReachedEnd,
    onLoadMore: () => {
      fetchNextPage();
    },
    disabled: isError,
    rootMargin: "0px 0px 40px 0px",
  });

  if (isLoading) {
    return (
      <PageLoader>
        <Loader icon="airplane" />
      </PageLoader>
    );
  }

  if (isEmpty) return <NoResultsError tab="flights" />;

  const flightCount = data?.pages?.[0]?.data?.count;

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        {orderTypeSwitcher}
        {flightCount ? (
          <div className="text-md font-medium">
            {t("flights.bookedFlights", { flightCount })}
          </div>
        ) : null}
      </div>

      {data?.pages?.flatMap((page) =>
        page.data.orders.map((order) =>
          order.status === OrderType.REFUNDED ? (
            <OrderCardWithoutReservation
              key={order.reservation?.id}
              order={order}
              onClick={() => onOrderClick(order)}
            />
          ) : order.reservation ? (
            <SingleAvailableFlight
              key={order.reservation?.id}
              onClick={() => order.reservation && onOrderClick(order)}
              itinerary={{
                ...order.reservation,
                flights: order.reservation.flights.map((flight) =>
                  mapFlightReservation(flight),
                ),
              }}
              orderStatus={order.status}
            />
          ) : null,
        ),
      )}

      {isFetching || !hasReachedEnd ? (
        <div
          className="flex items-center justify-center py-4"
          ref={sentryRef}
          aria-hidden={!isFetching}
        >
          <Loader icon="airplane" />
        </div>
      ) : (
        <div className="text-tertiary flex items-center justify-center p-8">
          {t("flights.noResults")}
        </div>
      )}
    </>
  );
};
