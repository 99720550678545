import { useTranslations } from "use-intl";
import PageError from "./PageError";
import { WifiX } from "@phosphor-icons/react";

export const NoNetworkConnectionPageError = () => {
  const t = useTranslations();

  return (
    <PageError
      title={t("noNetworkConnetionErrorTitle")}
      subtitles={[t("noNetworkConnetionErrorSubtitle")]}
      back={{ text: t("backToHome"), options: { to: "/" } }}
      icon={<WifiX size={60} color="#295942" />}
      hideWhatsAppButton
    />
  );
};
