"use client";

import { useRouter } from "@tanstack/react-router";
import { MyHotel } from "./MyHotel";
import { useSuspenseQuery } from "@tanstack/react-query";
import { MyTripsHeader } from "./MyTripsHeader";
import { Suspense } from "react";
import Loader from "../AnimatedLoader";
import { MyFlight } from "./MyFlight";
import PageLoader from "../PageLoader";
import {
  flightOrderQueryOptions,
  hotelOrderQueryOptions,
} from "@/app/lib/hooks/useOrder";
import { OrderType } from "@/app/lib/types/enums";
import { OrderDetailsWithoutReservation } from "./OrderDetailsWithoutReservation";

interface MyTripProps {
  orderId: string;
  scope: "hotels" | "flights";
}

export const MyTrip = ({ scope, orderId }: MyTripProps) => {
  if (scope === "hotels") {
    return (
      <Suspense
        fallback={
          <PageLoader>
            <Loader icon="buildings" />
          </PageLoader>
        }
      >
        <MyHotelReservation orderId={orderId} />
      </Suspense>
    );
  }

  if (scope === "flights") {
    return (
      <Suspense
        fallback={
          <PageLoader>
            <Loader icon="airplane" />
          </PageLoader>
        }
      >
        <MyFlightOrder orderId={orderId} />
      </Suspense>
    );
  }

  return null;
};

function MyHotelReservation({ orderId }: { orderId: string }) {
  const { data } = useSuspenseQuery(hotelOrderQueryOptions(orderId));
  const { history } = useRouter();

  if (data.status === OrderType.REFUNDED || !data.reservation) {
    return (
      <>
        <MyTripsHeader onBackClick={() => history.back()} />
        <OrderDetailsWithoutReservation order={data} />
      </>
    );
  }

  return (
    <>
      <MyTripsHeader onBackClick={() => history.back()} />
      <MyHotel reservation={data.reservation} />
    </>
  );
}

function MyFlightOrder({ orderId }: { orderId: string }) {
  const { data } = useSuspenseQuery(flightOrderQueryOptions(orderId));

  if (!data.reservation) {
    return (
      <>
        <MyTripsHeader onBackClick={() => history.back()} />
        <OrderDetailsWithoutReservation order={data} />
      </>
    );
  }

  return (
    <>
      <MyTripsHeader onBackClick={() => history.back()} />
      <MyFlight reservation={data.reservation} status={data.status} />
    </>
  );
}
