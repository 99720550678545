import { useInfiniteQuery } from "@tanstack/react-query";

import { BASE_API_URL } from "../routes";
import {
  FindOrdersReservationsForFlightsOutputDto,
  FindUserFlightReservationFlight,
} from "../types/codegen";
import api from "../api/api";
import { ItieraryFlight } from "../types/types";
import { sumDuration } from "../utils/duration";
import { OrderType } from "../types/enums";

export const mapFlightReservation = (
  flight: FindUserFlightReservationFlight,
): ItieraryFlight => ({
  duration: sumDuration([
    ...flight.stops.map((stop) => stop.stopTime),
    ...flight.segments.map((segment) => segment.flightDuration),
  ]),
  stops: flight.stops,
  segments: flight.segments.map((segment) => ({
    duration: segment.flightDuration,
    marketingCarrier: {
      flightNumber: segment.flightNumber,
      airline: segment.marketingCarrier,
    },
    departure: {
      date: segment.origin.date,
      time: segment.origin.time,
      code: segment.origin.code,
      airport: {
        code: segment.origin.code,
        name: segment.origin.code,
      },
    },
    arrival: {
      date: segment.destination.date,
      time: segment.destination.time,
      code: segment.destination.code,
      airport: {
        code: segment.destination.code,
        name: segment.destination.code,
      },
    },
  })),
});

const PAGE_SIZE = 10;

export const useFlightsReservations = ({
  orderType,
}: {
  orderType: OrderType;
}) => {
  const params = { perPage: PAGE_SIZE };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isError } =
    useInfiniteQuery({
      queryKey: ["flights", "reservations", orderType] as const,
      queryFn: ({ pageParam, signal }) =>
        api
          .get<FindOrdersReservationsForFlightsOutputDto>(
            `${BASE_API_URL}/orders/reservations/flight`,
            {
              params: {
                ...params,
                searchFilter: orderType,
                page: pageParam,
              },
              signal,
            },
          )
          .then((res) => res.data),
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.hasMore ? pages.length + 1 : undefined,
    });

  const isEmpty = data?.pages.flatMap((page) => page.data).length === 0;
  const hasReachedEnd = !hasNextPage;

  return {
    data,
    isLoading,
    isFetching,
    isError,
    isEmpty,
    hasReachedEnd,
    fetchNextPage,
    orderType,
  };
};
