import { PropsWithChildren, useState } from "react";
import type { IFlightSelectionData } from "@/app/lib/types/interfaces";
import type { FindFlightOffersOffer } from "@/app/lib/types/codegen";
import { useNavigate } from "@tanstack/react-router";
import { FlightSelectionPayloadContext } from "./FlightSelectionPayloadContext";

export const FlightSelectionPayloadProvider = ({
  children,
}: PropsWithChildren) => {
  const navigate = useNavigate();
  const [selectedFlightFromOfferList, setSelectedFlightFromOfferList] =
    useState<FindFlightOffersOffer | null>(null);
  const [selectedFlightPayload, setSelectedFlightPayload] =
    useState<IFlightSelectionData | null>(null);

  const handleFlightSelection = (
    dataPayload: IFlightSelectionData,
    itiernaryFromList: FindFlightOffersOffer,
  ) => {
    setSelectedFlightPayload(dataPayload);
    setSelectedFlightFromOfferList(itiernaryFromList);
    navigate({
      to: "/flights/offer/$id/step/1",
      params: { id: dataPayload.offerId },
    });
  };

  return (
    <FlightSelectionPayloadContext.Provider
      value={{
        selectedFlightPayload,
        handleFlightSelection,
        selectedFlightFromOfferList,
      }}
    >
      {children}
    </FlightSelectionPayloadContext.Provider>
  );
};
