import { PropsWithChildren } from "react";
import {
  SingleFightDetailsData,
  SingleFlightSelectionContext,
} from "./SingleFlightDetailsContext";

export const SingleFlightDetailsProvider = ({
  data,
  isAdditionalDataLoading,
  children,
}: PropsWithChildren<{
  data: SingleFightDetailsData;
  isAdditionalDataLoading?: boolean;
}>) => {
  const departureFlight = data?.flights?.[0] ?? null;
  const returnFlight =
    ((data?.flights?.length || 0) > 1 &&
      data?.flights?.[data?.flights.length - 1]) ||
    null;
  const departureSegments = departureFlight?.segments || null;
  const returnSegments = returnFlight?.segments || null;
  const origin = departureFlight?.segments[0]?.departure || null;
  const destination =
    departureFlight?.segments[departureFlight?.segments.length - 1]?.arrival ||
    null;

  return (
    <SingleFlightSelectionContext.Provider
      value={{
        singleFlightDetails: data,
        departureFlight,
        departureSegments,
        returnFlight,
        returnSegments,
        origin,
        destination,
        isAdditionalDataLoading: isAdditionalDataLoading || false,
      }}
    >
      {children}
    </SingleFlightSelectionContext.Provider>
  );
};
