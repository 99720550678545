import dayjs from "dayjs";

export const sumDuration = (durations: (string | undefined | null)[]) => {
  const sum = durations.reduce((acc, duration) => {
    if (!duration) {
      return acc;
    }

    const timeArray = duration.split(":");
    return acc.add(
      dayjs.duration({ hours: +timeArray[0], minutes: +timeArray[1] }),
    );
  }, dayjs.duration(0));

  return `${Math.floor(sum.asHours())}:${sum.minutes()}`;
};
