import { queryOptions } from "@tanstack/react-query";
import api from "@/app/lib/api/api";

export const applicationStateQueryOptions = () =>
  queryOptions({
    queryKey: ["toggle", "application-state-turned-off"],
    queryFn: ({ signal }) =>
      // TODO: replace with types from BE when available
      api.get<{ data: boolean }>("/toggle/application-state-turned-off", {
        signal,
      }),
    select: (response) => response.data.data,
  });
