import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

import { ConfirmDrawer } from "../ConfirmDrawer";

function CancelFlightPurchase({
  open,
  closeDrawer,
}: {
  open: boolean;
  closeDrawer: () => void;
}) {
  const t = useTranslations();
  const navigate = useNavigate();

  return (
    <ConfirmDrawer
      open={open}
      onClose={closeDrawer}
      onConfirm={() => {
        navigate({ to: "/", search: { category: "flights" } });
      }}
      title={t("flights.cancelTicketPurchase")}
      description={t("flights.cancelTicketPurchaseDesc")}
      closeLabel={t("back")}
      confirmLabel={t("cancel")}
    />
  );
}

export default CancelFlightPurchase;
