import { Card, Divider } from "@mui/material";
import {
  FindOrdersReservationsForFlightOrder,
  FindOrdersReservationsForHotelOrder,
} from "../lib/types/codegen";
import { OrderLabel } from "./my-trips/OrderLabel";
import { formatCurrency } from "../lib/utils/currency";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";

type Props = {
  order:
    | FindOrdersReservationsForFlightOrder
    | FindOrdersReservationsForHotelOrder;
  onClick?: () => void;
};

export const OrderCardWithoutReservation = ({ order, onClick }: Props) => {
  const t = useTranslations();
  return (
    <Card
      className="flex flex-col gap-4 p-4"
      onClick={onClick}
      sx={{
        "& + &": { mt: 1 },
        borderRadius: "1.5rem",
      }}
      elevation={0}
    >
      <div className="flex w-full justify-between">
        <span>
          {`${t("reservations.bookedAt")}: ${dayjs(order.createdAt).format("DD MMM, YYYY")}`}
        </span>

        <OrderLabel status={order.status} />
      </div>
      <Divider />
      <div className="flex items-center justify-between">
        <span>{`${t("reservations.id")}: ${order.id}`}</span>
        <strong>
          {formatCurrency(order.totalPrice, order.reservation?.currency)}
        </strong>
      </div>
    </Card>
  );
};
