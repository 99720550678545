import { useLayoutEffect, useMemo } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import type { FindFlightOffersOutputDto } from "@/app/lib/types/codegen";
import api from "@/app/lib/api/api";
import { PAGE_SIZE } from "./useFlightSearchTokens";
import { useFlightSortContext } from "../context/FlightSortContext";
import { useFlightFiltersContext } from "../context/FlightFiltersContext";
import { StopsOptions } from "../types/enums";
import { useFlightsTokensContext } from "../context/FlightsTokensContext";

export function useFlightsSearch() {
  const {
    data,
    isError: isTokenError,
    isLoading: isTokenLoading,
    isFetching: isTokenFetching,
    isSuccess: isTokenSuccess,
  } = useFlightsTokensContext();

  const { sortValue } = useFlightSortContext();
  const { filters, updateFilters } = useFlightFiltersContext();

  // using useLayoutEffect to avoid UI flickering
  useLayoutEffect(() => {
    if (isTokenSuccess && !filters) {
      updateFilters({
        stops: StopsOptions.ANY,
        selectedAirlines: data.carriers,
        airlines: data.airlines,
        priceRange: [data.minPrice, data.maxPrice],
      });
    }
  }, [
    filters,
    updateFilters,
    isTokenSuccess,
    data?.carriers,
    data?.airlines,
    data?.minPrice,
    data?.maxPrice,
  ]);

  const params =
    filters && data
      ? {
          searchResultKey: data.searchResultKey,
          searchResultToken: data.searchResultToken,
          perPage: PAGE_SIZE,
          sortOrder: sortValue.order,
          sortField: sortValue.field,
          minPrice: filters.priceRange[0],
          maxPrice: filters.priceRange[1],
          carriers: filters.selectedAirlines,
          stops: filters.stops,
        }
      : null;

  const {
    data: flightsData,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isError,
  } = useInfiniteQuery({
    queryKey: ["flights", "search", "results", params] as const,
    queryFn: ({ pageParam, signal }) =>
      api
        .get<FindFlightOffersOutputDto>(`/flights`, {
          params: {
            ...params,
            page: pageParam,
          },
          signal,
        })
        .then((res) => res.data),
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) =>
      lastPage.data.hasMore ? pages.length + 1 : undefined,
    enabled: Boolean(params),
  });

  const offers = useMemo(() => {
    if (flightsData) {
      return flightsData.pages.flatMap((page) => page.data.offers);
    }

    return [];
  }, [flightsData]);

  const isEmpty = offers.length === 0;
  const hasReachedEnd = !hasNextPage;

  return {
    offers,
    isEmpty,
    fetchNextPage,
    searchResultToken: data?.searchResultToken,
    searchResultKey: data?.searchResultKey,
    hasReachedEnd,
    isLoading: isLoading || isTokenLoading,
    isFetching: isFetching || isTokenFetching,
    isError: isError || isTokenError,
  };
}
