import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";

import { TFlightFormValues } from "@/app/lib/types/types";
import {
  InitiateFlightsSearchBodyInputDto,
  InitiateFlightsSearchOutputDto,
} from "../types/codegen";
import api from "../api/api";
import { TripType } from "../types/enums";
import { useFlightSearchDataContext } from "../context/FlightSearchDataContext";
import { useFlightSortContext } from "../context/FlightSortContext";

export const PAGE_SIZE = 20;

const dataMapper = (
  data: TFlightFormValues | undefined,
): InitiateFlightsSearchBodyInputDto | null => {
  if (!data) {
    return null;
  }

  const transformedData: InitiateFlightsSearchBodyInputDto = {
    originDestinations: [
      {
        origin: {
          id: data.from.id as number,
          type: data.from?.airports ? "CITY" : "AIRPORT",
        },
        destination: {
          id: data.to.id as number,
          type: data.to?.airports ? "CITY" : "AIRPORT",
        },
        date: dayjs(data.dates.departure).format("YYYY-MM-DD"),
      },
    ],
    cabinClass: data.cabinClass,
    passengers: {
      passengerTypeCodeList: [],
    },
  };

  if (data.tripType === TripType.ROUND_TRIP) {
    transformedData.originDestinations.push({
      origin: {
        id: data.to.id as number,
        type: data.to?.airports ? "CITY" : "AIRPORT",
      },
      destination: {
        id: data.from.id as number,
        type: data.from?.airports ? "CITY" : "AIRPORT",
      },
      date: dayjs(data.dates.return).format("YYYY-MM-DD"),
    });
  }

  if (data.adult) {
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "ADT",
      count: data.adult,
    });
  }

  if (data.child) {
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "CHD",
      count: data.child,
    });
  }

  if (data.infantWithoutSeat) {
    transformedData.passengers.passengerTypeCodeList.push({
      passengerTypeCode: "INF",
      count: data.infantWithoutSeat,
    });
  }

  return transformedData;
};

export function useFlightSearchTokens() {
  const { searchData } = useFlightSearchDataContext();
  const { sortValue } = useFlightSortContext();

  const mappedData = dataMapper(searchData);
  const params = {
    perPage: PAGE_SIZE,
    sortField: sortValue.field,
    sortOrder: sortValue.order,
  };

  return useQuery({
    queryKey: ["flights", "search", "tokens", mappedData, params] as const,
    queryFn: ({ signal }) =>
      api.post<InitiateFlightsSearchOutputDto>(`/flights/search`, mappedData, {
        params,
        signal,
      }),
    select: (response) => {
      const data = response.data.data;

      return {
        maxPrice: data.maxPrice,
        minPrice: 1,
        searchResultKey: data.searchResultKey,
        searchResultToken: data.searchResultToken,
        carriers: data.carriers.map((c) => c.code),
        airlines: data.carriers,
      };
    },
    enabled: Boolean(mappedData),
    gcTime: 0,
  });
}
