"use client";

import { useState } from "react";
import { Button } from "@mui/material";
import { UserPlus } from "@phosphor-icons/react";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useTranslations } from "use-intl";
import PageLoader from "../PageLoader";
import AnimatedLoader from "../AnimatedLoader";
import SingleTravellerInList from "@/app/ui/travellers/SingleTravellerInList";
import DeleteTraveller from "@/app/ui/travellers/DeleteTraveller";
import TravellerForm from "@/app/ui/travellers/TravellerForm";
import dayjs from "dayjs";
import { TravellerCloseConfirmDrawer } from "./TravellerCloseConfirmDrawer";
import { Drawer } from "../Drawer";
import { Traveller } from "@/app/lib/types/types";

type Props = {
  adultsToSelect: number;
  childrenToSelect: number;
  infantsToSelect: number;
  travelDate: Date | null;
};

function TravellersList({
  adultsToSelect,
  childrenToSelect,
  infantsToSelect,
  travelDate,
}: Props) {
  const [travellerForDeletion, setTravellerForDeletion] =
    useState<Traveller | null>(null);
  const [travellerForEdit, setTravellerForEdit] = useState<Traveller | null>(
    null,
  );
  const [newTraveller, setNewTraveller] = useState(false);
  const [isCloseConfirmOpen, setIsCloseConfirmOpen] = useState(false);
  const t = useTranslations();
  const {
    travellers,
    selectedTravellers,
    selectedTravellersByAge,
    toggleTravellerSelection,
    removeTraveller,
    editTraveller,
    addTraveller,
    isReady: isTravelersReady,
  } = useTravellersContext();

  const initTravellerDeletion = (traveller: Traveller) => {
    setTravellerForDeletion(traveller);
  };

  const initTravellerEdit = (traveller: Traveller) => {
    setTravellerForEdit(traveller);
  };

  const closeDrawerHandler = () => {
    setTravellerForDeletion(null);
    setTravellerForEdit(null);
    setNewTraveller(false);
  };

  if (!isTravelersReady) {
    return (
      <div className="my-20">
        <PageLoader>
          <AnimatedLoader icon="users" />
        </PageLoader>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full flex-col px-4">
        <div className="flex w-full flex-col">
          <div className="section-title m-0">
            {t("travellers.selectedTravellers")}
          </div>

          {adultsToSelect ? (
            <div className="section-title m-0">
              {t("travellers.adult")} {selectedTravellersByAge.adults}/
              {adultsToSelect}
            </div>
          ) : null}
          {childrenToSelect ? (
            <div className="section-title m-0">
              {t("travellers.child")} {selectedTravellersByAge.children}/
              {childrenToSelect}
            </div>
          ) : null}
          {infantsToSelect ? (
            <div className="section-title m-0">
              {t("travellers.infant")} {selectedTravellersByAge.infants}/
              {infantsToSelect}
            </div>
          ) : null}
        </div>
        <div className="mt-2 flex flex-col gap-2 overflow-y-auto">
          {travellers?.map((traveller, index) => {
            // block the traveller selection if the first travel date (check-in/departure) is less than 6 months from the passport expiry date
            const isExpired = travelDate
              ? dayjs(travelDate)
                  .add(6, "months")
                  .isAfter(dayjs(traveller.passportExpiryDate))
              : false;
            return (
              <SingleTravellerInList
                lastInList={travellers.length === index + 1}
                key={traveller.id}
                checkDisabled={isExpired}
                hint={isExpired ? t("travellers.passportExpiryHint") : ""}
                traveller={traveller}
                toggleTravellerSelection={toggleTravellerSelection}
                selectedTravellers={selectedTravellers}
                onDelete={initTravellerDeletion}
                onEdit={initTravellerEdit}
                controlled
              />
            );
          })}
        </div>
        <Button
          variant="text"
          size="small"
          onClick={() => {
            setNewTraveller(true);
          }}
          sx={{
            margin: "calc(var(--spacing) * 2) 0",
          }}
          color="primary"
        >
          <UserPlus size={24} className="fill-interactive me-2" />
          {t("travellers.addNew")}
        </Button>
      </div>

      <Drawer open={Boolean(newTraveller || travellerForEdit)} hasFullHeight>
        <TravellerForm
          onClose={(reason) => {
            if (reason === "cancelWithPendingChanges") {
              setIsCloseConfirmOpen(true);
              return;
            }

            closeDrawerHandler();
          }}
          traveller={travellerForEdit || null}
          onSubmit={(data) =>
            travellerForEdit
              ? editTraveller(travellerForEdit.id, data)
              : addTraveller(data)
          }
        />
      </Drawer>

      <TravellerCloseConfirmDrawer
        open={isCloseConfirmOpen}
        onClose={() => {
          setIsCloseConfirmOpen(false);
        }}
        onConfirm={() => {
          setIsCloseConfirmOpen(false);
          closeDrawerHandler();
        }}
      />

      <Drawer open={Boolean(travellerForDeletion)}>
        {travellerForDeletion ? (
          <DeleteTraveller
            traveller={travellerForDeletion}
            deleteTraveller={() => removeTraveller(travellerForDeletion.id)}
            closeDrawer={closeDrawerHandler}
          />
        ) : null}
      </Drawer>
    </>
  );
}

export default TravellersList;
