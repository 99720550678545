"use client";

import {
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

export const StyledToggleButton = styled(ToggleButton)(() => ({
  border: "none",
  textTransform: "none",
  color: "white !important",
  fontWeight: "500",
  fontSize: "16px",
  letterSpacing: "0.15px",
  lineHeight: "22px",
  borderRadius: "inherit !important",
  margin: "0 !important",
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: "white !important",
    color: "black !important",
  },
}));

interface ScopeSwitcherProps {
  current: "flights" | "hotels";
}

const ScopeSwitcher = ({ current }: ScopeSwitcherProps) => {
  const t = useTranslations();
  const navigate = useNavigate();

  const handleChange = (
    _: React.MouseEvent<HTMLElement> | null,
    newScope: string | null,
  ) => {
    navigate({
      to: "/",
      search: {
        category: (newScope as ScopeSwitcherProps["current"]) || current,
      },
      replace: true,
    });
  };

  return (
    <div className="z-10 flex rounded-3xl">
      <ToggleButtonGroup
        color="primary"
        value={current}
        exclusive
        onChange={handleChange}
        aria-label="Platform"
        sx={{
          backgroundColor: "var(--color-surface-accent-strong)",
          borderRadius: "46px",
          padding: "4px !important",
          height: "46px",
        }}
      >
        <StyledToggleButton value="flights" className="min-w-28">
          {t("flights.title")}
        </StyledToggleButton>
        <StyledToggleButton value="hotels" className="min-w-28">
          {t("hotels.title")}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export default ScopeSwitcher;
