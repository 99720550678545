import { Drawer, Button } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

const BottomDrawer = ({
  totalPrice,
  buttonLabel,
  onButtonClick,
  disabled,
}: {
  totalPrice: string;
  buttonLabel: string;
  onButtonClick: () => void;
  disabled: boolean;
}) => {
  const t = useTranslations();

  return (
    <>
      {/* Buffer for botttom drawer so we can scroll to the bottom */}
      <div className="h-30" />

      <Drawer anchor="bottom" variant="persistent" open>
        <div className="flex h-full flex-col gap-4">
          <div className="flex items-center justify-between">
            <h5 className="text-primary text-sm font-semibold">
              {t("flights.totalPrice")}
            </h5>

            <span className="text-primary text-md flex items-center gap-1 font-semibold">
              {totalPrice}
              <CaretRight
                color="#6A778A"
                size={16}
                className="rtl:-scale-x-100"
              />
            </span>
          </div>
          <Button
            fullWidth
            variant="contained"
            onClick={onButtonClick}
            disabled={disabled}
          >
            {t(buttonLabel)}
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default BottomDrawer;
