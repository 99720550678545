import { createContext, Dispatch, SetStateAction, useContext } from "react";
import { IHotelsFilters } from "../../types/interfaces";

export interface HotelFiltersContextValue {
  filters: IHotelsFilters | null;
  defaultFilters: IHotelsFilters | null;
  updateFilters: Dispatch<SetStateAction<IHotelsFilters | null>>;
  resetFilters: () => void;
}

export const HotelFiltersContext =
  createContext<HotelFiltersContextValue | null>(null);

export const useHotelFiltersContext = () => {
  const context = useContext(HotelFiltersContext);
  if (!context) {
    throw new Error(
      "useHotelFiltersContext must be used within a HotelFiltersProvider",
    );
  }
  return context;
};
