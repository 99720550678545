import { Avatar, AvatarGroup } from "@mui/material";

type Airline = {
  name: string;
  logoUrl: string;
};

type Props = {
  airlines: Airline[];
};

function AirlineAvatarGroup({ airlines }: Props) {
  return (
    <AvatarGroup>
      {airlines.map((airline) => (
        <Avatar
          key={airline.name}
          alt={`${airline.name} company logo`}
          sx={{
            width: 24,
            height: 24,
          }}
          src={airline.logoUrl}
        >
          {airline.name?.charAt(0) || ""}
        </Avatar>
      ))}
    </AvatarGroup>
  );
}

export default AirlineAvatarGroup;
