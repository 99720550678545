import { CircularProgress } from "@mui/material";
import { useTranslations } from "use-intl";

import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";
import { GetFlightAvailabilityTokenOptionalService } from "@/app/lib/types/codegen";
import { isBaggageService } from "../lib/utils/baggageProcessor";

function AdditionalInfoCard({
  singleFlightDetails,
  isAdditionalDataLoading,
}: {
  singleFlightDetails: SingleFightDetailsData;
  isAdditionalDataLoading: boolean;
}) {
  const t = useTranslations();
  const processedServices = singleFlightDetails.optionalServices
    ?.filter((service) => !isBaggageService(service))
    .reduce((acc, service) => {
      // remove duplicates based on service name
      if (!acc.find((item) => item.service.name === service.service.name)) {
        acc.push(service);
      }
      return acc;
    }, [] as GetFlightAvailabilityTokenOptionalService[]);

  return (
    <>
      <div className="section-title mt-4">{t("flights.additionalInfo")}</div>
      <div className="section-card flex gap-4">
        {!isAdditionalDataLoading && !processedServices?.length && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            {t("flights.noDetails")}
          </div>
        )}
        {isAdditionalDataLoading && (
          <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
            <CircularProgress size={20} />
            {t("flights.retrievingDetails")}
          </div>
        )}
        {processedServices && processedServices.length > 0 && (
          <div className="flex w-full flex-col gap-2">
            <h3 className="text-primary text-sm font-semibold">
              {t("flights.offerDetails")}
            </h3>
            {processedServices.map((service) => (
              <p
                key={
                  service.service.name + service.passengerIndex + service.target
                }
                className="text-primary text-sm font-normal"
              >
                {service.service.name} {t("flights.included")}
              </p>
            ))}
          </div>
        )}
      </div>

      <div className="section-card mt-2 flex gap-4">
        <div className="flex w-full flex-col gap-2">
          <h3 className="text-primary text-sm font-semibold">
            {t("flights.regulationsOnSpecialBaggage")}
          </h3>
          <p className="text-primary text-sm font-normal">
            {t("flights.regulationsDetails")}
          </p>
        </div>
      </div>
    </>
  );
}

export default AdditionalInfoCard;
