import { queryOptions, skipToken } from "@tanstack/react-query";
import api from "../api/api";
import { FindHotelOffersOutputDto } from "../types/codegen";

interface HotelOfferQueryParams {
  hotelId: string;
  searchResultKey: string;
  searchResultToken: string;
}

export const hotelOfferQueryOptions = (params: HotelOfferQueryParams | null) =>
  queryOptions({
    queryKey: ["hotel-offer", params] as const,
    queryFn: params
      ? ({ signal }) =>
          api.get<FindHotelOffersOutputDto>(
            `/hotels/${params.hotelId}/offers`,
            {
              params: {
                searchResultKey: params.searchResultKey,
                searchResultToken: params.searchResultToken,
              },
              signal,
            },
          )
      : skipToken,
    select: (response) => response.data.data,
  });
