import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { MagnifyingGlass, SuitcaseSimple, Users } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";
import {
  useLocation,
  useMatchRoute,
  useNavigate,
} from "@tanstack/react-router";

const VALUES_TO_ROUTES = {
  search: "/",
  trips: "/trips",
  travelers: "/travelers",
} as const;

const Footer = () => {
  const t = useTranslations();
  const navigate = useNavigate();
  const location = useLocation();

  const matches = useMatchRoute();

  const handleChange = (
    _: React.SyntheticEvent,
    newValue: keyof typeof VALUES_TO_ROUTES,
  ) => {
    const clearHistory =
      location.pathname === VALUES_TO_ROUTES.travelers ||
      location.pathname === VALUES_TO_ROUTES.trips;

    if (clearHistory) {
      window.history.replaceState(null, "", "/");
    }
    navigate({
      to: VALUES_TO_ROUTES[newValue],
      replace: clearHistory,
    });
  };

  const value = matches({ to: "/trips", fuzzy: true })
    ? "trips"
    : matches({ to: "/travelers", fuzzy: true })
      ? "travelers"
      : "search";

  return (
    <BottomNavigation
      dir="ltr" // we want to keep consistent order here
      className="absolute bottom-0 z-20 w-full flex-row rounded-t-2xl border-t border-t-slate-300 pt-2 pb-2"
      showLabels
      value={value}
      onChange={handleChange}
      sx={{
        height: "calc(env(safe-area-inset-bottom) + var(--footer-height))",
        paddingBottom: "calc(env(safe-area-inset-bottom) + 0.5rem)",
      }}
    >
      <BottomNavigationAction
        label={t("search")}
        icon={<MagnifyingGlass size={24} color="#003E2B" />}
        value="search"
      />
      <BottomNavigationAction
        label={t("myTrips")}
        icon={<SuitcaseSimple size={24} color="#003E2B" />}
        value="trips"
      />
      <BottomNavigationAction
        label={t("travellers.title")}
        icon={<Users size={24} color="#003E2B" />}
        value="travelers"
      />
    </BottomNavigation>
  );
};

export default Footer;
