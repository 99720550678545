import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";

import { HotelsTokensProvider } from "@/app/lib/context/HotelsTokensContext";
import { useHotelSearchTokens } from "@/app/lib/hooks/useHotelSearchTokens";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import { PurchaseStateContextProvider } from "@/app/lib/context/PurchaseStateContext";

export const Route = createFileRoute("/_authenticated/_hotels")({
  component: RouteComponent,
});

function RouteComponent() {
  const { searchData } = useHotelSearchDataContext();

  const { isLoading, data } = useHotelSearchTokens({ searchData: searchData! });

  if (!searchData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    return <Navigate to="/" search={{ category: "hotels" }} />;
  }

  return (
    <PurchaseStateContextProvider>
      <HotelsTokensProvider isLoading={isLoading} data={data || null}>
        <Outlet />
      </HotelsTokensProvider>
    </PurchaseStateContextProvider>
  );
}
