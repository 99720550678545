import useInfiniteScroll from "react-infinite-scroll-hook";
import Loader from "../AnimatedLoader";
import { useTranslations } from "use-intl";
import NoResultsError from "../NoResultsError";
import type { FindOrdersReservationsForHotelOrder } from "@/app/lib/types/codegen";
import { useHotelsReservations } from "@/app/lib/hooks/useHotelsReservations";
import { MyHotelCard } from "./MyHotelCard";
import PageLoader from "../PageLoader";
import { formatCurrency } from "@/app/lib/utils/currency";
import { OrderType } from "@/app/lib/types/enums";
import { OrderCardWithoutReservation } from "../OrderCardWithoutReservation";

export const MyHotelsList = ({
  onOrderClick,
  orderType,
  orderTypeSwitcher,
}: {
  onOrderClick: (flight: FindOrdersReservationsForHotelOrder) => void;
  orderType: OrderType;
  orderTypeSwitcher: React.ReactNode;
}) => {
  const t = useTranslations();
  const {
    data,
    isLoading,
    isFetching,
    isError,
    hasReachedEnd,
    isEmpty,
    fetchNextPage,
  } = useHotelsReservations({ orderType });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !hasReachedEnd,
    onLoadMore: () => {
      fetchNextPage();
    },
    disabled: isError,
    rootMargin: "0px 0px 40px 0px",
  });

  if (isLoading) {
    return (
      <PageLoader>
        <Loader icon="buildings" />
      </PageLoader>
    );
  }

  if (isEmpty) return <NoResultsError tab="hotels" />;

  const bookingCount = data?.pages?.[0]?.data?.count;

  return (
    <>
      <div className="mb-4 flex items-center justify-between">
        {orderTypeSwitcher}
        {bookingCount ? (
          <div className="text-md font-medium">
            {t("hotels.bookedHotels", { bookingCount })}
          </div>
        ) : null}
      </div>

      {data?.pages?.flatMap((page) =>
        page.data.orders.map((order) =>
          order.status === OrderType.REFUNDED ? (
            <OrderCardWithoutReservation key={order.id} order={order} />
          ) : order.reservation ? (
            <MyHotelCard
              key={order.reservation.id}
              onClick={() => order.reservation && onOrderClick(order)}
              reservation={order.reservation}
              footer={
                <div className="flex flex-row-reverse items-center justify-between">
                  <span
                    className="text-primary text-sm font-semibold"
                    dir="ltr"
                  >
                    {formatCurrency(
                      order.totalPrice,
                      order.reservation.currency,
                    )}
                  </span>
                </div>
              }
            />
          ) : null,
        ),
      )}

      {isFetching || !hasReachedEnd ? (
        <div
          className="flex items-center justify-center py-4"
          ref={sentryRef}
          aria-hidden={!isFetching}
        >
          <Loader icon="buildings" />
        </div>
      ) : (
        <div className="text-tertiary flex items-center justify-center p-8">
          {t("hotels.noResults")}
        </div>
      )}
    </>
  );
};
