import { useQuery } from "@tanstack/react-query";

import { BASE_API_URL } from "@/app/lib/routes";
import { THotelFormValues } from "@/app/lib/types/types";
import { InitiateHotelsSearchOutputDto } from "../types/codegen";
import api from "../api/api";

const dataMapper = (searchData: THotelFormValues) => {
  return {
    cityId: searchData.destination.id,
    checkIn: searchData.dates.checkIn,
    checkOut: searchData.dates.checkOut,
    travelPurpose: searchData.travelPurpose,
    occupancy: {
      leaderNationality: 1,
      rooms: searchData.rooms.map(({ adults, childrenAges }) => ({
        adults,
        childrenAges,
      })),
    },
  };
};

type UseHotelOfferSearchParams = {
  searchData: THotelFormValues;
};

export function useHotelSearchTokens({
  searchData,
}: UseHotelOfferSearchParams) {
  const mappedData = dataMapper(searchData);

  return useQuery({
    queryKey: ["hotels", "search", "tokens", mappedData] as const,
    queryFn: ({ signal }) =>
      api.post<InitiateHotelsSearchOutputDto>(`${BASE_API_URL}/hotels/search`, {
        ...mappedData,
        signal,
      }),
    select: (response) => response.data.data,
    enabled: Boolean(mappedData),
    gcTime: 0,
  });
}
