import { PropsWithChildren } from "react";
import {
  HotelsTokensContext,
  HotelsTokensContextValue,
} from "./HotelsTokensContext";

type Props = PropsWithChildren<HotelsTokensContextValue>;

export const HotelsTokensProvider = ({ children, data, isLoading }: Props) => {
  return (
    <HotelsTokensContext.Provider value={{ data, isLoading }}>
      {children}
    </HotelsTokensContext.Provider>
  );
};
