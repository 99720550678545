import { ReactNode } from "react";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";

import FlightArrow from "../FlightArrow";

type FlightTimeInfoProps = {
  date: string;
  time: string;
  code: string;
};

export function FlightTimeInfo({ date, time, code }: FlightTimeInfoProps) {
  return (
    <div className="flex flex-col last:text-end">
      <span className="text-tertiary text-sm">
        {dayjs(date).format("MMMM DD")}
      </span>
      <h3 className="text-title text-xl font-semibold">{time.slice(0, 5)}</h3>
      <span className="text-tertiary text-sm">{code}</span>
    </div>
  );
}

type FlightOverallTimeProps = {
  stops: number;
  overallTime: string | null;
};

export const FlightOverallTime = ({
  overallTime,
  stops,
}: FlightOverallTimeProps) => {
  const t = useTranslations();

  return (
    <div className="flex flex-col items-center gap-0.5">
      <FlightArrow overallTime={overallTime} />
      <span className="text-tertiary text-sm">
        {stops
          ? `${stops} ${stops === 1 ? t("flights.stop") : t("flights.stops")}`
          : t("flights.direct")}
      </span>
    </div>
  );
};

type FlightInfoProps = {
  origin: FlightTimeInfoProps;
  destination: FlightTimeInfoProps;
  children: ReactNode;
};

export function FlightInfo({ origin, destination, children }: FlightInfoProps) {
  return (
    <div className="flex w-full items-end justify-between gap-6">
      <FlightTimeInfo {...origin} />

      <div className="grow">{children}</div>

      <FlightTimeInfo {...destination} />
    </div>
  );
}
