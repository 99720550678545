import { createContext, useContext } from "react";
import type { IFlightSelectionPayloadContext } from "@/app/lib/types/interfaces";

const defaultContextValue: IFlightSelectionPayloadContext = {
  selectedFlightPayload: null,
  selectedFlightFromOfferList: null,
  handleFlightSelection: () => {},
};

export const FlightSelectionPayloadContext =
  createContext<IFlightSelectionPayloadContext>(defaultContextValue);

export const useFlightSelectionPayloadContext = () =>
  useContext(FlightSelectionPayloadContext);
