import { useQuery } from "@tanstack/react-query";

import api from "../api/api";
import { FindUserReservationDocumentsOutputDto } from "../types/codegen";

export const useReservationDocuments = (orderId?: number) => {
  return useQuery({
    queryKey: ["reservations", orderId, "documents"] as const,

    queryFn: ({ signal }) =>
      api.get<FindUserReservationDocumentsOutputDto>(
        `/reservations/${orderId}/documents`,
        {
          signal,
        },
      ),
    select: (response) => response.data.data.documents,
    enabled: Boolean(orderId),
    refetchInterval: (query) => {
      const data = query.state.data;

      if (!data || data.data.data.documents.length === 0) {
        return 2000;
      }

      return false;
    },
  });
};
