import {
  FindOrderReservationForFlightData,
  FindOrderReservationForHotelData,
} from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";
import { OrderStatusSection } from "../OrderStatusSection";
import dayjs from "dayjs";
import { formatCurrency } from "@/app/lib/utils/currency";
import { Button } from "@mui/material";
import { WHATSAPP_URL } from "@/app/lib/routes";

type Props = {
  order: FindOrderReservationForFlightData | FindOrderReservationForHotelData;
};

export const OrderDetailsWithoutReservation = ({ order }: Props) => {
  const t = useTranslations();

  return (
    <>
      <OrderStatusSection status={order.status} />
      <section>
        <ul className="w-full items-center justify-between gap-4 space-y-2 rounded-3xl bg-white p-4 text-sm">
          <li className="flex w-full items-center justify-between">
            <span className="text-title">{t("reservations.orderId")}:</span>
            <span className="text-title font-semibold">{order.id}</span>
          </li>
          <li className="flex w-full items-center justify-between">
            <span className="text-title">{t("reservations.bookingTime")}:</span>
            <span className="text-title font-semibold">
              {dayjs(order.createdAt).format("HH:mm, DD MMM YYYY")}
            </span>
          </li>
          <li className="flex w-full items-center justify-between">
            <span className="text-title">{t("flights.totalPrice")}:</span>
            <span className="text-title font-semibold" dir="ltr">
              {formatCurrency(order.totalPrice)}
            </span>
          </li>
        </ul>
      </section>
      <section className="mt-4 flex w-full flex-col items-end gap-1 space-y-2 rounded-3xl bg-white p-4">
        <h3 className="mb-2 text-sm font-semibold">
          {t("reservations.doYouNeedMoreInformation")}
        </h3>
        <p className="text-primary text-sm">
          {t("reservations.reachSupportTeam")}
        </p>
        <Button
          variant="text"
          onClick={() => window.open(WHATSAPP_URL, "_blank")}
          sx={{
            paddingRight: "0",
          }}
        >
          <span className="text-sm">{t("contactWhatsAppSupport")}</span>
        </Button>
      </section>
    </>
  );
};
