import { useTranslations } from "use-intl";
import { FormControlLabel, RadioGroup, Radio } from "@mui/material";
import { useFormikContext } from "formik";

import { TFlightFormValues } from "@/app/lib/types/types";
import { TripType } from "@/app/lib/types/enums";

function TripTypeRadioGroup() {
  const t = useTranslations();
  const { values, handleChange } = useFormikContext<TFlightFormValues>();

  return (
    <RadioGroup
      row
      name="tripType"
      className="mb-5 flex justify-center"
      value={values.tripType}
      onChange={handleChange}
    >
      <FormControlLabel
        value={TripType.ONE_WAY}
        control={<Radio />}
        label={t("flights.search.oneWay")}
        sx={{
          "& .MuiTypography-root": {
            fontSize: 14,
          },
        }}
      />
      <FormControlLabel
        value={TripType.ROUND_TRIP}
        control={<Radio />}
        label={t("flights.search.roundTrip")}
        sx={{
          "& .MuiTypography-root": {
            fontSize: 14,
          },
        }}
      />
    </RadioGroup>
  );
}

export default TripTypeRadioGroup;
