import { createContext, useContext } from "react";
import type {
  CreateTravelerBodyInputDto,
  UpdateTravelerBodyInputDto,
} from "@/app/lib/types/codegen";
import { Traveller } from "../../types/types";

const defaultContextValue: {
  travellers: Traveller[];
  getSelectedTravellers: Traveller[];
  selectedTravellers: string[];
  addTraveller: (traveller: CreateTravelerBodyInputDto) => Promise<void>;
  editTraveller: (
    travellerId: string,
    traveller: UpdateTravelerBodyInputDto,
  ) => Promise<void>;
  removeTraveller: (id: string) => void;
  toggleTravellerSelection: (id: string) => void;
  handleLeadTraveller: (traveller: Traveller) => void;
  leadTraveller: Traveller | null;
  isReady: boolean;
  selectedTravellersByAge: {
    adults: number;
    children: number;
    infants: number;
  };
} = {
  travellers: [],
  selectedTravellers: [],
  getSelectedTravellers: [],
  addTraveller: Promise.resolve,
  editTraveller: Promise.resolve,
  removeTraveller: () => {},
  toggleTravellerSelection: () => {},
  handleLeadTraveller: () => {},
  leadTraveller: null,
  isReady: false,
  selectedTravellersByAge: {
    adults: 0,
    children: 0,
    infants: 0,
  },
};

export const TravellersContext =
  createContext<typeof defaultContextValue>(defaultContextValue);

export const useTravellersContext = () => useContext(TravellersContext);
