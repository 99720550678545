import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { HotelOverview } from "@/app/ui/hotel/HotelOverview";
import { PersistentDrawer } from "@/app/ui/PersistentDrawer";
import { Button } from "@mui/material";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/browser";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";

export const Route = createFileRoute(
  "/_authenticated/_hotels/_hotel-offer/hotels/$id/details",
)({
  component: RouteComponent,
  staticData: {
    hotelStep: { stepNumber: 0 },
    drawer: true,
  },
});

/**
 * First step of the hotel booking process.
 */
function RouteComponent() {
  const t = useTranslations();
  const navigate = Route.useNavigate();

  const { searchData } = useHotelSearchDataContext();
  const { data: hotel } = useOptionalHotelDataContext();

  if (!searchData || !hotel) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access hotel details page directly without going through the hotel offer page",
      "fatal",
    );
    return <Navigate to="/hotels/list" />;
  }

  return (
    <>
      <HotelOverview searchData={searchData} hotel={hotel} />
      <PersistentDrawer>
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            navigate({ to: "/hotels/$id/rooms" });
          }}
        >
          {t("hotels.chooseRoom")}
        </Button>
      </PersistentDrawer>
    </>
  );
}
