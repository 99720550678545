import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import { useHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { formatCurrency } from "@/app/lib/utils/currency";

export function HotelOfferTotalPrice() {
  const { data: hotelBooking } = useHotelBookingContext();
  const { data: hotelData } = useHotelDataContext();
  const totalPrice =
    hotelBooking?.rooms.reduce(
      (acc, room) => acc + room.price?.value || 0,
      0,
    ) ||
    hotelBooking?.price?.value ||
    0;

  return (
    <span dir="ltr">
      {formatCurrency(totalPrice, hotelData.minPrice?.currency)}
    </span>
  );
}
