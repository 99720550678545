import { ReactNode } from "react";
import { ToggleButton } from "@mui/material";
import { Check } from "@phosphor-icons/react";

import { SortValue } from "@/app/lib/types/types";

type Props<T> = {
  value: SortValue<T>;
  onChange: (value: SortValue<T>) => void;
  children: ReactNode;
  isSelected: boolean;
};

export function SortButton<T>({
  value,
  onChange,
  isSelected,
  children,
}: Props<T>) {
  return (
    <ToggleButton
      onChange={() => onChange(value)}
      sx={{
        border: "none",
        borderBottom: "1px solid var(--color-stroke-neutral-soft)",
        borderBottomLeftRadius: "0",
        borderBottomRightRadius: "0",
        justifyContent: "flex-end",
        textTransform: "unset",
        padding: "16px 8px",
      }}
      value={value}
    >
      <div className="flex w-full">
        <span className="text-primary inline-flex grow items-center gap-4 text-base font-medium">
          {children}
        </span>

        {isSelected ? (
          <Check size={20} className="fill-interactive" weight="bold" />
        ) : null}
      </div>
    </ToggleButton>
  );
}
