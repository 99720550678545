import { useState } from "react";
import clsx from "clsx";
import { useLocaleInfo } from "../lib/hooks/useLocaleInfo";

function SingleDateCard({
  date,
  active = false,
  onChange = () => {},
  disabled,
}: {
  date: Date;
  active?: boolean;
  onChange?: () => void;
  disabled?: boolean;
}) {
  const [animate, setAnimate] = useState(false);
  const { locale } = useLocaleInfo();
  const onClick = () => {
    setAnimate(true);
    onChange();
  };

  return (
    <button
      id={`${date.getDate()}-${date.getMonth()}`}
      onClick={onClick}
      disabled={disabled}
      onAnimationEnd={() => setAnimate(false)}
      className={clsx(
        "disabled:text-disabled flex max-h-14.5 w-fit min-w-16 flex-col items-center rounded-2xl px-4 py-2 disabled:bg-gray-200",
        (active && "pointer-events-none bg-[#295942] text-white") ||
          "text-primary bg-white",
        animate && "click-animate",
      )}
    >
      <span className="text-center text-base font-semibold">
        {date.getDate()}
      </span>
      <span className="text-center text-sm">
        {date.toLocaleString(locale, { month: "short" })}
      </span>
    </button>
  );
}

export default SingleDateCard;
