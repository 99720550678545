import { Button, CircularProgress, Divider } from "@mui/material";
import type {
  HotelBookingRoomOption,
  THotelSearchResult,
} from "@/app/lib/types/types";
import { useTranslations } from "use-intl";
import { HotelCard } from "../hotels-list/HotelCard";
import { ReservationNextSteps } from "../my-trips/ReservationNextSteps";
import { ReservationDocumentList } from "../ReservationDocumentList";
import { HotelRoom } from "./HotelRoom";
import { hotelReservationQueryOptions } from "@/app/lib/hooks/useReservation";
import { useNavigate } from "@tanstack/react-router";
import { useQuery } from "@tanstack/react-query";
import { Fragment } from "react";
import { useOrder } from "@/app/lib/hooks/useOrder";

export const HotelBookingSuccess = ({
  hotel,
  orderId,
  rooms,
  visitors,
}: {
  hotel: THotelSearchResult;
  orderId: string;
  rooms: HotelBookingRoomOption[];
  visitors: number;
}) => {
  const navigate = useNavigate();
  const t = useTranslations();
  const { data: orderData, isLoading: isOrderLoading } = useOrder(orderId);
  const { data: reservationData, isLoading: isReservationLoading } = useQuery(
    hotelReservationQueryOptions(orderData?.bookingId),
  );

  const isLoading =
    isOrderLoading || !orderData?.bookingId || isReservationLoading;

  return (
    <div className="px-4">
      <div className="text-primary mb-2 p-2 text-center text-xl font-semibold">
        {t("hotels.overview.booked")}
      </div>

      <div className="section-title">{t("hotels.overview.hotelDetails")}</div>
      <HotelCard hotel={hotel} isSuccessScreen />

      <div className="text-primary section-card mt-2 mb-4 flex flex-col gap-4">
        {rooms.map((room, i) => (
          <Fragment key={room.roomToken}>
            {i !== 0 ? <Divider /> : null}

            <HotelRoom room={room} hotel={hotel} visitorsCount={visitors} />
          </Fragment>
        ))}
      </div>

      <section className="section-card mb-4 flex w-full items-center justify-between gap-4">
        <span className="section-title mb-0">
          {t("reservations.bookingNumber")}
        </span>
        <span className="text-title text-base font-semibold">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            reservationData?.reference?.external
          )}
        </span>
      </section>

      <ReservationDocumentList bookingId={orderData?.bookingId} />

      <ReservationNextSteps type="hotel" />

      <div className="mt-4 flex flex-col items-center justify-center gap-2">
        <Button
          sx={{
            width: "240px",
            padding: "8px",
          }}
          variant="contained"
          size="small"
          onClick={() => {
            navigate({ to: "/", search: { category: "hotels" } });
          }}
        >
          <span>{t("backToHome")}</span>
        </Button>
        <Button
          variant="text"
          size="small"
          sx={{
            width: "240px",
          }}
          onClick={() => {
            navigate({ to: "/trips" });
          }}
        >
          <span>{t("openMyTrips")}</span>
        </Button>
      </div>
    </div>
  );
};
