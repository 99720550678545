import { TextField } from "../StyledTz";
import { Button, IconButton } from "@mui/material";
import { useTranslations } from "use-intl";
import { FormikProps } from "formik";
import { THotelFormValues } from "@/app/lib/types/types";
import { useState } from "react";
import { useHotelsCitiesSearch } from "@/app/lib/hooks/useHotelsCitiesSearch";
import { initialHotelsValues } from "@/app/lib/initialHotelsValues";
import { X } from "@phosphor-icons/react";
import { FindCitiesCity } from "@/app/lib/types/codegen";

function DestinationSearch({
  onClose,
  formProps,
}: {
  onClose: () => void;
  formProps: FormikProps<THotelFormValues>;
}) {
  const [input, setInput] = useState(formProps.values.destination.name);
  const t = useTranslations();
  const { data } = useHotelsCitiesSearch(input);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.currentTarget.value.slice(0, 30);
    setInput(input);
  };

  const onClick = (value: FindCitiesCity) => {
    formProps.setFieldValue("destination", value);
    onClose();
  };

  const resetDestination = () => {
    formProps.setFieldValue("destination", initialHotelsValues.destination);
    setInput("");
  };

  return (
    <div className="w-full">
      <div className="mt-1 mb-3 flex w-full items-center justify-center text-center">
        <div className="absolute end-4">
          <IconButton onClick={onClose}>
            <X size={20} className="fill-interactive" />
          </IconButton>
        </div>

        <h2 className="text-title text-base font-medium">
          {t("hotels.search.destinationSelect")}
        </h2>
      </div>

      <div className="relative mt-4 flex w-full flex-col gap-2">
        <TextField
          name="destination"
          fullWidth
          value={input}
          onChange={onChange}
          InputProps={{
            endAdornment: formProps.values.destination.name && (
              <button onClick={resetDestination} className="absolute end-3">
                <X size={20} color="#6A778A" />
              </button>
            ),
          }}
          onBlur={formProps.handleBlur}
          label={t("hotels.search.destination")}
        />
      </div>
      {data?.cities && data?.cities?.length > 0 ? (
        <>
          <div className="w-full py-3">
            <span className="text-xs font-medium" style={{ color: "#5E6B7D" }}>
              {t("hotels.search.popular")}
            </span>
          </div>
          <ul
            style={{
              width: "calc(100% + 10px)",
            }}
            className="ltr:-translate-x-[5px] rtl:translate-x-[5px]"
          >
            {data.cities.map((city) => (
              <li key={city.id}>
                <Button
                  fullWidth
                  onClick={() => {
                    onClick(city);
                  }}
                  variant="text"
                >
                  <div className="flex w-full items-baseline gap-1">
                    <span className="text-label text-base font-medium capitalize">
                      {city.name.toLowerCase()}
                    </span>
                    <span className="text-sm leading-6 font-normal text-slate-500">
                      {city.countryName}
                    </span>
                  </div>
                </Button>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="text-placeholder mt-6 flex justify-center text-sm">
          {t("hotels.search.enterName")}
        </div>
      )}
    </div>
  );
}

export default DestinationSearch;
