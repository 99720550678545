"use client";

import { useState } from "react";
import { useTranslations } from "use-intl";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { X } from "@phosphor-icons/react";
import { IconButton, LinearProgress, SwipeableDrawer } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

import PageLoader from "@/app/ui/PageLoader";
import { THotelSearchResult } from "@/app/lib/types/types";
import HotelListHeader from "./HotelListHeader";
import HotelsListSort from "./HotelsListSort";
import { DrawerComponent } from "@/app/lib/types/enums";
import { useHotelSearchProgress } from "@/app/lib/hooks/useHotelSearchProgress";
import { HotelCard } from "./HotelCard";
import HotelsFilters from "./HotelsFilters";
import AnimatedLoader from "../AnimatedLoader";
import { useHotelSearchResults } from "@/app/lib/hooks/useHotelSearchResults";
import NoResultsError from "../NoResultsError";
import { useHotelsTokensContext } from "@/app/lib/context/HotelsTokensContext";
import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { WhatsAppButton } from "../WhatsAppButton";
import { useHotelFiltersContext } from "@/app/lib/context/HotelFiltersContext";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import { getNumberOfBookingNights } from "@/app/lib/utils/getNumberOfBookingNights";
import { useHotelSortContext } from "@/app/lib/context/HotelSortContext";
import { Puller } from "../StyledTz";
import { getSetFilterCount } from "@/app/lib/utils/filters";

function HotelsList() {
  const { data: tokens, isLoading: isLoadingTokens } = useHotelsTokensContext();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const [drawerComponent, setDrawerComponent] = useState("");
  const { setData: setHotelData } = useOptionalHotelDataContext();
  const t = useTranslations();

  const handleOverviewOpen = (hotel: THotelSearchResult) => {
    setHotelData(hotel);
    navigate({
      to: "/hotels/$id/details",
      params: { id: `${hotel.id}` },
    });
  };

  const handleDrawerOpen = (component: DrawerComponent) => {
    setDrawerComponent(component);
    setDrawerOpen(true);
  };

  const { data: progressData, isLoading: isLoadingProgressData } =
    useHotelSearchProgress(tokens);

  const { sortValue, updateSortValue } = useHotelSortContext();
  const { defaultFilters, filters, updateFilters } = useHotelFiltersContext();
  const { searchData } = useHotelSearchDataContext();

  const shouldFetch = progressData?.data?.progress === 100;

  const {
    data: searchResultData,
    isLoading: isLoadingData,
    isFetching: isFetchingData,
    isError,
    hasReachedEnd,
    isEmpty,
    fetchNextPage,
  } = useHotelSearchResults({
    searchResultKey: tokens?.searchResultKey,
    searchResultToken: tokens?.searchResultToken,
    filters,
    bookingNights: getNumberOfBookingNights({
      checkIn: searchData?.dates.checkIn,
      checkOut: searchData?.dates.checkOut,
    }),
    shouldFetch,
    sortValue,
  });

  const [sentryRef] = useInfiniteScroll({
    loading: isFetchingData,
    hasNextPage: !hasReachedEnd,
    onLoadMore: () => {
      fetchNextPage();
    },
    disabled: isError,
    rootMargin: "0px 0px 40px 0px",
  });

  const progress = Number(progressData?.data?.progress || 0);
  const isLoading =
    isLoadingTokens || isLoadingData || isLoadingProgressData || progress < 100;

  if (isLoading) {
    return (
      <>
        {progress < 100 ? (
          <LinearProgress variant="determinate" value={progress} />
        ) : null}

        <PageLoader>
          <AnimatedLoader icon="buildings" />
          <h3 className="text-primary mt-3 font-normal">
            {t("hotels.preparingBestOffers")}
          </h3>
        </PageLoader>
      </>
    );
  }

  return (
    <div>
      <HotelListHeader
        onSortButtonClick={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_SORT);
        }}
        onFiltersButtonClick={() => {
          handleDrawerOpen(DrawerComponent.HOTELS_FILTERS);
        }}
        selectedSortValue={sortValue}
        hotelCount={progressData?.data?.countHotels}
        filtersSet={getSetFilterCount(filters, defaultFilters)}
      />

      <WhatsAppButton />

      {isEmpty ? (
        <NoResultsError tab="hotels" />
      ) : (
        <div className="text-primary w-full px-4 text-lg font-normal">
          {searchResultData?.pages?.flatMap((hotels) =>
            hotels.data.map((hotel) => (
              <HotelCard
                onClick={() => handleOverviewOpen(hotel)}
                key={hotel.id}
                hotel={hotel}
              />
            )),
          )}

          {isFetchingData || !hasReachedEnd ? (
            <div
              className="flex items-center justify-center py-4"
              ref={sentryRef}
              aria-hidden={!isFetchingData}
            >
              <AnimatedLoader icon="buildings" />
            </div>
          ) : (
            <div className="mb-9 flex items-center justify-center p-8">
              {t("hotels.noMoreResults")}
            </div>
          )}
        </div>
      )}

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            minHeight:
              drawerComponent == DrawerComponent.HOTELS_SORT ? "auto" : "90vh",
          },
        }}
      >
        {DrawerComponent.HOTELS_SORT == drawerComponent ? (
          <>
            <div className="mb-2 flex items-center">
              <IconButton
                onClick={() => setDrawerOpen(false)}
                aria-label="close drawer"
              >
                <X size={20} className="fill-interactive" width="bold" />
              </IconButton>

              <h2 className="grow text-center font-medium">
                {t("hotels.filters.sort")}
              </h2>
              <div className="size-9 shrink-0" />
            </div>

            <HotelsListSort
              sortValue={sortValue}
              onSortValueChange={(newSortValue) => {
                updateSortValue(newSortValue);
                setDrawerOpen(false);
              }}
            />
          </>
        ) : defaultFilters && filters ? (
          <div>
            <Puller />
            <div className="flex w-full justify-center pt-5.5 pb-3">
              <h2 className="text-title font-base font-medium">
                {t("hotels.filters.filters")}
              </h2>
            </div>
            <HotelsFilters
              defaultFilters={defaultFilters}
              filters={filters}
              onFiltersChange={(newFilters) => {
                updateFilters((prevFilters) =>
                  prevFilters
                    ? {
                        ...prevFilters,
                        ...newFilters,
                      }
                    : null,
                );
                setDrawerOpen(false);
              }}
            />
          </div>
        ) : null}
      </SwipeableDrawer>
    </div>
  );
}

export default HotelsList;
