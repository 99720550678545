import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { IFlightFilters } from "../../types/interfaces";

export interface FlightFiltersContextValue {
  filters: IFlightFilters | null;
  defaultFilters: IFlightFilters | null;
  updateFilters: Dispatch<SetStateAction<IFlightFilters | null>>;
  resetFilters: () => void;
}

export const FlightFiltersContext =
  createContext<FlightFiltersContextValue | null>(null);

export const useFlightFiltersContext = () => {
  const context = useContext(FlightFiltersContext);
  if (!context) {
    throw new Error(
      "useFlightFiltersContext must be used within a FlightFiltersProvider",
    );
  }
  return context;
};
