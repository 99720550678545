import { RadioGroup, FormControlLabel, Radio, Button } from "@mui/material";
import { CabinClass } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import type { FormikProps } from "formik";
import type { TFlightFormValues } from "@/app/lib/types/types";
import PlusMinusButton from "../CounterButton";

function PassengersSelection({
  onDrawerClose,
  formProps,
}: {
  onDrawerClose: () => void;
  formProps: FormikProps<TFlightFormValues>;
}) {
  const t = useTranslations();
  const maximumChild = 9 - formProps.values.adult;
  const maximumAdult = 9 - formProps.values.child;

  return (
    <div className="flex h-full w-full flex-col px-2 pb-4">
      <h2 className="mb-7 text-center font-medium">
        {t("flights.search.travellersCabinClass")}
      </h2>
      <span className="text-tertiary mb-4 inline-block text-xs">
        {t("flights.search.travellers")}
      </span>
      <div className="flex justify-between py-2">
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.adult")}
          </h4>
          <span className="text-tertiary inline-block text-xs font-normal">
            {t("flights.search.twelveYearOldPlus")}
          </span>
        </div>
        <PlusMinusButton
          minValue={1}
          maxValue={maximumAdult}
          value={formProps.values.adult}
          onChange={(value) => formProps.setFieldValue("adult", value)}
        />
      </div>
      <div className="flex justify-between py-2">
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.child")}
          </h4>
          <span className="text-tertiary inline-block text-xs font-normal">
            {t("flights.search.twoToTwelveYearOld")}
          </span>
        </div>
        <PlusMinusButton
          maxValue={maximumChild}
          value={formProps.values.child}
          onChange={(value) => formProps.setFieldValue("child", value)}
        />
      </div>
      <div className="flex justify-between py-2">
        <div className="flex flex-col">
          <h4 className="text-primary font-medium">
            {t("flights.search.infant")}
          </h4>
          <span className="text-tertiary inline-block max-w-[200px] text-xs font-normal text-wrap">
            {t("flights.search.withoutSeat")}
          </span>
        </div>
        <PlusMinusButton
          maxValue={formProps.values.adult}
          value={formProps.values.infantWithoutSeat}
          onChange={(value) =>
            formProps.setFieldValue("infantWithoutSeat", value)
          }
        />
      </div>
      <span className="text-tertiary mt-4 mb-2 inline-block text-xs">
        {t("flights.cabinClass.cabinClass")}
      </span>
      <div>
        <RadioGroup
          aria-labelledby="cabin-class-radio-group"
          defaultValue="economy"
          name="cabinClass"
          value={formProps.values.cabinClass}
          onChange={formProps.handleChange}
        >
          <FormControlLabel
            className="flex justify-between py-2"
            value={CabinClass.ECONOMY}
            control={<Radio />}
            label={t("flights.cabinClass.economy")}
            sx={{ m: 0 }}
          />
          <FormControlLabel
            className="m-0 flex justify-between py-2"
            value={CabinClass.PREMIUM_ECONOMY}
            control={<Radio />}
            label={t("flights.cabinClass.premium")}
            sx={{ m: 0 }}
          />
          <FormControlLabel
            className="m-0 flex justify-between py-2"
            value={CabinClass.FIRST_CLASS}
            control={<Radio />}
            label={t("flights.cabinClass.firstClass")}
            sx={{ m: 0 }}
          />
          <FormControlLabel
            className="m-0 flex justify-between py-2"
            value={CabinClass.BUSINESS}
            control={<Radio />}
            label={t("flights.cabinClass.business")}
            sx={{ m: 0 }}
          />
        </RadioGroup>
      </div>
      <Button
        onClick={onDrawerClose}
        variant="contained"
        size="medium"
        fullWidth
        sx={{
          marginTop: "auto",
        }}
      >
        {t("flights.confirm")}
      </Button>
    </div>
  );
}

export default PassengersSelection;
