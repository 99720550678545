import { useInfiniteQuery } from "@tanstack/react-query";

import { BASE_API_URL } from "../routes";
import { FindOrdersReservationsForHotelsOutputDto } from "../types/codegen";
import api from "../api/api";
import { OrderType } from "../types/enums";

const PAGE_SIZE = 10;

export const useHotelsReservations = ({
  orderType,
}: {
  orderType: OrderType;
}) => {
  const params = { perPage: PAGE_SIZE };

  const { data, fetchNextPage, hasNextPage, isLoading, isFetching, isError } =
    useInfiniteQuery({
      queryKey: ["hotels", "reservations", orderType] as const,
      queryFn: ({ pageParam, signal }) =>
        api
          .get<FindOrdersReservationsForHotelsOutputDto>(
            `${BASE_API_URL}/orders/reservations/hotel`,
            {
              params: {
                ...params,
                searchFilter: orderType,
                page: pageParam,
              },
              signal,
            },
          )
          .then((res) => res.data),
      initialPageParam: 1,
      getNextPageParam: (lastPage, pages) =>
        lastPage.data.hasMore ? pages.length + 1 : undefined,
    });

  const isEmpty = data?.pages.flatMap((page) => page.data).length === 0;
  const hasReachedEnd = !hasNextPage;

  return {
    data,
    isLoading,
    isFetching,
    isError,
    isEmpty,
    hasReachedEnd,
    fetchNextPage,
  };
};
