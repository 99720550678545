"use client";

import { useState } from "react";
import { X } from "@phosphor-icons/react";
import { IconButton, SwipeableDrawer } from "@mui/material";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Puller } from "../StyledTz";
import { useTranslations } from "use-intl";
import { useFlightsSearch } from "@/app/lib/hooks/useFlightsSearch";
import Header from "@/app/ui/flight-list/Header";
import AnimatedLoader from "@/app/ui/AnimatedLoader";
import PageLoader from "@/app/ui/PageLoader";
import SingleAvailableFlight from "@/app/ui/SingleAvailableFlight";
import FlightsFilters from "./FlightsFilters";
import FlightsSort from "@/app/ui/flight-list/FlightsSort";
import NoResultsError from "../NoResultsError";
import { useFlightSelectionPayloadContext } from "@/app/lib/context/FlightSelectionPayloadContext";
import { WhatsAppButton } from "../WhatsAppButton";
import { useFlightSortContext } from "@/app/lib/context/FlightSortContext";
import { useFlightFiltersContext } from "@/app/lib/context/FlightFiltersContext";
import { getSetFilterCount } from "@/app/lib/utils/filters";

enum DrawerComponent {
  FLIGHTS_FILTERS = "FLIGHTS_FILTERS",
  FLIGHTS_SORT = "FLIGHTS_SORT",
}

function FlightList() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { sortValue, updateSortValue } = useFlightSortContext();
  const { defaultFilters, filters, updateFilters } = useFlightFiltersContext();

  const [drawerComponent, setDrawerComponent] = useState("");

  const t = useTranslations();
  const {
    offers,
    isError,
    hasReachedEnd,
    searchResultKey,
    searchResultToken,
    isEmpty,
    fetchNextPage,
    isFetching,
    isLoading,
  } = useFlightsSearch();

  const { handleFlightSelection } = useFlightSelectionPayloadContext();

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: !hasReachedEnd,
    onLoadMore: () => {
      fetchNextPage();
    },
    disabled: isError,
    rootMargin: "0px 0px 40px 0px",
  });

  if (isLoading) {
    return (
      <PageLoader>
        <AnimatedLoader icon="airplane" />
        <h3 className="text-primary mt-3 font-normal">
          {t("flights.preparingOffers")}
        </h3>
      </PageLoader>
    );
  }

  return (
    <div>
      <Header
        onFiltersButtonClick={() => {
          setDrawerOpen(true);
          setDrawerComponent(DrawerComponent.FLIGHTS_FILTERS);
        }}
        onSortButtonClick={() => {
          setDrawerOpen(true);
          setDrawerComponent(DrawerComponent.FLIGHTS_SORT);
        }}
        filtersSet={getSetFilterCount(filters, defaultFilters)}
        selectedSortValue={sortValue}
      />

      <WhatsAppButton />

      {isEmpty ? (
        <NoResultsError tab="flights" />
      ) : (
        <div className="w-full px-4">
          <h4 className="text-title mt-4 mb-2 font-medium">
            {t("flights.availableFlights")}
          </h4>

          {offers?.map((itinerary) => (
            <SingleAvailableFlight
              key={itinerary.index + itinerary.itineraryIndex}
              itinerary={itinerary}
              onClick={() => {
                handleFlightSelection(
                  {
                    offerId: itinerary.index as unknown as string,
                    itineraryIndex: itinerary.itineraryIndex,
                    searchResultKey,
                    searchResultToken,
                  },
                  itinerary,
                );
              }}
            />
          ))}

          {isFetching || !hasReachedEnd ? (
            <div
              className="flex items-center justify-center py-4"
              ref={sentryRef}
              aria-hidden={!isFetching}
            >
              <AnimatedLoader icon="airplane" />
            </div>
          ) : (
            <div className="mb-9 flex items-center justify-center p-8">
              {t("flights.noResults")}
            </div>
          )}
        </div>
      )}

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        disableSwipeToOpen={false}
        disableBackdropTransition={true}
        sx={{
          "& .MuiDrawer-paper": {
            display: "flex",
            flexDirection: "column",
            touchAction: "pan-y",
            paddingBottom: "env(safe-area-inset-bottom)",
            height:
              drawerComponent === DrawerComponent.FLIGHTS_SORT
                ? "auto"
                : "90vh",
          },
        }}
      >
        {DrawerComponent.FLIGHTS_SORT == drawerComponent ? (
          <>
            <div className="mb-2 flex items-center">
              <IconButton
                onClick={() => setDrawerOpen(false)}
                aria-label="close drawer"
              >
                <X size={20} className="fill-interactive" width="bold" />
              </IconButton>

              <h2 className="grow text-center font-medium">
                {t("flights.sort")}
              </h2>

              <div className="size-9 shrink-0" />
            </div>

            <FlightsSort
              sortValue={sortValue}
              onSortValueChange={(newSortValue) => {
                updateSortValue(newSortValue);
                setDrawerOpen(false);
              }}
            />
          </>
        ) : filters && defaultFilters ? (
          <div className="px-1">
            <Puller />
            <div className="flex w-full justify-center pt-5.5 pb-3">
              <h2 className="text-title font-base font-medium">
                {t("flights.filters")}
              </h2>
            </div>
            <FlightsFilters
              defaultFilters={defaultFilters}
              filters={filters}
              onFiltersChange={(newFilters) => {
                updateFilters((prevFilters) =>
                  prevFilters
                    ? {
                        ...prevFilters,
                        ...newFilters,
                      }
                    : null,
                );
                setDrawerOpen(false);
              }}
            />
          </div>
        ) : null}
      </SwipeableDrawer>
    </div>
  );
}

export default FlightList;
