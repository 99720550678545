import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { FlightsSortValue } from "../../types/types";

export interface FlightSortContextValue {
  sortValue: FlightsSortValue;
  updateSortValue: Dispatch<SetStateAction<FlightsSortValue>>;
  resetSortValue: () => void;
}

export const FlightSortContext = createContext<FlightSortContextValue | null>(
  null,
);

export const useFlightSortContext = () => {
  const context = useContext(FlightSortContext);
  if (!context) {
    throw new Error(
      "useFlightSortContext must be used within a FlightSortProvider",
    );
  }
  return context;
};
