import { PropsWithChildren } from "react";
import {
  HotelOffersContext,
  HotelOffersContextValue,
} from "./HotelOffersContext";

type Props = PropsWithChildren<HotelOffersContextValue>;

export const HotelOffersContextProvider = ({ children, data }: Props) => {
  return (
    <HotelOffersContext.Provider value={{ data }}>
      {children}
    </HotelOffersContext.Provider>
  );
};
