import {
  FindOrdersReservationsForFlightReservation,
  FindOrdersReservationsForHotelReservation,
} from "@/app/lib/types/codegen";
import { formatCurrency } from "@/app/lib/utils/currency";
import { useTranslations } from "use-intl";

export const ReservationPaymentDetails = ({
  reservation,
}: {
  reservation:
    | FindOrdersReservationsForHotelReservation
    | FindOrdersReservationsForFlightReservation;
}) => {
  const t = useTranslations();

  return (
    <section>
      <h3 className="text-tertiary mt-4 mb-2 text-xs font-medium">
        {t("reservations.paymentDetails")}
      </h3>
      <ul className="w-full items-center justify-between gap-4 space-y-2 rounded-3xl bg-white p-4 text-sm">
        {reservation.reference.confirmation ? (
          <li className="flex w-full items-center justify-between">
            <span className="text-title">{t("reservations.orderNumber")}</span>
            <span className="text-title font-semibold">
              {reservation.reference.confirmation}
            </span>
          </li>
        ) : null}
        <li className="flex w-full items-center justify-between">
          <span className="text-title">{t("reservations.totalPrice")}</span>
          <span className="text-title font-semibold" dir="ltr">
            {formatCurrency(reservation.totalPrice, reservation.currency)}
          </span>
        </li>
      </ul>
    </section>
  );
};
