import { createContext, useContext, Dispatch, SetStateAction } from "react";

export type PurchaseState = {
  wasPurchaseDone: boolean;
};

export type PurchaseStateContextValue = {
  purchaseState: PurchaseState;
  updatePurchaseState: Dispatch<SetStateAction<PurchaseState>>;
};

export const PurchaseStateContext =
  createContext<PurchaseStateContextValue | null>(null);

export const usePurchaseStateContext = () => {
  const context = useContext(PurchaseStateContext);
  if (!context) {
    throw new Error(
      "usePurchaseStateContext must be used within a PurchaseStateContextProvider",
    );
  }
  return context;
};
