import { useState, PropsWithChildren } from "react";
import {
  HotelBookingContext,
  HotelBookingContextValue,
} from "./HotelBookingContext";

export const HotelBookingContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [data, setData] = useState<HotelBookingContextValue["data"]>(null);
  const [availabilityToken, setAvailabilityToken] = useState<string | null>(
    null,
  );

  return (
    <HotelBookingContext.Provider
      value={{ data, setData, availabilityToken, setAvailabilityToken }}
    >
      {children}
    </HotelBookingContext.Provider>
  );
};
