import { Ghost } from "@phosphor-icons/react";
import { Button } from "@mui/material";
import { WHATSAPP_URL } from "@/app/lib/routes";
import { useTranslations } from "use-intl";
import { type NavigateOptions, useNavigate } from "@tanstack/react-router";

type TabProps = {
  tab: "flights" | "hotels" | "rooms";
};

const tabs = [
  {
    name: "flights",
    path: { to: "/", search: { category: "flights" } },
    text: "flights",
  },
  {
    name: "hotels",
    path: { to: "/", search: { category: "hotels" } },
    text: "hotels",
  },
  {
    name: "rooms",
    path: { to: "/", search: { category: "hotels" } },
    text: "available rooms",
  },
] satisfies { name: TabProps["tab"]; path: NavigateOptions; text: string }[];

function NoResultsError({ tab }: TabProps) {
  const t = useTranslations();
  const navigate = useNavigate();

  return (
    <div className="flex h-full flex-col items-center pb-10">
      <div className="mt-[30%] flex flex-col items-center gap-4">
        <Ghost size={60} color="#0A22474D" />
        <span className="text-primary text-center text-sm">
          {t(`${tab}.emptyState`)}
        </span>
        <Button
          variant="outlined"
          onClick={() =>
            navigate(
              tabs.find((t) => t.name === tab)?.path || {
                to: "/",
                search: { category: "flights" },
              },
            )
          }
        >
          {t("backToSearch")}
        </Button>
      </div>
      <Button
        variant="text"
        onClick={() => window.open(WHATSAPP_URL, "_blank")}
        sx={{
          marginTop: "auto",
        }}
      >
        {t("contactWhatsAppSupport")}
      </Button>
    </div>
  );
}

export default NoResultsError;
