import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

import { Fragment } from "react";
import { useTranslations } from "use-intl";

import type {
  HotelBookingRoomOption,
  HotelOffer,
  THotelSearchResult,
} from "@/app/lib/types/types";

import { HotelRoomTitle } from "./HotelRoomTitle";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import { formatCurrency } from "@/app/lib/utils/currency";

interface HotelRoomSelectionProps {
  hotel: THotelSearchResult;
  offers: HotelOffer[];
  onRoomGalleryClick: (room: HotelBookingRoomOption) => void;
}

export const HotelRoomSelection = ({
  hotel,
  offers,
  onRoomGalleryClick,
}: HotelRoomSelectionProps) => {
  const { setData: setHotelBooking, data: hotelBooking } =
    useHotelBookingContext();

  const t = useTranslations();

  return (
    <div className="flex w-full flex-col px-4">
      {offers.map((offer, offerIndex) => (
        <Fragment key={offerIndex}>
          <div className="section-title">
            {t("hotels.search.option")} #{offerIndex + 1}
          </div>
          {offer.rooms.map((room, index) => (
            <div
              className="section-card mb-2 flex flex-col justify-between gap-2"
              key={index}
            >
              <div className="section-title m-0">
                {t("hotels.search.room")} {index + 1} (
                {t("hotels.search.selectOne")})
              </div>
              <FormControl>
                <RadioGroup
                  onChange={(e) => {
                    const isCurrentOffer =
                      offer.offerId === hotelBooking?.offerId &&
                      offer.packageToken === hotelBooking?.packageToken;
                    const rooms = isCurrentOffer ? hotelBooking.rooms : [];
                    const target = JSON.parse(e.target.value);
                    rooms.splice(isCurrentOffer ? index : 0, 1, target);
                    setHotelBooking({
                      packageToken: offer.packageToken,
                      rooms,
                      offerId: offer.offerId,
                      price: target.price,
                    });
                  }}
                >
                  {room.options.map((option) => (
                    <div
                      className="mb-2 rounded-2xl bg-[#F3F4F7] p-3 ps-1"
                      key={option.roomToken}
                    >
                      <div className="flex flex-row-reverse justify-between gap-4">
                        <button
                          type="button"
                          className="relative h-[48px] min-h-[48px] w-[60px] shrink-0 overflow-clip rounded-lg"
                          onClick={() => {
                            onRoomGalleryClick(option);
                          }}
                        >
                          {hotel.mainImageUrl && (
                            <img
                              src={hotel.mainImageUrl}
                              alt={hotel.name}
                              width={60}
                              height={48}
                              style={{
                                minHeight: "48px",
                                minWidth: "60px",
                                borderRadius: "8px",
                                zIndex: 10,
                              }}
                            />
                          )}
                          <div className="absolute bottom-0 left-0 z-1 bg-black ps-1.5 pe-1">
                            <div className="flex items-center justify-center gap-0.5">
                              <div className="text-xs text-white">
                                {hotel.imagesUrls.length}+
                              </div>
                              <img
                                src={"/image.svg"}
                                width={9}
                                height={9}
                                alt="image"
                              />
                            </div>
                          </div>
                        </button>
                        <FormControlLabel
                          key={option.roomToken}
                          name={option.roomToken}
                          value={JSON.stringify({
                            ...option,
                            price: option.price || offer.price,
                          })}
                          control={<Radio />}
                          disabled={
                            offer.offerId !== hotelBooking?.offerId && index > 0
                          }
                          checked={
                            option.roomToken ===
                            hotelBooking?.rooms[index]?.roomToken
                          }
                          label={
                            <div className="font-medium">
                              <HotelRoomTitle
                                name={option.name}
                                board={option.board}
                                occupancy={room.occupancy}
                              />
                              {option?.price && (
                                <div className="text-primary mt-1 font-bold">
                                  <span dir="ltr">
                                    {formatCurrency(
                                      option.price.value,
                                      option.price.currency,
                                    )}
                                  </span>
                                </div>
                              )}
                              {!option?.price && offer.price && (
                                <div className="text-primary mt-1 font-bold">
                                  <span dir="ltr">
                                    {formatCurrency(
                                      offer.price.value,
                                      offer.price.currency,
                                    )}
                                  </span>
                                </div>
                              )}
                            </div>
                          }
                          sx={{
                            m: 0,
                            "& .MuiTypography-root": {
                              fontSize: 14,
                              color: "#111317",
                            },
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          ))}
        </Fragment>
      ))}
    </div>
  );
};
