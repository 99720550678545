import { useState, PropsWithChildren } from "react";
import { HotelDataContext, HotelDataContextValue } from "./HotelDataContext";

export const HotelDataProvider = ({ children }: PropsWithChildren) => {
  const [data, setData] = useState<HotelDataContextValue["data"]>(null);

  return (
    <HotelDataContext.Provider value={{ data, setData }}>
      {children}
    </HotelDataContext.Provider>
  );
};
