import { Divider } from "@mui/material";
import { useTranslations } from "use-intl";
import type { GetFlightAvailabilityTokenOptionalService } from "@/app/lib/types/codegen";
import { baggageIcons } from "@/app/lib/utils/baggageIcons";
import { Traveller } from "@/app/lib/types/types";

function groupByPassengerIndex(
  data: GetFlightAvailabilityTokenOptionalService[],
) {
  const grouped: Record<string, GetFlightAvailabilityTokenOptionalService[]> =
    {};

  for (const key in data) {
    const item = data[key];
    const passengerIndex = item.passengerIndex;

    if (!grouped[passengerIndex]) {
      grouped[passengerIndex] = [];
    }

    grouped[passengerIndex].push(item);
  }

  return grouped;
}

function TravellersListSummary({
  selectedTravellers,
  baggage,
}: {
  selectedTravellers: Traveller[];
  baggage: GetFlightAvailabilityTokenOptionalService[];
}) {
  const t = useTranslations();
  const groupedByPassenger = groupByPassengerIndex(baggage);

  return (
    <div className="flex flex-col gap-2">
      {selectedTravellers.map((traveller, index) => (
        <div
          key={traveller.id}
          className="flex flex-col gap-2 rounded-2xl bg-white"
        >
          <div className="flex w-full items-center justify-between gap-2">
            <div className="flex flex-col">
              <span className="text-primary text-base font-medium">
                {traveller.firstName} {traveller.lastName}
              </span>
              <span className="text-tertiary text-sm">
                {t("travellers.passportId")}: {traveller.passportNumber || "-"}
              </span>
            </div>
          </div>
          {groupedByPassenger[index] && (
            <>
              <Divider flexItem sx={{ my: 1 }} />
              <div className="flex flex-col gap-4">
                {groupedByPassenger[index].map((service, index) => (
                  <div
                    key={index}
                    className="flex w-full items-center justify-between"
                  >
                    <div className="flex gap-6">
                      <div className="flex items-center gap-1">
                        {baggageIcons[service.service.categoryCode]}
                      </div>
                    </div>
                    <div className="flex flex-col text-end">
                      <span className="text-primary text-sm">
                        {service.service.name}
                      </span>
                      <span className="text-tertiary text-sm">
                        {service.route.arrivalCode} -{" "}
                        {service.route.departureCode}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default TravellersListSummary;
