import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { motion } from "motion/react";

import Footer from "@/app/ui/Footer";
import { MyTrips } from "@/app/ui/my-trips/MyTrips";
import { getQiToken } from "@/app/lib/api/api";
import { OrderType } from "@/app/lib/types/enums";

export const Route = createFileRoute("/_authenticated/trips")({
  validateSearch: (
    search: Record<string, unknown>,
  ): { category?: "flights" | "hotels"; orderType?: OrderType } => {
    return {
      category: search.category as "flights" | "hotels",
      orderType: search.orderType as OrderType,
    };
  },
  component: RouteComponent,
});

function RouteComponent() {
  const qi = getQiToken();
  const searchParams = Route.useSearch();

  return (
    <>
      <motion.div
        layoutId="hero"
        className="bg-hero-pattern absolute top-0 w-full rounded-b-2xl bg-cover bg-no-repeat"
      />
      <main
        className={clsx({
          main: !qi,
          "main-qi": !!qi,
        })}
      >
        <div className="min-h-full w-full overflow-auto p-4 pt-12">
          <MyTrips
            category={searchParams.category ?? "flights"}
            initOrderType={searchParams.orderType ?? OrderType.UPCOMING}
          />
        </div>

        <Footer />
      </main>
    </>
  );
}
