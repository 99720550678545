import { ReactNode, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

import { TextField } from "./StyledTz";
import { useLocaleInfo } from "../lib/hooks/useLocaleInfo";

type Props = {
  min: number;
  max: number;
  label: ReactNode;
  value: number;
  onChange: (value: number) => void;
};

export function CurrencyInput({
  min,
  max,
  label,
  value: initialValue,
  onChange,
}: Props) {
  const { dir } = useLocaleInfo();

  // This is a workaround for setting the actual value on blur event, which has the best UX in this case
  const [value, setValue] = useState<number | undefined>(initialValue);
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <NumericFormat
      sx={dir === "rtl" ? { "& input": { textAlign: "end" } } : undefined}
      // do not swap value and suffix order
      dir="ltr"
      value={value}
      suffix=" IQD"
      thousandSeparator=","
      customInput={TextField}
      fullWidth
      label={label}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        inputMode: "numeric",
        pattern: "d*",
      }}
      decimalScale={0}
      onValueChange={({ floatValue }) => {
        setValue(floatValue);
      }}
      onBlur={() => {
        const newValue = Math.max(Math.min(value || min, max), min);

        setValue(newValue);
        onChange(newValue);
      }}
    />
  );
}
