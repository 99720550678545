import {
  Bed,
  Lockers,
  Hamburger,
  ListBullets,
  ListChecks,
  Swap,
  Swatches,
} from "@phosphor-icons/react";

const iconProps = { size: 20, fill: "#6A778A" };
export const boardIcons: Record<string, JSX.Element> = {
  RO: <Bed {...iconProps} />,
  SC: <Hamburger {...iconProps} />,
  BB: <Swap {...iconProps} />,
  HB: <ListBullets {...iconProps} />,
  FB: <ListChecks {...iconProps} />,
  AI: <Swatches {...iconProps} />,
  RD: <Lockers {...iconProps} />,
};
