import { createFileRoute } from "@tanstack/react-router";
import { useFlightSearchDataContext } from "@/app/lib/context/FlightSearchDataContext";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import BottomDrawer from "@/app/ui/flight-purchase/BottomDrawer";
import TravellersList from "@/app/ui/travellers/TravellersList";

export const Route = createFileRoute(
  "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/2",
)({
  component: RouteComponent,
  staticData: {
    flightStep: { stepNumber: 2, stepTitle: "travellers.title" },
    drawer: true,
  },
});

function RouteComponent() {
  const navigate = Route.useNavigate();
  const { singleFlightDetails, isAdditionalDataLoading } =
    useSingleFlightSelectionContext();
  const { searchData } = useFlightSearchDataContext();
  const { selectedTravellersByAge } = useTravellersContext();

  return (
    <>
      <TravellersList
        adultsToSelect={searchData?.adult || 0}
        childrenToSelect={searchData?.child || 0}
        infantsToSelect={searchData?.infantWithoutSeat || 0}
        travelDate={searchData ? new Date(searchData.dates.departure) : null}
      />

      <BottomDrawer
        totalPrice={singleFlightDetails.totalPrice}
        buttonLabel="flights.continue"
        onButtonClick={() => navigate({ to: "/flights/offer/$id/step/3" })}
        disabled={
          searchData!.adult !== selectedTravellersByAge.adults ||
          searchData!.child !== selectedTravellersByAge.children ||
          searchData!.infantWithoutSeat !== selectedTravellersByAge.infants ||
          isAdditionalDataLoading
        }
      />
    </>
  );
}
