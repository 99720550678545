import AdditionalInfoCard from "@/app/ui/AdditionalInfoCard";
import BaggageAllowance from "@/app/ui/BaggageAllowance";
import FlightDetailsCard from "@/app/ui/FlightDetailsCard";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { Chip } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import BottomDrawer from "@/app/ui/flight-purchase/BottomDrawer";

export const Route = createFileRoute(
  "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/1",
)({
  component: RouteComponent,
  staticData: {
    flightStep: { stepNumber: 1, stepTitle: "flights.selectFlight" },
    drawer: true,
  },
});

function RouteComponent() {
  const {
    departureSegments,
    returnSegments,
    departureFlight,
    returnFlight,
    isAdditionalDataLoading,
    singleFlightDetails,
  } = useSingleFlightSelectionContext();
  const navigate = Route.useNavigate();

  const t = useTranslations();
  const departureStops = departureFlight?.stops || [];
  const returnStops = returnFlight?.stops || [];

  return (
    <>
      <div className="px-4">
        <div className="mt-2 mb-4">
          <div className="section-title">{t("flights.departure")}</div>
          <div className="flex flex-col gap-4">
            {departureSegments?.map((segment, index) => (
              <div
                className="flex flex-col gap-4"
                key={segment.arrival.date + segment.departure.time}
              >
                <FlightDetailsCard segment={segment} />
                {departureStops.length > 0 &&
                  departureStops[index]?.stopTime && (
                    <Chip
                      className="mx-auto"
                      color="primary"
                      label={t("flights.flightStop", {
                        hours: departureStops[index].stopTime.split(":")[0],
                        minutes: departureStops[index].stopTime.split(":")[1],
                        cityName: segment.arrival.city.name,
                        code: segment.arrival.airport.code,
                      })}
                    />
                  )}
              </div>
            ))}
          </div>

          {returnSegments && (
            <div className="mt-2 mb-4">
              <div className="section-title">{t("flights.search.return")}</div>
              {returnSegments?.map((segment, index) => (
                <div
                  className="flex flex-col gap-4"
                  key={segment.arrival.date + segment.departure.time}
                >
                  <FlightDetailsCard segment={segment} />
                  {returnStops?.length > 0 && returnStops[index]?.stopTime && (
                    <Chip
                      className="mx-auto"
                      color="primary"
                      label={t("flights.flightStop", {
                        hours: returnStops[index].stopTime.split(":")[0],
                        minutes: returnStops[index].stopTime.split(":")[1],
                        cityName: segment.arrival.city.name,
                        code: segment.arrival.airport.code,
                      })}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <BaggageAllowance
          singleFlightDetails={singleFlightDetails}
          isAdditionalDataLoading={isAdditionalDataLoading}
        />

        <AdditionalInfoCard
          singleFlightDetails={singleFlightDetails}
          isAdditionalDataLoading={isAdditionalDataLoading}
        />
      </div>

      <BottomDrawer
        totalPrice={singleFlightDetails.totalPrice}
        buttonLabel="flights.continue"
        onButtonClick={() => navigate({ to: "/flights/offer/$id/step/2" })}
        disabled={isAdditionalDataLoading}
      />
    </>
  );
}
