import dayjs from "dayjs";
import {
  TemporallyStoreFlightBookingDetailsTraveler,
  TemporallyStoreHotelBookingDetailsTraveler,
} from "../types/codegen";
import { getAgeFromDateOfBirth } from "../getAgefromDateOfBirth";
import { MIN_ADULT_AGE, MIN_CHILD_AGE } from "../constants";
import { Traveller } from "../types/types";

function getTravelerPassengerTypeCode(traveler: Pick<Traveller, "birthDate">) {
  const age = getAgeFromDateOfBirth(traveler.birthDate);
  const type = getTravelerTypeOfAge(age);
  if (type === "adult") {
    return "ADT";
  }
  if (type === "child") {
    return "CHD";
  }
  return "INF";
}

export function getTravelerIntlLabel(code: string) {
  if (code === "ADT") {
    return "travellers.adult";
  }

  if (code === "CHD") {
    return "travellers.child";
  }

  return "travellers.infant";
}

export function getTravelerType(traveler: Pick<Traveller, "birthDate">) {
  const code = getTravelerPassengerTypeCode(traveler);

  if (code === "ADT") {
    return "adult";
  }

  if (code === "CHD") {
    return "child";
  }

  return "infant";
}

export function getTravelerTypeOfAge(age: number) {
  if (age >= MIN_ADULT_AGE) {
    return "adult";
  }

  if (age >= MIN_CHILD_AGE) {
    return "child";
  }

  return "infant";
}

export function transformFlightTraveler(
  traveler: Traveller,
): TemporallyStoreFlightBookingDetailsTraveler {
  return {
    ...traveler,
    passportExpiryDate: dayjs(traveler.passportExpiryDate).format("YYYY-MM-DD"),
    passportIssuingDate: dayjs(traveler.passportIssuingDate).format(
      "YYYY-MM-DD",
    ),
    emailAddress: traveler.emailAddress || undefined,
    passengerTypeCode: getTravelerPassengerTypeCode(traveler),
    type: getTravelerType(traveler),
    birthDate: dayjs(traveler.birthDate).format("YYYY-MM-DD"),
    phoneNumber: traveler.phoneNumber.replace(traveler.phonePrefix, ""),
  };
}

export function transformHotelTraveler(
  traveler: Traveller,
  { isLead }: { isLead?: boolean } = {},
): TemporallyStoreHotelBookingDetailsTraveler {
  return {
    ...traveler,
    lead: isLead || false,
    type: getTravelerType(traveler),
    birthDate: dayjs(traveler.birthDate).format("YYYY-MM-DD"),
    emailAddress: traveler.emailAddress || undefined,
    phoneNumber: traveler.phoneNumber.replace(traveler.phonePrefix, ""),
  };
}
