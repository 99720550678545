import { CircularProgress } from "@mui/material";
import { useTranslations } from "use-intl";

import { getBaggageInfo } from "@/app/lib/utils/baggageProcessor";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";

import BaggageInfo from "./BaggageInfo";

function BaggageAllowance({
  singleFlightDetails,
  isAdditionalDataLoading,
}: {
  singleFlightDetails: SingleFightDetailsData;
  isAdditionalDataLoading: boolean;
}) {
  const t = useTranslations();
  const baggageInfo = getBaggageInfo(singleFlightDetails);

  return (
    <>
      <div className="section-title">{t("flights.baggageDetails")}</div>
      <div className="section-card flex flex-col gap-4">
        <div className="text-primary flex w-full items-center gap-2 text-sm font-normal">
          {isAdditionalDataLoading ? (
            <>
              <CircularProgress size={20} />
              {t("flights.retrievingBaggageDetails")}
            </>
          ) : !baggageInfo ? (
            <>{t("flights.noBaggage")}</>
          ) : (
            <div className="flex w-full flex-col gap-2">
              <h3 className="text-primary text-sm font-semibold">
                {t("flights.totalBaggageAllowance")}
              </h3>

              <BaggageInfo baggageInfo={baggageInfo} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default BaggageAllowance;
