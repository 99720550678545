import { MobileStepper } from "@mui/material";
import { useTranslations } from "use-intl";

const TOTAL_STEPS = 3;

const Stepper = ({
  stepNumber,
  stepTitle,
}: {
  stepNumber: number;
  stepTitle: string;
}) => {
  const t = useTranslations();

  return (
    <div className="px-4">
      <MobileStepper
        variant="progress"
        steps={TOTAL_STEPS + 1} // 0 is a valid step so we need to add 1
        position="static"
        activeStep={stepNumber}
        nextButton={null}
        backButton={null}
      />
      <div className="mb-4 flex w-full items-center gap-2">
        <h5 className="text-title text-xs font-semibold">{t(stepTitle)}</h5>
        <span className="text-tertiary text-xs">
          {t("flights.step")} {stepNumber}/{TOTAL_STEPS}
        </span>
      </div>
    </div>
  );
};

export default Stepper;
