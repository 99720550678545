import { Button } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";

interface OfferNotAvailableErrorProps {
  variant: "flight" | "hotel";
}

export const OfferNotAvailableError = ({
  variant,
}: OfferNotAvailableErrorProps) => {
  const masterKey =
    variant === "flight" ? "flights.notAvailable" : "hotels.notAvailable";

  const t = useTranslations(masterKey);
  const navigate = useNavigate();
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center text-center">
      <h1 className="text-primary text-center text-2xl">{t("title")}</h1>
      <p className="text-secondary mb-4 px-8">{t("description")}</p>
      <Button
        variant="outlined"
        onClick={() =>
          navigate({
            to: variant === "flight" ? "/flights/list" : "/hotels/list",
          })
        }
      >
        {t("button")}
      </Button>
    </div>
  );
};
