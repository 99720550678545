import FlightBookSuccess from "@/app/ui/flight-purchase/FlightBookSuccess";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { createFileRoute } from "@tanstack/react-router";
import { validateOrderId } from "@/app/lib/routeValidation";

export const Route = createFileRoute(
  "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/final",
)({
  validateSearch: validateOrderId,
  component: RouteComponent,
  staticData: {
    drawer: false,
  },
});

function RouteComponent() {
  const { orderId } = Route.useSearch();

  const { departureFlight, returnFlight, singleFlightDetails } =
    useSingleFlightSelectionContext();
  const { getSelectedTravellers } = useTravellersContext();

  return (
    <FlightBookSuccess
      singleFlightDetails={singleFlightDetails}
      departureFlight={departureFlight}
      returnFlight={returnFlight}
      departureStops={departureFlight?.stops || []}
      returnStops={returnFlight?.stops || []}
      selectedTravellers={getSelectedTravellers}
      orderId={orderId}
    />
  );
}
