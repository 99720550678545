import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import { useHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { validateOrderId } from "@/app/lib/routeValidation";
import { HotelBookingSuccess } from "@/app/ui/hotel/HotelBookingSuccess";
import * as Sentry from "@sentry/browser";
import { createFileRoute, Navigate } from "@tanstack/react-router";

export const Route = createFileRoute(
  "/_authenticated/_hotels/_hotel-offer/hotels/$id/confirmation",
)({
  validateSearch: validateOrderId,
  component: RouteComponent,
  staticData: {
    drawer: false,
  },
});

function RouteComponent() {
  const { orderId } = Route.useSearch();

  const { getSelectedTravellers } = useTravellersContext();
  const { data: hotel } = useHotelDataContext();
  const { data: booking } = useHotelBookingContext();

  const params = Route.useParams();

  if (!booking || !hotel) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access hotel confirmation page directly without going through the hotel offer page",
      "fatal",
    );
    return <Navigate to="/hotels/$id/details" params={params} />;
  }

  return (
    <HotelBookingSuccess
      orderId={orderId}
      hotel={hotel}
      rooms={booking?.rooms ?? []}
      visitors={getSelectedTravellers.length}
    />
  );
}
