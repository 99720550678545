import { PropsWithChildren, useMemo, useState } from "react";
import {
  HotelSearchDataContext,
  HotelSearchDataContextValue,
} from "./HotelSearchDataContext";

export const HotelSearchDataProvider = ({ children }: PropsWithChildren) => {
  const [searchData, setSearchData] =
    useState<HotelSearchDataContextValue["searchData"]>(null);

  const contextValue = useMemo(
    () => ({
      searchData,
      updateSearchData: setSearchData,
    }),
    [searchData],
  );

  return (
    <HotelSearchDataContext.Provider value={contextValue}>
      {children}
    </HotelSearchDataContext.Provider>
  );
};
