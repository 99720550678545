import { FormikErrors } from "formik";
import { useTranslations } from "use-intl";

export function getFormikErrorsRecursive<T>(
  formikErrors: FormikErrors<T>,
  t?: ReturnType<typeof useTranslations>,
) {
  const errors: string[] = [];
  (Object.keys(formikErrors) as (keyof T)[]).forEach((key) => {
    const value = formikErrors[key];
    if (typeof value === "object") {
      errors.push(...getFormikErrorsRecursive(value, t));
    } else if (value) {
      errors.push(t ? t(value) : value);
    }
  });
  return errors;
}
