import dayjs from "dayjs";
import { FormikHelpers } from "formik";
import { DateRange, DayPicker } from "react-day-picker";
import type {
  DayEventHandler,
  DayPickerProps,
  Matcher,
} from "react-day-picker";
import { ar } from "react-day-picker/locale";
import "react-day-picker/style.css";

import { useLocaleInfo } from "@/app/lib/hooks/useLocaleInfo";

import { TextField } from "./StyledTz";

type FieldInfo = {
  path: string;
  value: string;
  label: string;
};

export type CalendarProps<T> = {
  startField: FieldInfo;
  endField?: FieldInfo;
  setFieldValue: FormikHelpers<T>["setFieldValue"];
  dropdown?: boolean;
  disabledDates?: Matcher;
  startMonth?: Date;
  endMonth?: Date;
  allowSameDayRangeSelection?: boolean;
};

export function Calendar<T>({
  startField,
  endField,
  setFieldValue,
  dropdown,
  disabledDates,
  allowSameDayRangeSelection,
  startMonth,
  endMonth,
}: CalendarProps<T>) {
  const { locale } = useLocaleInfo();

  const selectedDay: Date | undefined = dayjs(startField.value).isValid()
    ? dayjs(startField.value).toDate()
    : undefined;

  const selectedRange: DateRange = {
    from: dayjs(startField.value).isValid()
      ? dayjs(startField.value).toDate()
      : undefined,
    to: dayjs(endField?.value).isValid()
      ? dayjs(endField?.value).toDate()
      : undefined,
  };

  const setDateFieldValue = (path: string, date: Date | undefined) => {
    setFieldValue(path, date ? dayjs(date).format("YYYY-MM-DD") : "");
  };

  const handleDayClick: DayEventHandler<React.MouseEvent> = (date) => {
    // single date selection
    if (!endField) {
      setDateFieldValue(startField.path, date);
      return;
    }

    // date range selection
    if (selectedRange.to) {
      // whole range was already selected, begin to select new range
      setDateFieldValue(startField.path, date);
      setDateFieldValue(endField.path, undefined);
      return;
    }

    // make sure dates in range are in proper order
    if (dayjs(date).isBefore(selectedRange.from)) {
      setDateFieldValue(startField.path, date);
      setDateFieldValue(endField.path, selectedRange.from);
      return;
    }

    // same from and to dates selected
    if (!allowSameDayRangeSelection && dayjs(date).isSame(selectedRange.from)) {
      return;
    }

    setDateFieldValue(endField.path, date);
  };

  const fromValue = endField?.value ? selectedRange.from : selectedDay;

  const formatWeekdayName = (date: Date) => {
    return dayjs(date).format("ddd");
  };

  const formatMonthCaption = (date: Date) => {
    return dayjs(date).format("MMMM YYYY");
  };

  const pickerProps = {
    className: "mt-8",
    locale: locale === "ar" ? ar : undefined,
    formatters: {
      formatWeekdayName,
      formatMonthCaption,
    },
    startMonth: startMonth || new Date(1940, 1),
    endMonth: endMonth || new Date(2050, 1),
    captionLayout: dropdown ? "dropdown" : "label",
    disabled: disabledDates,
    defaultMonth: selectedDay || new Date(),
    required: true,
    onSelect: () => {}, // needed to keep the calendar in controlled mode
    onDayClick: handleDayClick,
    classNames: {
      day: "text-primary",
      chevron: "fill-interactive",
      nav: "absolute top-0 right-0 left-0 flex items-center justify-between",
      button_previous: "fill-interactive mb-4 rtl:-scale-x-100",
      button_next: "fill-interactive mb-4 rtl:-scale-x-100",
      month_caption: "text-interactive text-lg font-bold text-center",
      selected: "selected-day",
    },
  } satisfies Partial<DayPickerProps>;

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-full gap-6">
        <TextField
          value={fromValue ? dayjs(fromValue).format("ddd, D MMM") : ""}
          className="pointer-events-none w-[50%]"
          label={startField.label}
          slotProps={{
            input: {
              readOnly: true,
            },
            inputLabel: {
              shrink: true,
            },
          }}
        />
        {endField ? (
          <TextField
            value={
              selectedRange.to
                ? dayjs(selectedRange.to).format("ddd, D MMM")
                : ""
            }
            className="pointer-events-none w-[50%]"
            label={endField.label}
            slotProps={{
              input: {
                readOnly: true,
              },
              inputLabel: {
                shrink: true,
              },
            }}
          />
        ) : (
          <div className="w-[50%]"></div>
        )}
      </div>

      {endField ? (
        <DayPicker
          {...pickerProps}
          mode="range"
          styles={{
            today: {
              boxShadow: "0 0 0 1px #CFD4DD !important",
              color: "#222930",
            },
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
          }}
          selected={selectedRange}
        />
      ) : (
        <DayPicker
          {...pickerProps}
          mode="single"
          styles={{
            day_button: {
              width: "32px",
              height: "32px",
              margin: "0 8px",
            },
            selected: {
              backgroundColor: "#003E2B", // Replace with your desired color
              color: "#FFFFFF", // Optional: text color for selected days
            },
            month_caption: {
              color: "#003E2B", // Replace with your desired color
              fontWeight: "bold", // Optional for bold text
            },
          }}
          selected={selectedDay}
        />
      )}
    </div>
  );
}
