import { Button } from "@mui/material";
import { useTranslations } from "use-intl";
import { Traveller } from "@/app/lib/types/types";

function DeleteTraveller({
  traveller,
  closeDrawer,
  deleteTraveller,
}: {
  traveller: Traveller;
  closeDrawer: () => void;
  deleteTraveller: () => void;
}) {
  const t = useTranslations();

  const fullName = `${traveller.firstName} ${traveller.lastName}`;
  const removeTravellerHandler = () => {
    deleteTraveller();
    closeDrawer();
  };

  return (
    <div className="flex h-full w-full flex-col items-center">
      <h5 className="text-title mt-2 mb-3 text-base leading-6 font-medium">
        {t("travellers.deletePerson")}
      </h5>
      <span className="text-primary mb-10 text-sm font-normal">
        {t("travellers.deleteName", {
          fullName,
        })}
      </span>
      <div className="mt-auto flex w-full flex-col gap-2">
        <Button
          onClick={removeTravellerHandler}
          variant="contained"
          color="error"
        >
          {t("delete")}
        </Button>
        <Button onClick={closeDrawer} variant="text">
          {t("cancel")}
        </Button>
      </div>
    </div>
  );
}

export default DeleteTraveller;
