import { useState, useCallback, PropsWithChildren } from "react";
import { Snackbar } from "@mui/material";
import { SnackbarContext } from "./SnackbarContext";

export const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });

  const showSnackbar = useCallback((message: string) => {
    setSnackbar((prev) => ({
      ...prev,
      open: true,
      message,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        message={snackbar.message}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          bottom:
            "calc(env(safe-area-inset-bottom) + var(--footer-height) + (var(--spacing) * 4))",
        }}
      />
    </SnackbarContext.Provider>
  );
};
