import {
  FindFlightOffersSegment,
  FindFlightOffersFlight,
  FindFlightOffersDestination,
  FindFlightOffersStop,
  FindUserTravelersOutputData,
  FindHotelOffersRoomOccupancy,
  InitiateHotelsSearchOutputData,
  InitiateFlightsSearchOutputData,
  FindUserTravelersTravelers,
  FindHotelOffersRoom,
  FindOrdersReservationsForFlightOrder,
  FindOrdersReservationsForHotelOrder,
} from "./codegen";
import {
  TripType,
  CabinClass,
  TravelPurpose,
  SortOrder,
  HotelsSortOptions,
  FlightsSortOptions,
} from "./enums";

export enum PurchaseStatus {
  SUCCESSFUL = "SUCCESSFUL",
  CANCELLED = "CANCELLED",
  FAILED = "FAILED",
  EXPIRED = "EXPIRED",
}

export type Traveller = FindUserTravelersTravelers;

export type BookingResult = {
  orderId: string;
  status: PurchaseStatus;
};

export type PaymentResult = {
  orderId: string;
  status: PurchaseStatus;
};

export type TFlightFormValues = {
  tripType: TripType;
  from: {
    name: string;
    code: string;
    id: number;
    city: string;
    airports?: { name: string; code: string; id: number }[];
  };
  to: {
    name: string;
    code: string;
    id: number;
    city: string;
    airports?: { name: string; code: string; id: number }[];
  };
  dates: {
    departure: string;
    return: string;
  };
  adult: number;
  child: number;
  infantWithoutSeat: number;
  cabinClass: CabinClass;
};

export type TCity = {
  id: number;
  name: string;
  code: string;
  countryName: string;
  countryCode: string;
};

export type TRoom = {
  adults: number;
  children: number;
  childrenAges: (number | string)[];
};

export type DocumentScanningResult = {
  data: {
    givenName?: string;
    surName?: string;
    dateOfBirth?: string;
    gender?: string;
    nationality?: string;
    passportNumber?: string;
    dateOfIssue?: string;
    dateOfExpiry?: string;
  };
};
export type THotelFormValues = {
  destination: TCity;
  dates: {
    checkIn: string;
    checkOut: string;
  };
  rooms: TRoom[];
  travelPurpose: TravelPurpose | string;
};

export type THotelSearchResult = {
  id: number | string;
  name: string;
  address: string;
  specialDeal?: boolean;
  recommended?: boolean;
  descriptions?: {
    short: string;
    full: string;
    location: string;
    facilities: string;
    rooms: string;
    essentialInformation: string;
  };
  boardBasis?: string[];
  stars: number;
  mainImageUrl: string;
  imagesUrls: string[];
  telephone?: string;
  email?: string;
  fax?: string;
  minPrice?: {
    value: number;
    currency: string;
  };
  geolocation?: {
    latitude: number;
    longitude: number;
  };
};

export type HotelSearchTokens = InitiateHotelsSearchOutputData;
export type FlightSearchTokens = InitiateFlightsSearchOutputData;

type ItieraryFlightSegmentOptionalKeys =
  | "cabinClasses"
  | "baggages"
  | "equipment";

export interface ItieraryFlightSegment
  extends Partial<
      Pick<FindFlightOffersSegment, ItieraryFlightSegmentOptionalKeys>
    >,
    Omit<
      FindFlightOffersSegment,
      ItieraryFlightSegmentOptionalKeys | "departure" | "arrival"
    > {
  departure: Omit<FindFlightOffersDestination, "city">;
  arrival: Omit<FindFlightOffersDestination, "city">;
}

export interface ItieraryFlight
  extends Omit<FindFlightOffersFlight, "segments" | "duration" | "stops"> {
  segments: ItieraryFlightSegment[];
  stops: Partial<FindFlightOffersStop>[];
  duration?: FindFlightOffersFlight["duration"];
}

export interface Itierary {
  flights: ItieraryFlight[];
  totalPrice?: number;
  currency?: string;
}

type HotelOptionalKeys =
  | "telephone"
  | "email"
  | "fax"
  | "geolocation"
  | "address"
  | "imagesUrls";

export type Hotel = Omit<THotelSearchResult, HotelOptionalKeys> &
  Partial<Pick<THotelSearchResult, HotelOptionalKeys>>;
export type THotelRoom = {
  name: string;
  board: string;
} & FindHotelOffersRoom;

export type TRoomOutput = {
  packageRoomToken: string;
  travelers: FindUserTravelersOutputData[];
};

interface Price {
  value: number;
  currency: string;
}

/**
 * Overwrite the default HotelBookingRoomOption type, as it includes an incorrect field types.
 * @see FindHotelOffersPackageRoomOption
 */
export interface HotelBookingRoomOption {
  board: string;
  boardBasis: string;
  price: Price;
  roomToken: string;
  name: string;
}

/**
 * Overwrite the default HotelOfferRoom type, as it includes an incorrect field types.
 * @see FindHotelOffersRoom
 */
export interface HotelOfferRoom {
  options: HotelBookingRoomOption[];
  occupancy: FindHotelOffersRoomOccupancy;
}

/**
 * Overwrite the default HotelOffer type, as it includes an incorrect field types.
 * @see FindHotelOffersOutputData
 */
export interface HotelOffer {
  offerId: string;
  price: Price;
  packageToken: string;
  rooms: HotelOfferRoom[];
}

export interface HotelBooking {
  packageToken: string;
  offerId: string;
  price?: Price;
  rooms: HotelBookingRoomOption[];
}

export type SortValue<T> = {
  order: SortOrder;
  field: T;
};

export type HotelsSortValue = SortValue<HotelsSortOptions>;
export type FlightsSortValue = SortValue<FlightsSortOptions>;

export enum ValidationExceptionCode {
  CannotContainGivenPattern = "VALIDATION_VALUE_CANNOT_CONTAIN_GIVEN_PATTERN",
  IsDateString = "VALIDATION_VALUE_IS_DATE_STRING",
  MinimumLength = "VALIDATION_VALUE_MINIMUM_LENGTH",
  MustBeOneOf = "VALIDATION_VALUE_MUST_BE_ONE_OF",
}

export type ApiError = {
  code: string;
  data: {
    validationExceptionCode: ValidationExceptionCode;
    property: string;
    message: string;
  }[];
  message: string;
  status: string;
};

export type OrderStatus =
  | FindOrdersReservationsForFlightOrder["status"]
  | FindOrdersReservationsForHotelOrder["status"];
