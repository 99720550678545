const FALLBACK_PRICES = [1, 999999];

export const getPricesBasedOnProgressData = ({
  minPrice,
  maxPrice,
  bookingNights,
}: {
  minPrice?: number;
  maxPrice?: number;
  bookingNights: number;
}): {
  priceRange: [number, number];
  minPrice: number;
  maxPrice: number;
} => {
  const minPricePerNight = minPrice
    ? minPrice / bookingNights
    : FALLBACK_PRICES[0];
  const maxPricePerNight = maxPrice
    ? maxPrice / bookingNights
    : FALLBACK_PRICES[1];
  return {
    priceRange: [minPricePerNight, maxPricePerNight],
    minPrice: minPricePerNight,
    maxPrice: maxPricePerNight,
  };
};
