type Options = {
  maximumFractionDigits?: number;
};

export const formatCurrency = (
  amount: number | undefined,
  currency: string | undefined = "IQD",
  options?: Options,
) => {
  if (amount == null) {
    return "-";
  }

  // By default, currency is returned with currency symbol first, we want it to be at the end
  return amount
    .toLocaleString("en-US", {
      style: "currency",
      currency,
      ...options,
    })
    .split(" ")
    .reverse()
    .join(" ");
};
