import { useEffect, useState } from "react";
import { useTranslations } from "use-intl";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import {
  SwipeableDrawer,
  FormControlLabel,
  RadioGroup,
  Radio,
  TextField as MuiTextField,
  Button,
} from "@mui/material";
import {
  CaretDown,
  AirplaneTakeoff,
  AirplaneLanding,
  CaretUpDown,
  X,
} from "@phosphor-icons/react";
import { TextField, Puller } from "../StyledTz";
import { FormikContext, useFormik } from "formik";
import { TripType, CabinClass } from "@/app/lib/types/enums";
import { initialFlightsValues } from "@/app/lib/initialFlightsValues";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { getQiToken } from "@/app/lib/api/api";
import { string, object, date, number, ref } from "yup";
import { useFlightSearchDataContext } from "@/app/lib/context/FlightSearchDataContext";
import AirportSearch from "./AirportSearch";
import TravellersSelection from "./TravellersSelection";
import DatesSelection from "./DatesSelection";
import dayjs from "dayjs";
import { useNavigate } from "@tanstack/react-router";
import { useFlightFiltersContext } from "@/app/lib/context/FlightFiltersContext";
import { useFlightSortContext } from "@/app/lib/context/FlightSortContext";

enum EDrawerComponent {
  AirportSearch = "AirportSearch",
  DatesSelection = "DatesSelection",
  PassengersSelection = "PassengersSelection",
}

const cabinClassLabels = {
  [CabinClass.ECONOMY]: "economy",
  [CabinClass.BUSINESS]: "business",
  [CabinClass.PREMIUM_ECONOMY]: "premium",
  [CabinClass.FIRST_CLASS]: "firstClass",
};

type InitialFlightsValuesKeys = keyof typeof initialFlightsValues;

const validationSchema = object().shape({
  dates: object().shape({
    departure: date()
      .required("flights.validation.dates.departure")
      .test(
        "departureRequired",
        "flights.validation.dates.minDeparture",
        (date) => {
          if (!date) return true;
          return !dayjs(date).endOf("day").isBefore(dayjs());
        },
      ),
    return: date()
      .optional()
      .min(ref("departure"), "flights.validation.dates.minReturn")
      .test(
        "returnRequiredIfRoundTrip",
        "flights.validation.dates.return",
        (date, context) => {
          const [, formValues] = context.from!;

          return formValues.value.tripType !== TripType.ROUND_TRIP || !!date;
        },
      ),
  }),
  from: object({
    name: string().required("flights.validation.from"),
  }),
  to: object({
    name: string().required("flights.validation.to"),
  }),
  tripType: string().required("flights.validation.tripType"),
  cabinClass: string().required("flights.validation.cabinClass"),
  adult: number().min(1),
  child: number().min(0),
});

function FlightCalculator() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerComponent, setDrawerComponent] =
    useState<EDrawerComponent | null>(null);
  const qi = getQiToken();
  const { updateSearchData } = useFlightSearchDataContext();
  const { resetFilters } = useFlightFiltersContext();
  const { resetSortValue } = useFlightSortContext();
  const showSnackbar = useSnackbar();
  const t = useTranslations();

  const handleDrawerOpen = (component: EDrawerComponent) => {
    setDrawerOpen(true);
    setDrawerComponent(component);
  };

  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: initialFlightsValues,
    onSubmit: (values) => {
      resetFilters();
      resetSortValue();
      updateSearchData(values);
      navigate({ to: "/flights/list" });
    },
  });

  const { setFieldValue } = formik;
  const { departure: departureDate, return: returnDate } = formik.values.dates;

  // make sure we don't have a return date before the departure date
  useEffect(() => {
    if (dayjs(returnDate).isBefore(dayjs(departureDate))) {
      setFieldValue("dates.return", departureDate);
    }
  }, [departureDate, returnDate, setFieldValue]);

  const passengerCount =
    formik.values.adult + formik.values.child + formik.values.infantWithoutSeat;

  const changeAirportValues = () => {
    formik.setFieldValue("from", formik.values.to);
    formik.setFieldValue("to", formik.values.from);
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res, t)[0]);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const onMouseDownHandler = (
    e: React.MouseEvent<HTMLDivElement>,
    type: InitialFlightsValuesKeys,
    component: EDrawerComponent,
  ) => {
    if (
      // TODO: Quick solution, find better solution for this post mvp
      (e.target as HTMLElement).namespaceURI === "http://www.w3.org/2000/svg"
    ) {
      e.preventDefault();
      formik.setFieldValue(type, initialFlightsValues[type]);
    } else {
      e.preventDefault();
      handleDrawerOpen(component);
    }
  };

  const departureValue = dayjs(formik.values.dates.departure).format(
    "ddd, D MMM",
  );

  const returnValue = formik.values.dates.return
    ? dayjs(formik.values.dates.return).format("ddd, D MMM")
    : "_";

  return (
    <FormikContext.Provider value={formik}>
      <div className="w-full rounded-3xl p-4">
        <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
          <RadioGroup
            row
            aria-labelledby="trip-type"
            name="tripType"
            className="mb-5 flex justify-center gap-6"
            value={formik.values.tripType}
            onChange={(e) => {
              const { departure: departureDate, return: returnDate } =
                formik.values.dates;

              if (dayjs(returnDate).isBefore(dayjs(departureDate))) {
                formik.setFieldValue("dates.return", departureDate);
              }

              formik.handleChange(e);
            }}
          >
            <FormControlLabel
              value="one-way"
              control={<Radio sx={{ px: 0, paddingInlineEnd: "8px" }} />}
              label={t("flights.search.oneWay")}
              sx={{
                m: 0,
                "& .MuiTypography-root": {
                  fontSize: 14,
                },
              }}
            />
            <FormControlLabel
              value="round-trip"
              control={<Radio sx={{ px: 0, paddingInlineEnd: "8px" }} />}
              label={t("flights.search.roundTrip")}
              sx={{
                m: 0,
                "& .MuiTypography-root": {
                  fontSize: 14,
                },
              }}
            />
          </RadioGroup>
          <div className="mb-4 flex flex-col gap-4">
            <div className="relative flex flex-col gap-4">
              <MuiTextField
                fullWidth
                onMouseDown={(e) => {
                  onMouseDownHandler(e, "from", EDrawerComponent.AirportSearch);
                }}
                InputProps={{
                  endAdornment: formik.values.from.name && (
                    <>
                      <button className="absolute end-3">
                        <X size={20} color="#6A778A" />
                      </button>
                      {!formik.values.from?.airports && (
                        <span className="text-placeholder absolute end-9 text-sm">
                          {formik.values.from.code}
                        </span>
                      )}
                    </>
                  ),
                }}
                sx={{
                  input: {
                    paddingInlineEnd: "70px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                  },
                }}
                value={formik.values.from.city}
                label={
                  <div className="flex items-center gap-2">
                    <AirplaneTakeoff size={24} />
                    <span>{t("flights.search.from")}</span>
                  </div>
                }
              />
              <CaretUpDown
                onClick={changeAirportValues}
                height={40}
                width={26}
                color="white"
                className="absolute end-5 top-11 z-10 rounded-2xl bg-[#3B3C3D] hover:cursor-pointer"
              />
              <TextField
                fullWidth
                value={formik.values.to.city}
                onMouseDown={(e) => {
                  onMouseDownHandler(e, "to", EDrawerComponent.AirportSearch);
                }}
                InputProps={{
                  endAdornment: formik.values.to.name && (
                    <>
                      <button className="absolute end-3 z-10">
                        <X size={20} color="#6A778A" />
                      </button>
                      {!formik.values.to?.airports && (
                        <span className="text-placeholder absolute end-9 text-sm">
                          {formik.values.to.code}
                        </span>
                      )}
                    </>
                  ),
                }}
                label={
                  <div className="flex items-center gap-2">
                    <AirplaneLanding size={24} />
                    <span>{t("flights.search.to")}</span>
                  </div>
                }
                sx={{
                  input: {
                    paddingInlineEnd: "70px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    userSelect: "none",
                  },
                }}
              />
            </div>
            <TextField
              onMouseDown={(e) => {
                onMouseDownHandler(
                  e,
                  "dates.departure" as InitialFlightsValuesKeys,
                  EDrawerComponent.DatesSelection,
                );
              }}
              fullWidth
              label={t("flights.search.departureDate")}
              InputProps={{
                endAdornment: formik.values.dates.departure && (
                  <button type="button" className="absolute end-5 z-10">
                    <X size={20} color="#6A778A" />
                  </button>
                ),
              }}
              value={
                formik.values.tripType === TripType.ROUND_TRIP
                  ? `${departureValue} - ${returnValue}`
                  : departureValue
              }
            />
            <TextField
              fullWidth
              id="passengersNo"
              label={t("flights.search.travellers")}
              className="w-full p-0"
              InputProps={{
                endAdornment: (
                  <CaretDown
                    className="absolute end-5"
                    color="black"
                    size={24}
                  />
                ),
              }}
              value={`${passengerCount > 1 ? t("persons") : t("person")} ${passengerCount}, ${t(
                `flights.cabinClass.${cabinClassLabels[formik.values.cabinClass]}`,
              )}`}
              onMouseDown={(e) => {
                e.preventDefault();
                handleDrawerOpen(EDrawerComponent.PassengersSelection);
              }}
            />
          </div>
          <Button fullWidth variant="contained" type="submit">
            {t("flights.search.searchFlight")}
          </Button>
          <SwipeableDrawer
            anchor="bottom"
            onClose={() => setDrawerOpen(false)}
            onOpen={() => setDrawerOpen(true)}
            open={drawerOpen}
            sx={{
              "& .MuiDrawer-paper": {
                height: !drawerComponent ? "auto" : qi ? "90vh" : "100vh",
                paddingBottom: "env(safe-area-inset-bottom)",
                overflow: "hidden",
              },
            }}
          >
            <Puller />
            {drawerComponent === EDrawerComponent.AirportSearch &&
              drawerOpen && (
                <AirportSearch
                  formProps={formik}
                  onDrawerClose={() => setDrawerOpen(false)}
                />
              )}
            {drawerComponent === EDrawerComponent.DatesSelection &&
              drawerOpen && (
                <DatesSelection
                  formProps={formik}
                  onDrawerClose={() => setDrawerOpen(false)}
                />
              )}
            {drawerComponent === EDrawerComponent.PassengersSelection &&
              drawerOpen && (
                <TravellersSelection
                  formProps={formik}
                  onDrawerClose={() => setDrawerOpen(false)}
                />
              )}
          </SwipeableDrawer>
        </form>
      </div>
    </FormikContext.Provider>
  );
}

export default FlightCalculator;
