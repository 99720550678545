import { useMutation } from "@tanstack/react-query";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/react";

import { getQiToken, my, qi, apiV2 } from "../api/api";
import {
  TemporallyStoreHotelBookingDetailsBodyInputDto,
  CreateSuperQiOrderV2OutputDto,
  CreateQiServicesOrderV2OutputDto,
} from "../types/codegen";
import { BookingResult, PaymentResult, PurchaseStatus } from "../types/types";

async function initiatePayment(availabilityToken: string) {
  try {
    const response = await apiV2.post<CreateSuperQiOrderV2OutputDto>(
      "/orders/super-qi",
      {
        serviceType: "hotel",
        availabilityToken,
      },
    );

    const paymentUrl = response?.data?.data?.redirectUrl;
    if (!paymentUrl) {
      throw new Error(
        "Invalid response from API: Missing redirect action form",
      );
    }

    return new Promise<PaymentResult>((resolve, reject) => {
      my?.tradePay({
        paymentUrl,
        success: (res) => {
          switch (res.resultCode) {
            case "9000": // Payment is successful
              resolve({
                orderId: response.data.data.orderId,
                status: PurchaseStatus.SUCCESSFUL,
              });
              break;

            case "6001": // User cancels to pay
              resolve({
                orderId: response.data.data.orderId,
                status: PurchaseStatus.CANCELLED,
              });
              break;

            default:
              reject(new Error(`Payment failed: ${res.resultCode}`));
              break;
          }
        },
        fail: (err) => {
          console.error("Payment failed:", err);
          reject(new Error(`Payment failed: ${err}`));
        },
      });
    });
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as Error).message || err}`,
    );
  }
}

async function initiateQiServicesPayment(availabilityToken: string) {
  try {
    // TODO: replace with CreateQiServicesOrderV2OutputDto when fixed
    const response = await apiV2.post<{
      data: CreateQiServicesOrderV2OutputDto["data"][number];
    }>("/orders/qi-services", {
      serviceType: "hotel",
      availabilityToken,
    });

    return new Promise<PaymentResult>((resolve) => {
      qi?.purchase(
        {
          price: 1600,
          description: "",
          timeToLiveMillis: 60000,
          merchantTransactionID: response.data.data.orderId.toString(),
        },
        async (data) => {
          resolve({
            orderId: response.data.data.orderId,
            status: data.status,
          });
        },
      );
    });
  } catch (err) {
    console.error("Failed to initiate payment:", err);
    throw new Error(
      `Failed to initiate payment: ${(err as Error).message || err}`,
    );
  }
}

type Params = Pick<
  TemporallyStoreHotelBookingDetailsBodyInputDto,
  "rooms" | "availabilityToken" | "searchResultKey" | "searchResultToken"
> & {
  offerId: string;
  hotelId: string;
};

export const useHotelBooking = ({
  onSuccess,
}: {
  onSuccess: (result: BookingResult) => void;
}) => {
  const t = useTranslations();

  return useMutation({
    mutationFn: async ({
      offerId,
      hotelId,
      rooms,
      availabilityToken,
      searchResultKey,
      searchResultToken,
    }: Params) => {
      try {
        await apiV2.post(`/hotels/${offerId}/book`, {
          availabilityToken,
          searchResultKey,
          searchResultToken,
          hotelId: parseInt(hotelId),
          rooms,
        });

        const qiToken = getQiToken();

        if (!qiToken) {
          const { orderId, status } = await initiatePayment(availabilityToken);

          return {
            orderId,
            status,
          };
        }

        const { orderId, status } =
          await initiateQiServicesPayment(availabilityToken);

        if (status === PurchaseStatus.SUCCESSFUL) {
          await apiV2.post("/transactions/qi-services", { orderId });

          return {
            orderId,
            status,
          };
        }

        throw new Error(`Payment failed: Invalid payment status: ${status}`);
      } catch (err) {
        console.error("Error in booking or payment process:", err);
        throw err;
      }
    },
    onSuccess,
    onError: (error) => {
      Sentry.captureException(error);
      my?.showToast({
        type: "exception",
        content: t("hotels.failed"),
        duration: 3000,
      });
    },
  });
};
