import { Checkbox, Link } from "@mui/material";
import { useTranslations } from "use-intl";
import { useId } from "react";

const Summary = ({
  totalPrice,
  termsAndConditions,
  onTermsChange,
  onMoreDetails,
}: {
  totalPrice: string;
  termsAndConditions: boolean;
  onTermsChange: (checked: boolean) => void;
  onMoreDetails: () => void;
}) => {
  const t = useTranslations();
  const checkboxId = useId();

  return (
    <>
      <div className="section-title mt-4">{t("summary")}</div>

      <div className="section-card mt-2 flex gap-2 text-sm">
        <div className="shrink-0">
          <Checkbox
            id={checkboxId}
            checked={termsAndConditions}
            onChange={(e, checked) => {
              onTermsChange(checked);
            }}
            sx={{ p: 0 }}
          />
        </div>

        <div className="flex flex-col gap-1 pt-1">
          <label
            className="text-primary text-sm font-semibold"
            htmlFor={checkboxId}
          >
            {t("flights.acceptGeneralTerms")}
          </label>
          <div className="text-tertiary text-sm">
            {t("flights.acceptGeneralTermsWarning")}
          </div>
          <Link onClick={() => onMoreDetails()} sx={{ fontSize: "14px" }}>
            {t("moreDetails")}
          </Link>
        </div>
      </div>

      <div className="section-card mt-2 flex flex-col gap-2 text-sm">
        <div className="flex justify-between">
          <div className="text-tertiary">{t("total")}</div>
          <div className="text-primary font-bold">{totalPrice}</div>
        </div>
        <div className="flex justify-between">
          <div className="text-tertiary">{t("paymentMethod")}</div>
          <div className="text-primary font-bold">{t("qiCard")}</div>
        </div>
      </div>
    </>
  );
};

export default Summary;
