import { useTranslations } from "use-intl";

import { ConfirmDrawer } from "../ConfirmDrawer";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function TravellerCloseConfirmDrawer({
  open,
  onClose,
  onConfirm,
}: Props) {
  const t = useTranslations();

  return (
    <ConfirmDrawer
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={t("travellers.closeConfirmation.title")}
      description={t("travellers.closeConfirmation.description")}
      closeLabel={t("back")}
      confirmLabel={t("travellers.closeConfirmation.leave")}
    />
  );
}
