import { useTranslations } from "use-intl";
import { Chip, Divider, Avatar, Button, CircularProgress } from "@mui/material";
import { Alarm } from "@phosphor-icons/react";
import type { Stop } from "@/app/lib/types/interfaces";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import type { GetFlightAvailabilityTokenFlight } from "@/app/lib/types/codegen";
import TravellersListSummary from "./TravellersListSummary";
import { ReservationDocumentList } from "../ReservationDocumentList";
import { flightReservationQueryOptions } from "@/app/lib/hooks/useReservation";
import { SingleFightDetailsData } from "@/app/lib/context/SingleFlightDetailsContext";
import FlightArrow from "../FlightArrow";
import { FlightInfo } from "../flights/FlightInfo";
import { useOrder } from "@/app/lib/hooks/useOrder";
import { Traveller } from "@/app/lib/types/types";

function FlightBookSuccess({
  departureStops,
  selectedTravellers,
  departureFlight,
  returnStops,
  returnFlight,
  singleFlightDetails,
  orderId,
}: {
  departureStops: Stop[];
  selectedTravellers: Traveller[];
  returnStops: Stop[];
  departureFlight: GetFlightAvailabilityTokenFlight | null;
  returnFlight: GetFlightAvailabilityTokenFlight | null;
  singleFlightDetails: SingleFightDetailsData | null;
  orderId: string;
}) {
  const t = useTranslations();
  const navigate = useNavigate();

  const { data: orderData, isLoading: isOrderLoading } = useOrder(orderId);
  const { data: reservationData, isLoading: isReservationLoading } = useQuery(
    flightReservationQueryOptions(orderData?.bookingId),
  );

  const isLoading =
    isOrderLoading || !orderData?.bookingId || isReservationLoading;

  const baggage =
    singleFlightDetails?.optionalServices?.filter(
      (service) =>
        (service.included && service.service.categoryCode === "BG") ||
        service.service.categoryCode === "CY",
    ) || [];

  return (
    <div className="flex w-full flex-col items-center gap-4 px-4">
      <h5 className="mt-6 mb-10 text-xl font-semibold text-black">
        {t("flights.flightBooked")}
      </h5>
      <div className="section-card flex w-full items-center justify-between gap-4">
        <span className="section-title mb-0">{t("flights.ticketNumber")}</span>
        <h3 className="text-title text-base font-semibold">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            reservationData?.reference?.external
          )}
        </h3>
      </div>

      <ReservationDocumentList bookingId={orderData?.bookingId} />

      <div className="section-card flex w-full flex-col gap-4">
        {departureFlight && (
          <span className="section-title m-0">{t("flights.departure")}</span>
        )}
        {departureFlight &&
          departureFlight.segments.map((segment, index) => (
            <div
              key={index}
              className="flex w-full flex-col items-center gap-2"
            >
              <div className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                  <Avatar
                    alt="airplane company logo"
                    sx={{
                      width: 16,
                      height: 16,
                      fontSize: "12px",
                    }}
                    src={segment.marketingCarrier.airline.logoUrl}
                  >
                    {segment.marketingCarrier.airline.name?.split("")[0]}
                  </Avatar>
                  <span className="text-primary text-xs">
                    {segment.marketingCarrier.airline.name ||
                      t("unknownAirline")}
                  </span>
                </div>
                <span className="text-tertiary text-xs">
                  {segment.equipment.name || segment.equipment.code}{" "}
                  {segment.marketingCarrier.flightNumber}
                </span>
              </div>

              <FlightInfo
                origin={{
                  date: segment.departure.date,
                  time: segment.departure.time,
                  code: segment.departure.airport.code,
                }}
                destination={{
                  date: segment.arrival.date,
                  time: segment.arrival.time,
                  code: segment.arrival.airport.code,
                }}
              >
                <FlightArrow overallTime={segment.duration} />
              </FlightInfo>

              {departureStops.length > 0 && departureStops[index]?.stopTime && (
                <Chip
                  icon={<Alarm size={16} />}
                  className="mx-auto"
                  color="primary"
                  label={t("flights.flightStop", {
                    hours: departureStops[index].stopTime.split(":")[0],
                    minutes: departureStops[index].stopTime.split(":")[1],
                    cityName: segment.arrival.city.name,
                    code: segment.arrival.airport.code,
                  })}
                />
              )}
            </div>
          ))}
        {returnFlight && (
          <span className="section-title m-0">
            {t("flights.search.return")}
          </span>
        )}
        {returnFlight &&
          returnFlight.segments.map((segment, index) => (
            <div
              key={index}
              className="flex w-full flex-col items-center gap-4"
            >
              <div className="flex w-full justify-between">
                <div className="flex items-center gap-2">
                  <Avatar
                    alt="airplane company logo"
                    sx={{
                      width: 16,
                      height: 16,
                      fontSize: "12px",
                    }}
                    src={segment.marketingCarrier.airline.logoUrl}
                  >
                    {segment.marketingCarrier.airline.name?.split("")[0]}
                  </Avatar>
                  <span className="text-primary text-xs">
                    {segment.marketingCarrier.airline.name ||
                      t("unknownAirline")}
                  </span>
                </div>
                <span className="text-tertiary text-xs">
                  {segment.equipment.name || segment.equipment.code}{" "}
                  {segment.marketingCarrier.flightNumber}
                </span>
              </div>

              <FlightInfo
                origin={{
                  date: segment.departure.date,
                  time: segment.departure.time,
                  code: segment.departure.airport.code,
                }}
                destination={{
                  date: segment.arrival.date,
                  time: segment.arrival.time,
                  code: segment.arrival.airport.code,
                }}
              >
                <FlightArrow overallTime={segment.duration} />
              </FlightInfo>

              {returnStops.length > 0 && returnStops[index]?.stopTime && (
                <Chip
                  icon={<Alarm size={16} />}
                  className="mx-auto"
                  color="primary"
                  label={t("flights.flightStop", {
                    hours: returnStops[index].stopTime.split(":")[0],
                    minutes: returnStops[index].stopTime.split(":")[1],
                    cityName: segment.arrival.city.name,
                    code: segment.arrival.airport.code,
                  })}
                />
              )}
            </div>
          ))}
        <Divider sx={{ margin: "1.5rem 0" }} />
        <TravellersListSummary
          selectedTravellers={selectedTravellers}
          baggage={baggage}
        />
      </div>

      <div>
        <div className="section-title">{t("reservations.whatsNext")}</div>
        <div className="section-card">
          <div className="flex flex-col gap-2">
            <h5 className="text-primary text-sm font-semibold">
              {t("flights.nextSteps")}
            </h5>
            <span className="text-primary block text-sm font-normal">
              {t("flights.nextStepsDescription")}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-auto mb-40 flex flex-col gap-2">
        <Button
          onClick={() => {
            navigate({ to: "/", search: { category: "flights" } });
          }}
          variant="contained"
          size="small"
          sx={{
            width: "240px",
            marginTop: "24px",
          }}
        >
          {t("backToHome")}
        </Button>
        <Button
          onClick={() => {
            navigate({ to: "/trips" });
          }}
          variant="text"
          size="small"
          sx={{
            width: "240px",
          }}
        >
          {t("openMyTrips")}
        </Button>
      </div>
    </div>
  );
}

export default FlightBookSuccess;
