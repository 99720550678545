import { FormEvent, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import dayjs from "dayjs";
import { matchIsValidTel, MuiTelInputCountry } from "mui-tel-input";
import {
  Button,
  IconButton,
  MenuItem,
  SwipeableDrawer,
  TextField,
  Modal,
} from "@mui/material";
import {
  CaretDown,
  CircleNotch,
  X,
  Image,
  Camera,
  Warning,
} from "@phosphor-icons/react";
import { string, object, date } from "yup";
import { useTranslations } from "use-intl";
import { AxiosError, isAxiosError } from "axios";

import { Puller, TelInput } from "@/app/ui/StyledTz";
import { Calendar } from "@/app/ui/Calendar";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import type { ValidationExceptionOutputDto } from "@/app/lib/types/codegen";
import { Traveller } from "@/app/lib/types/types";
import DocumentAutoCapture, {
  Props as DocumentAutoCaptureProps,
} from "@/app/ui/DocumentAutoCapture";
import { useDocumentScanning } from "../../lib/hooks/useDocumentScanning";
import { getQiToken } from "@/app/lib/api/api";

const telInputCountries: MuiTelInputCountry[] = ["IQ"];

const titleOptions = ["mr", "mrs", "miss"];

type CalendarType = "issueDate" | "dateOfBirth" | "expiryDate";

const nameMinLength = 2;
const nameMaxLength = 30;

type Props = {
  traveller: Traveller | null;
  onSubmit: (traveller: Traveller) => Promise<unknown>;
  onClose: (reason: "cancel" | "cancelWithPendingChanges" | "submit") => void;
};

async function imageUrlToBase64(url: string) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();

    return await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Failed to convert image to base64:", error);
    throw error;
  }
}

function TravellerForm({ traveller, onSubmit, onClose }: Props) {
  const qi = getQiToken();
  const t = useTranslations();
  const showSnackbar = useSnackbar();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [triedSubmitting, setTriedSubmitting] = useState(false);

  const [scanned, setScanned] = useState(false);
  const [error, setError] = useState(false);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  const [calendarType, setCalendarType] = useState<CalendarType | undefined>();

  const formik = useFormik<Traveller>({
    enableReinitialize: true,
    initialValues: traveller
      ? {
          ...traveller,
          phoneNumber: traveller.phonePrefix + traveller.phoneNumber,
        }
      : {
          id: "",
          title: "mr",
          firstName: "",
          lastName: "",
          birthDate: "",
          passportNumber: "",
          phonePrefix: "+964",
          passportIssuingDate: "",
          passportExpiryDate: "",
          emailAddress: undefined,
          phoneNumber: "",
          passportIssuingCountry: "IQ",
          passportNationality: "IQ",
        },
    validationSchema: object().shape({
      firstName: string()
        .required(t("travellers.validation.nameRequired"))
        .matches(/^[a-zA-Z -]*$/, t("travellers.validation.nameNotValid"))
        .min(
          nameMinLength,
          t("travellers.validation.nameLengthNotValid", {
            min: nameMinLength,
            max: nameMaxLength,
          }),
        )
        .max(
          nameMaxLength,
          t("travellers.validation.nameLengthNotValid", {
            min: nameMinLength,
            max: nameMaxLength,
          }),
        ),
      lastName: string()
        .required(t("travellers.validation.surnameRequired"))
        .matches(/^[a-zA-Z -]*$/, t("travellers.validation.surnameNotValid"))
        .min(
          nameMinLength,
          t("travellers.validation.surnameLengthNotValid", {
            min: nameMinLength,
            max: nameMaxLength,
          }),
        )
        .max(
          nameMaxLength,
          t("travellers.validation.surnameLengthNotValid", {
            min: nameMinLength,
            max: nameMaxLength,
          }),
        ),
      birthDate: date().required(t("travellers.validation.birthDate")),
      passportNumber: string()
        .required(t("travellers.validation.passportId"))
        .matches(/^[a-zA-Z0-9]{7,9}$/, t("travellers.validation.passportId")),
      emailAddress: string()
        .required(t("travellers.validation.emailRequired"))
        .email(t("travellers.validation.emailNotValid")),
      passportIssuingDate: date().required(
        t("travellers.validation.issueDate"),
      ),
      passportExpiryDate: date().required(
        t("travellers.validation.expiryDate"),
      ),
      title: string().required(t("travellers.validation.title")),
      phoneNumber: string()
        .required(t("travellers.validation.phone"))
        .test("is-phone-number", t("travellers.validation.phone"), (value) =>
          matchIsValidTel(value, {
            onlyCountries: telInputCountries,
          }),
        ),
    }),
    onSubmit: (values, formikHelpers) =>
      onSubmit({
        ...values,
        phoneNumber: values.phoneNumber.replace(values.phonePrefix, ""),
        emailAddress: values.emailAddress || undefined,
      })
        .then(() => {
          onClose("submit");
        })
        .catch((err) => {
          const isValidationError = (
            error: unknown,
          ): error is AxiosError<ValidationExceptionOutputDto> => {
            return (
              isAxiosError(error) &&
              error.response?.data?.code === "VALIDATION_FAILED"
            );
          };

          if (!isValidationError(err) || !err.response) {
            throw err;
          }

          const errorMessages = err.response.data.data.reduce(
            (acc, propertyError) => {
              return {
                ...acc,
                [propertyError.property]: propertyError.message,
              };
            },
            {} as FormikErrors<Traveller>,
          );

          // mark fields with errors
          formikHelpers.setErrors(errorMessages);

          // show snackbar with first error, as we don't show errors next to fields
          const errorsToShow = getFormikErrorsRecursive(errorMessages);
          if (errorsToShow.length > 0) {
            showSnackbar(errorsToShow[0]);
          }
        }),
  });

  const handleDocumentPhotoError = () => {
    setScanned(true);
    setError(true);
    showSnackbar(t("travellers.scanError"));
    setIsCameraOpen(false);
    setLoading(false);
  };
  const handleDocumentPhotoTaken: DocumentAutoCaptureProps["onPhotoTaken"] = (
    response,
  ) => {
    const { data } = response || {};
    if (!data) {
      handleDocumentPhotoError();
      return;
    }

    setScanned(true);
    formik.setValues({
      ...formik.values,
      id: "",
      title: data.gender === "M" ? "mr" : "mrs",
      firstName: data.givenName || "",
      lastName: data.surName || "",
      birthDate: data.dateOfBirth || "",
      passportNumber: data.passportNumber || "",
      phonePrefix: "+964",
      passportIssuingDate: data.dateOfIssue || "",
      passportExpiryDate: data.dateOfExpiry || "",
      emailAddress: undefined,
      phoneNumber: "",
      passportIssuingCountry: "IQ",
      passportNationality: "IQ",
    });
    showSnackbar(t("travellers.scanSuccess"));
    setError(false);
    setIsCameraOpen(false);
    setLoading(false);
  };

  const { mutateAsync } = useDocumentScanning({
    onSuccess: handleDocumentPhotoTaken,
    onError: handleDocumentPhotoError,
  });

  const handleImageUpload = (imageUrl: string) => {
    if (!imageUrl) return;
    setLoading(true);

    imageUrlToBase64(imageUrl).then((base64data) => {
      const base64 = (base64data as string)?.split("base64,")[1];
      if (base64) {
        mutateAsync({
          frontDoc: base64,
        });
      }
    });
  };

  const showError = (field: keyof Traveller) => {
    const touched = formik.touched;
    const errors = formik.errors;
    return (triedSubmitting || touched[field]) && !!errors[field];
  };
  const handleDrawerOpen = (type: CalendarType) => {
    setDrawerOpen(true);
    setCalendarType(type);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (formik.isSubmitting) {
      return;
    }
    formik.validateForm().then((res) => {
      const errors = getFormikErrorsRecursive(res);
      if (errors.length > 0) {
        setTriedSubmitting(true);
        showSnackbar(errors[0]);
      } else {
        formik.handleSubmit();
      }
    });
  };

  return loading ? (
    <div className="flex flex-1 items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <div className="relative flex h-24 w-24 items-center justify-center rounded-full bg-green-200 p-6">
          <div className="absolute inset-0 animate-spin rounded-full border-4 border-t-4 border-green-300 border-t-green-800" />

          <img src={"/scan.svg"} width={40} height={40} alt="scan" />
        </div>
        <div className="mt-2 text-black">
          {t("travellers.scanningInProgress")}
        </div>
      </div>
    </div>
  ) : (
    <>
      {isCameraOpen && (
        <Modal open>
          <div className="fixed h-full w-full bg-white">
            <DocumentAutoCapture
              onPhotoTaken={handleDocumentPhotoTaken}
              onError={handleDocumentPhotoError}
              onClose={() => setIsCameraOpen(false)}
            />
          </div>
        </Modal>
      )}

      <div className="mt-5 mb-3 flex w-full flex-row items-center justify-center px-4 text-center">
        <div className="absolute start-4">
          <IconButton
            onClick={() =>
              onClose(formik.dirty ? "cancelWithPendingChanges" : "cancel")
            }
          >
            <X size={20} className="fill-interactive" />
          </IconButton>
        </div>

        <h5 className="text-title text-center text-base leading-[1.625rem] font-medium">
          {traveller ? t("travellers.edit") : t("travellers.add")}
        </h5>
      </div>
      <div className="mb-6 flex flex-col justify-center rounded-xl bg-[#E5FFE4] p-4">
        <div>
          <div className="text-base font-medium text-black">
            {t("travellers.scanTitle")}
          </div>
          <div className="text-tertiary mb-4 text-sm">
            {t("travellers.fillIn")}
          </div>
        </div>
        <button
          className="text-accent-600 flex items-center justify-center rounded-full border-1 border-black bg-[#E5FFE4] px-6 py-2"
          onClick={() => {
            setIsCameraOpen(true);
            setScanned(false);
          }}
        >
          <span className="text-sm font-bold">{t("travellers.scan")}</span>
          <Camera size={24} alt="scan" className="ms-1.5" />
        </button>
        <button
          className="text-accent-600 mt-4 flex items-center justify-center rounded-full border-1 border-black bg-[#E5FFE4] px-6 py-2"
          onClick={() => {
            my?.chooseImage({
              success: (res) => {
                if (res && res.apFilePaths && res.apFilePaths.length > 0) {
                  handleImageUpload(res.apFilePaths[0]);
                }
              },
            });
          }}
        >
          <span className="text-sm font-bold">
            {t("travellers.uploadImage")}
          </span>
          <Image size={24} alt="upload" className="ms-1.5" />
        </button>
      </div>
      {scanned && !error && (
        <div className="text-primary mb-4 flex h-40 w-full justify-between rounded-xl border-1 border-yellow-500 bg-[#FDFAF2] p-3 text-sm font-medium">
          {t("travellers.doubleCheck")} <Warning size={24} color="#DDA822" />
        </div>
      )}
      <form
        className="relative flex h-full flex-col gap-2"
        noValidate
        onSubmit={handleSubmit}
        acceptCharset="ISO-8859-1"
      >
        {!(scanned && !error) && (
          <span className="text-primary mb-4 flex w-full justify-between text-sm font-medium">
            {t("travellers.info")}
          </span>
        )}
        <div className="flex gap-2">
          <TextField
            select
            fullWidth
            id="title"
            name="title"
            label={t("travellers.passengerTitle")}
            value={formik.values.title}
            error={showError("title")}
            InputProps={{
              endAdornment: (
                <CaretDown className="absolute end-5" color="black" size={24} />
              ),
            }}
            onChange={(e) => formik.setFieldValue("title", e.target.value)}
          >
            {titleOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {t(`travellers.titleOptions.${option}`)}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            style={{ minWidth: "70%" }}
            name="firstName"
            label={t("travellers.name")}
            value={formik.values.firstName}
            error={showError("firstName")}
            onChange={formik.handleChange}
            autoComplete="off"
          />
        </div>
        <TextField
          fullWidth
          name="lastName"
          label={t("travellers.lastName")}
          value={formik.values.lastName}
          error={showError("lastName")}
          onChange={formik.handleChange}
          autoComplete="off"
        />
        <TextField
          fullWidth
          name="dateOfBirth"
          label={t("travellers.dateOfBirth")}
          value={
            formik.values.birthDate
              ? dayjs(formik.values.birthDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("birthDate")}
          onMouseDown={() => {
            handleDrawerOpen("dateOfBirth");
          }}
          autoComplete="off"
        />
        <TextField
          fullWidth
          name="passportNumber"
          label={t("travellers.passportId")}
          value={formik.values.passportNumber}
          error={showError("passportNumber")}
          onChange={formik.handleChange}
          autoComplete="off"
        />
        <TextField
          fullWidth
          name="passportIssuingDate"
          label={t("travellers.passportIssueDate")}
          value={
            formik.values.passportIssuingDate
              ? dayjs(formik.values.passportIssuingDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("passportIssuingDate")}
          onMouseDown={() => {
            handleDrawerOpen("issueDate");
          }}
          autoComplete="off"
        />
        <TextField
          fullWidth
          name="passportExpiryDate"
          label={t("travellers.passportExpiryDate")}
          value={
            formik.values.passportExpiryDate
              ? dayjs(formik.values.passportExpiryDate).format("YYYY-MM-DD")
              : ""
          }
          error={showError("passportExpiryDate")}
          onMouseDown={() => {
            handleDrawerOpen("expiryDate");
          }}
          autoComplete="off"
        />
        <TextField
          fullWidth
          name="emailAddress"
          label={t("travellers.email")}
          value={formik.values.emailAddress}
          error={showError("emailAddress")}
          onChange={formik.handleChange}
          autoComplete="off"
          type="email"
        />
        <TelInput
          fullWidth
          value={formik.values.phoneNumber}
          error={showError("phoneNumber")}
          onChange={(event) => {
            let phoneNumber = event.replace(/\D/g, "");
            if (!phoneNumber.startsWith("964")) {
              phoneNumber = "964";
            }
            phoneNumber = "+" + phoneNumber;
            formik.setFieldValue("phoneNumber", phoneNumber);
          }}
          onlyCountries={telInputCountries}
          disableFormatting
          disableDropdown
          name="phoneNumber"
          label={t("travellers.phoneNumber")}
          autoComplete="off"
          variant="filled"
          defaultCountry={telInputCountries[0]}
        />

        <Button
          fullWidth
          variant="contained"
          type="submit"
          sx={{
            marginTop: "auto",
          }}
        >
          <span className="flex items-center gap-2">
            <span>{t("saveChanges")}</span>
            {formik.isSubmitting ? (
              <CircleNotch className="animate-spin" size={24} />
            ) : null}
          </span>
        </Button>
      </form>
      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            paddingBottom: "env(safe-area-inset-bottom)",
            height: !drawerOpen ? "auto" : qi ? "90vh" : "100vh",
          },
        }}
      >
        {drawerOpen && (
          <div className="flex h-full flex-col p-4 pt-0">
            <div className="flex items-center justify-center pb-4">
              <Puller />
            </div>
            <Calendar
              dropdown
              setFieldValue={formik.setFieldValue}
              {...(calendarType === "issueDate"
                ? {
                    startField: {
                      label: t("travellers.passportIssueDate"),
                      value: formik.values.passportIssuingDate,
                      path: "passportIssuingDate",
                    },
                    disabledDates: {
                      after: new Date(),
                    },
                    endMonth: new Date(),
                  }
                : calendarType === "dateOfBirth"
                  ? {
                      startField: {
                        label: t("travellers.dateOfBirth"),
                        value: formik.values.birthDate,
                        path: "birthDate",
                      },
                      disabledDates: {
                        after: new Date(),
                      },
                      endMonth: new Date(),
                    }
                  : {
                      startField: {
                        label: t("travellers.passportExpiryDate"),
                        value: formik.values.passportExpiryDate,
                        path: "passportExpiryDate",
                      },
                      disabledDates: {
                        before: new Date(),
                      },
                      startMonth: new Date(),
                    })}
            />
            <Button
              fullWidth
              variant="contained"
              type="submit"
              sx={{
                marginTop: "auto",
                position: "sticky",
                bottom: "12px",
              }}
              onClick={() => setDrawerOpen(false)}
            >
              {t("flights.done")}
            </Button>
          </div>
        )}
      </SwipeableDrawer>
    </>
  );
}

export default TravellerForm;
