"use client";

import { Slider } from "@mui/material";
import { useTranslations } from "use-intl";

import { CurrencyInput } from "./CurrencyInput";
import { formatCurrency } from "../lib/utils/currency";

type Props = {
  min: number;
  max: number;
  value: [number, number];
  onChange: (value: [number, number]) => void;
};

const PriceRangeSlider = ({ min, max, value, onChange, ...props }: Props) => {
  const t = useTranslations();

  return (
    <>
      <div className="mx-1">
        <Slider
          {...props}
          min={min}
          max={max}
          onChange={(event, newValue) => {
            onChange(newValue as [number, number]);
          }}
          value={value}
          valueLabelFormat={(value) =>
            formatCurrency(value, "IQD", { maximumFractionDigits: 0 })
          }
          valueLabelDisplay="auto"
        />
      </div>
      <div className="flex gap-6">
        <CurrencyInput
          min={min}
          max={value[1]}
          label={t("minPrice")}
          value={value[0]}
          onChange={(newMin) => {
            const maxValue = value[1];
            onChange([newMin, maxValue]);
          }}
        />

        <CurrencyInput
          min={value[0]}
          max={max}
          label={t("maxPrice")}
          value={value[1]}
          onChange={(newMax) => {
            const minValue = value[0];
            onChange([minValue, newMax]);
          }}
        />
      </div>
    </>
  );
};

export default PriceRangeSlider;
