import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { HotelOfferSummary } from "@/app/ui/hotel/HotelOfferSummary";
import { PersistentDrawer } from "@/app/ui/PersistentDrawer";
import { Button } from "@mui/material";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import { useTranslations } from "use-intl";
import * as Sentry from "@sentry/browser";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import PageLoader from "@/app/ui/PageLoader";
import AnimatedLoader from "@/app/ui/AnimatedLoader";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import TravellersList from "@/app/ui/travellers/TravellersList";
import { getTravelerTypeOfAge } from "@/app/lib/utils/transformTraveler";

export const Route = createFileRoute(
  "/_authenticated/_hotels/_hotel-offer/hotels/$id/travelers",
)({
  component: RouteComponent,
  staticData: {
    hotelStep: { stepNumber: 2 },
    drawer: true,
  },
});

function RouteComponent() {
  const { getSelectedTravellers, selectedTravellersByAge } =
    useTravellersContext();
  const { availabilityToken } = useHotelBookingContext();
  const { searchData } = useHotelSearchDataContext();
  const t = useTranslations();
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  if (!searchData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    Sentry.captureMessage(
      "User tried to access the travellers page without search data",
      "fatal",
    );
    return <Navigate to="/hotels/$id/rooms" params={params} />;
  }

  if (!availabilityToken) {
    return (
      <PageLoader>
        <AnimatedLoader icon="buildings" />
        <h3 className="text-primary mt-3 font-normal">
          {t("hotels.checkingRoomAvailability")}
        </h3>
      </PageLoader>
    );
  }

  const travellersToSelectByAge = searchData.rooms.reduce(
    (acc, room) => {
      const childrenByAge = room.childrenAges.reduce(
        (acc, age) => {
          const type = getTravelerTypeOfAge(Number(age));
          if (type === "child") {
            return {
              ...acc,
              children: acc.children + 1,
            };
          }

          if (type === "infant") {
            return {
              ...acc,
              infants: acc.infants + 1,
            };
          }

          return acc;
        },
        {
          children: 0,
          infants: 0,
        },
      );

      return {
        adults: acc.adults + room.adults,
        children: acc.children + childrenByAge.children,
        infants: acc.infants + childrenByAge.infants,
      };
    },
    {
      adults: 0,
      children: 0,
      infants: 0,
    },
  );

  return (
    <>
      <TravellersList
        adultsToSelect={travellersToSelectByAge.adults}
        childrenToSelect={travellersToSelectByAge.children}
        infantsToSelect={travellersToSelectByAge.infants}
        travelDate={new Date(searchData.dates.checkIn)}
      />
      <PersistentDrawer>
        <HotelOfferSummary />
        <Button
          fullWidth
          variant="contained"
          disabled={
            // ensure that the user has selected anything
            getSelectedTravellers.length === 0 ||
            // ensure that the user has selected all the travellers
            selectedTravellersByAge.adults !== travellersToSelectByAge.adults ||
            selectedTravellersByAge.children !==
              travellersToSelectByAge.children ||
            selectedTravellersByAge.infants !== travellersToSelectByAge.infants
          }
          onClick={() => {
            navigate({ to: "/hotels/$id/summary" });
          }}
        >
          {t("hotels.continue")}
        </Button>
      </PersistentDrawer>
    </>
  );
}
