import { Airplane, Buildings, Users } from "@phosphor-icons/react";

const iconMap = {
  airplane: Airplane,
  buildings: Buildings,
  users: Users,
};

type IconName = keyof typeof iconMap;

function Loader({ icon = "airplane" }: { icon?: IconName }) {
  const Icon = iconMap[icon] || Airplane;

  return (
    <div className="relative flex h-[68px] w-[68px] flex-col items-center justify-center">
      <img
        src="/tzarrow.svg"
        className="arrow top-arrow"
        alt="top-arrow"
        width={15}
        height={15}
      />
      <img
        src="/tzarrow.svg"
        className="arrow bottom-arrow"
        alt="bottom-arrow"
        width={15}
        height={15}
      />
      <Icon size={40} color="#003E2B" className="absolute z-10" />
    </div>
  );
}

export default Loader;
