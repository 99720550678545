import FlightSummary from "@/app/ui/flight-purchase/FlightSummary";
import TravellersListSummary from "@/app/ui/flight-purchase/TravellersListSummary";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useSingleFlightSelectionContext } from "@/app/lib/context/SingleFlightDetailsContext";
import { createFileRoute } from "@tanstack/react-router";
import Summary from "@/app/ui/flight-purchase/Summary";
import { useState } from "react";
import BottomDrawer from "@/app/ui/flight-purchase/BottomDrawer";
import MoreDetailsDrawer from "@/app/ui/flight-purchase/MoreDetailsDrawer";
import AnimatedLoader from "@/app/ui/AnimatedLoader";
import PageLoader from "@/app/ui/PageLoader";
import { useFlightSelectionPayloadContext } from "@/app/lib/context/FlightSelectionPayloadContext";
import { useFlightBooking } from "@/app/lib/hooks/useFlightBooking";
import { transformFlightTraveler } from "@/app/lib/utils/transformTraveler";
import { useTranslations } from "use-intl";
import { usePurchaseStateContext } from "@/app/lib/context/PurchaseStateContext";
import { BookingError } from "@/app/ui/errors/BookingError";
import { PurchaseStatus } from "@/app/lib/types/types";

export const Route = createFileRoute(
  "/_authenticated/_flights/_flight-offer/flights/offer/$id/step/3",
)({
  component: RouteComponent,
  staticData: {
    flightStep: { stepNumber: 3, stepTitle: "bookingConfirmation.title" },
    drawer: true,
  },
});

function RouteComponent() {
  const t = useTranslations();
  const navigate = Route.useNavigate();

  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  const { updatePurchaseState, purchaseState } = usePurchaseStateContext();
  const { singleFlightDetails, isAdditionalDataLoading } =
    useSingleFlightSelectionContext();
  const { getSelectedTravellers, isReady: isTravelersReady } =
    useTravellersContext();
  const { selectedFlightPayload } = useFlightSelectionPayloadContext();
  const { isPending, isError, error, mutateAsync } = useFlightBooking({
    onSuccess: ({ orderId, status }) => {
      if (status === PurchaseStatus.SUCCESSFUL) {
        navigate({
          to: "/flights/offer/$id/step/final",
          search: { orderId },
        }).then(() => {
          updatePurchaseState({ wasPurchaseDone: true });
        });
      }
    },
  });

  if (isError) {
    return <BookingError error={error} type="flights" />;
  }

  if (isPending) {
    return (
      <div className="h-96">
        <PageLoader>
          <AnimatedLoader icon="airplane" />
          <h3 className="text-primary mt-3 font-normal">
            {t("flights.bookingFlight")}
          </h3>
        </PageLoader>
      </div>
    );
  }

  const baggage =
    singleFlightDetails.optionalServices?.filter(
      (service) =>
        (service.included && service.service.categoryCode === "BG") ||
        service.service.categoryCode === "CY",
    ) || [];

  return (
    <>
      <div className="px-4">
        <FlightSummary selectedFlight={singleFlightDetails} />

        {isTravelersReady && getSelectedTravellers.length > 0 && (
          <>
            <div className="section-title mt-4">
              {t("travellers.travellersList")}
            </div>
            <div className="section-card">
              <TravellersListSummary
                selectedTravellers={getSelectedTravellers}
                baggage={baggage}
              />
            </div>
          </>
        )}

        <Summary
          totalPrice={singleFlightDetails.totalPrice}
          termsAndConditions={termsAndConditions}
          onTermsChange={setTermsAndConditions}
          onMoreDetails={() => {
            setMoreDetailsOpen(true);
          }}
        />
      </div>

      <MoreDetailsDrawer
        open={moreDetailsOpen}
        onClose={() => setMoreDetailsOpen(false)}
      />

      <BottomDrawer
        totalPrice={singleFlightDetails.totalPrice}
        buttonLabel="flights.purchaseTicket"
        onButtonClick={() => {
          if (selectedFlightPayload && !purchaseState.wasPurchaseDone) {
            mutateAsync({
              offerId: selectedFlightPayload?.offerId,
              searchResultKey: selectedFlightPayload?.searchResultKey,
              searchResultToken: selectedFlightPayload?.searchResultToken,
              itineraryIndex: selectedFlightPayload?.itineraryIndex,
              availabilityToken: singleFlightDetails.availabilityToken,
              travelers: getSelectedTravellers.map((traveller) =>
                transformFlightTraveler(traveller),
              ),
            });
          }
        }}
        disabled={!termsAndConditions || isAdditionalDataLoading}
      />
    </>
  );
}
