import { createContext, useContext } from "react";
import { UseQueryResult } from "@tanstack/react-query";

import { InitiateFlightsSearchAirline } from "../../types/codegen";

type FlightsTokensContextValue = UseQueryResult<{
  maxPrice: number;
  minPrice: number;
  searchResultKey: string;
  searchResultToken: string;
  carriers: string[];
  airlines: InitiateFlightsSearchAirline[];
}>;

export const FlightsTokensContext =
  createContext<FlightsTokensContextValue | null>(null);

export const useFlightsTokensContext = () => {
  const context = useContext(FlightsTokensContext);
  if (!context) {
    throw new Error(
      "useFlightsTokensContext must be used within a FlightsTokensContextProvider",
    );
  }
  return context;
};
