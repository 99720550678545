import { ReactNode } from "react";
import { Button } from "@mui/material";

import { Drawer } from "./Drawer";

type Props = {
  title: ReactNode;
  description?: ReactNode;
  closeLabel: ReactNode;
  confirmLabel: ReactNode;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export function ConfirmDrawer({
  title,
  description,
  closeLabel,
  confirmLabel,
  open,
  onClose,
  onConfirm,
}: Props) {
  return (
    <Drawer open={open}>
      <div className="flex h-full w-full flex-col items-center">
        <h5 className="text-title mt-2 mb-3 text-base leading-6 font-medium">
          {title}
        </h5>
        {description ? (
          <span className="text-primary text-sm font-normal">
            {description}
          </span>
        ) : null}
        <div className="mt-10 flex w-full flex-col gap-2">
          <Button
            onClick={onConfirm}
            sx={{
              boxShadow: "none",
            }}
            variant="contained"
            color="error"
          >
            {confirmLabel}
          </Button>
          <Button
            onClick={onClose}
            sx={{
              color: "#222930",
            }}
            variant="text"
          >
            {closeLabel}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
