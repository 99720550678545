import { useTranslations } from "use-intl";

interface ReservationNextStepsProps {
  type: "flight" | "hotel";
}

export const ReservationNextSteps = ({ type }: ReservationNextStepsProps) => {
  const t = useTranslations();

  return (
    <section className="mt-4">
      <h3 className="text-tertiary mb-2 text-xs font-medium">
        {t("reservations.whatsNext")}
      </h3>
      <div className="text-primary w-full items-center justify-between gap-4 space-y-2 rounded-3xl bg-white p-4 text-sm">
        <h3 className="font-semibold">
          {type === "flight"
            ? t("reservations.flight.whatsNextTitle")
            : t("reservations.hotel.whatsNextTitle")}
        </h3>
        <p>
          {type === "flight"
            ? t("reservations.flight.whatsNextDescription")
            : t("reservations.hotel.whatsNextDescription")}{" "}
        </p>
      </div>
    </section>
  );
};
