import { ReactNode, useState } from "react";
import { IconButton, MobileStepper } from "@mui/material";
import { X, ArrowLeft } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";
import dayjs from "dayjs";
import {
  NavigateOptions,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";

import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import CancelHotelBooking from "./CancelHotelBooking";

type HotelBookingHeaderProps = {
  className?: string;
  title?: ReactNode;
  presentationMode?: "modal";
  showStepper?: boolean;
} & (
  | {
      showStepper: true;
      step: number;
    }
  | { showStepper?: false }
);

const STEPS_CONFIG = [
  { title: "hotels.title", prev: { to: "/hotels/list" } },
  { title: "hotels.chooseRoom", prev: { to: "/hotels/$id/details" } },
  { title: "travellers.title", prev: { to: "/hotels/$id/rooms" } },
  { title: "summary", prev: { to: "/hotels/$id/travelers" } },
  { title: "success", prev: { to: "/", search: { category: "hotels" } } },
] satisfies {
  title: string;
  prev?: NavigateOptions;
}[];

export const HotelBookingHeader = ({
  title,
  presentationMode,
  ...props
}: HotelBookingHeaderProps) => {
  const t = useTranslations();

  const navigate = useNavigate();
  const { history } = useRouter();

  const { searchData } = useHotelSearchDataContext();
  const [isCancelConfirmOpen, setIsCancelConfirmOpen] = useState(false);

  return (
    <>
      <div className="mb-4 flex w-full flex-col px-4">
        <div className="align-center flex justify-between">
          {props.showStepper ? (
            <IconButton onClick={() => history.back()} sx={{ padding: 0 }}>
              <ArrowLeft
                size={24}
                color="text-title"
                className="rtl:-scale-x-100"
              />
            </IconButton>
          ) : (
            <div className="size-6" />
          )}
          <div className="flex flex-col items-center justify-center gap-1">
            <div className="flex w-full items-center justify-center">
              {props.showStepper ? (
                <h3 className="text-title font-medium">
                  {t(STEPS_CONFIG[props.step].title)}
                </h3>
              ) : (
                title
              )}
            </div>
            {searchData ? (
              <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
                {dayjs(searchData.dates.checkIn).format("ddd, DD MMM")} -{" "}
                {dayjs(searchData.dates.checkOut).format("ddd, DD MMM")},{" "}
                <span>
                  {searchData.rooms.length > 1
                    ? t("hotels.search.rooms")
                    : t("hotels.search.room")}{" "}
                  {searchData.rooms.length}
                </span>
              </span>
            ) : null}
          </div>
          <IconButton
            onClick={() => {
              if (presentationMode === "modal") {
                navigate({ to: ".." });
              } else {
                setIsCancelConfirmOpen(true);
              }
            }}
            sx={{ padding: 0 }}
          >
            <X size={24} color="text-title" />
          </IconButton>
        </div>
        {props.showStepper ? (
          <>
            <MobileStepper
              variant="progress"
              steps={5}
              position="static"
              // +1 because we're 0-indexed
              activeStep={props.step + 1}
              sx={{
                paddingTop: 2,
              }}
              nextButton={null}
              backButton={null}
            />
            <div className="flex items-center gap-2 text-start">
              <h5 className="text-title text-xs">
                <span className="text-secondary">
                  {t("hotels.step")}&nbsp;{props.step + 1}/
                  {STEPS_CONFIG.length - 1} {title}
                </span>
              </h5>
            </div>
          </>
        ) : null}
      </div>

      <CancelHotelBooking
        open={isCancelConfirmOpen}
        closeDrawer={() => setIsCancelConfirmOpen(false)}
      />
    </>
  );
};
