import { StaticDataRouteOption, useMatches } from "@tanstack/react-router";

export const useRouteConfig = () => {
  const matches = useMatches();
  const routeConfig: StaticDataRouteOption = {};

  for (const match of matches) {
    if (match.staticData.hotelStep !== undefined) {
      routeConfig.hotelStep = match.staticData.hotelStep;
    }
    if (match.staticData.flightStep !== undefined) {
      routeConfig.flightStep = match.staticData.flightStep;
    }
    if (match.staticData.drawer !== undefined) {
      routeConfig.drawer = match.staticData.drawer;
    }
  }

  return routeConfig;
};
