import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";
import { useOptionalHotelDataContext } from "@/app/lib/context/HotelDataContext";
import { useHotelsTokensContext } from "@/app/lib/context/HotelsTokensContext";
import { HotelBookingSummaryForm } from "@/app/ui/hotel/HotelBookingSummaryForm";
import { createFileRoute, Navigate } from "@tanstack/react-router";
import * as Sentry from "@sentry/browser";
import { usePurchaseStateContext } from "@/app/lib/context/PurchaseStateContext";
import { PurchaseStatus } from "@/app/lib/types/types";

export const Route = createFileRoute(
  "/_authenticated/_hotels/_hotel-offer/hotels/$id/summary",
)({
  component: RouteComponent,
  staticData: {
    hotelStep: { stepNumber: 3 },
    drawer: true,
  },
});

function RouteComponent() {
  const navigate = Route.useNavigate();
  const params = Route.useParams();

  const { data } = useHotelsTokensContext();
  const { data: booking, availabilityToken } = useHotelBookingContext();
  const { data: hotel } = useOptionalHotelDataContext();
  const { updatePurchaseState } = usePurchaseStateContext();

  if (
    !availabilityToken ||
    !booking ||
    !hotel ||
    !data?.searchResultKey ||
    !data?.searchResultToken
  ) {
    // If token does not exist at this point, or other required params, redirect to the hotel offer page
    // This is a fallback in case the user tries to access this page directly
    // without going through the hotel offer page
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the HotelBookingSummaryForm component, and other components
    Sentry.captureMessage(
      "User tried to access hotel summary page directly without going through the hotel offer page",
      "fatal",
    );
    return <Navigate to="/hotels/$id/travelers" params={params} />;
  }

  return (
    <HotelBookingSummaryForm
      availabilityToken={availabilityToken}
      searchResultKey={data.searchResultKey}
      searchResultToken={data.searchResultToken}
      hotel={hotel}
      booking={booking}
      onSuccess={({ orderId, status }) => {
        if (status === PurchaseStatus.SUCCESSFUL) {
          navigate({
            to: "/hotels/$id/confirmation",
            search: { orderId },
          }).then(() => {
            updatePurchaseState({ wasPurchaseDone: true });
          });
        }
      }}
    />
  );
}
