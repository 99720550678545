import {
  createFileRoute,
  Navigate,
  Outlet,
  useMatchRoute,
} from "@tanstack/react-router";
import { motion } from "motion/react";
import { getQiToken } from "@/app/lib/api/api";
import clsx from "clsx";
import { useRouteConfig } from "@/app/lib/hooks/useRouteConfig";
import { TravellersProvider } from "@/app/lib/context/TravellersContext";
import * as Sentry from "@sentry/react";
import { usePurchaseStateContext } from "@/app/lib/context/PurchaseStateContext";
import { BookingError } from "@/app/ui/errors/BookingError";

export const Route = createFileRoute("/_authenticated/_hotels/_hotel-offer")({
  component: RouteComponent,
  errorComponent: (props) => {
    Sentry.captureException(props.error);
    return <BookingError {...props} type="hotels" />;
  },
});

function RouteComponent() {
  const routeConfig = useRouteConfig();
  const matches = useMatchRoute();
  const qi = getQiToken();
  const { purchaseState } = usePurchaseStateContext();

  const isLastStepRoute = matches({
    from: "/",
    to: "/hotels/$id/confirmation",
  });

  if (!isLastStepRoute && purchaseState.wasPurchaseDone) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <motion.div
        layoutId="hero"
        className="bg-hero-pattern absolute top-0 z-20 w-full rounded-b-2xl bg-cover bg-no-repeat"
      />
      <main
        className={clsx("no-footer pt-4", {
          "with-drawer": !!routeConfig.drawer,
          main: !qi,
          "main-qi": !!qi,
        })}
      >
        <TravellersProvider>
          <Outlet />
        </TravellersProvider>
      </main>
    </>
  );
}
