"use client";
import { IconProps } from "@phosphor-icons/react";
import { Button, Link as MuiLink } from "@mui/material";
import { useTranslations } from "use-intl";
import { WHATSAPP_URL } from "@/app/lib/routes";
import {
  Link,
  NavigateOptions,
  useNavigate,
  useRouter,
} from "@tanstack/react-router";
import { ReactElement } from "react";

export interface PageErrorProps {
  title?: string;
  subtitles: string[];
  link?: {
    text: string;
    options: NavigateOptions;
  };
  icon?: ReactElement<IconProps>;
  back: {
    text: string;
    options?: NavigateOptions;
  };
  hideWhatsAppButton?: boolean;
}

function PageError({
  back,
  icon,
  title,
  subtitles,
  link,
  hideWhatsAppButton,
}: PageErrorProps) {
  const t = useTranslations();
  const navigate = useNavigate();
  const { history } = useRouter();

  const handleBackButtonClick: React.MouseEventHandler<
    HTMLButtonElement
  > = () => {
    if (back.options) {
      navigate(back.options);
    } else {
      history.back();
    }
  };

  return (
    <div className="flex h-screen w-full flex-col items-center p-10">
      <div className="mt-[30%] flex flex-col items-center gap-4">
        {icon ? icon : null}
        {title ? (
          <span className="text-primary text-center text-xl font-bold">
            {title}
          </span>
        ) : null}
        {subtitles.map((subtitle, idx) => (
          <span key={idx} className="text-primary text-center text-sm">
            {subtitle}
          </span>
        ))}
        {link ? (
          <MuiLink className="mb-4">
            <Link {...link.options}>{link.text}</Link>
          </MuiLink>
        ) : null}
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleBackButtonClick}
        >
          {back.text}
        </Button>
      </div>
      {hideWhatsAppButton ? null : (
        <Button
          onClick={() => {
            window.open(WHATSAPP_URL, "_blank");
          }}
          color="primary"
          variant="text"
          sx={{ marginTop: "auto" }}
        >
          {t("contactWhatsAppSupport")}
        </Button>
      )}
    </div>
  );
}

export default PageError;
