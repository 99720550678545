import { createFileRoute, Navigate, Outlet } from "@tanstack/react-router";

import { useFlightSearchDataContext } from "@/app/lib/context/FlightSearchDataContext";
import { FlightsTokensProvider } from "@/app/lib/context/FlightsTokensContext";
import { PurchaseStateContextProvider } from "@/app/lib/context/PurchaseStateContext";

export const Route = createFileRoute("/_authenticated/_flights")({
  component: RouteComponent,
});

function RouteComponent() {
  const { searchData } = useFlightSearchDataContext();

  if (!searchData) {
    // If the app fails to load the hotel data or to pass search data, redirect to the hotel list page
    // This is a fallback in case the user tries to access this page directly
    // It's unlikely that this will happen, but it's a good fallback to have
    // Avoids the need to handle these cases in the other components
    return <Navigate to="/" search={{ category: "flights" }} />;
  }

  return (
    <PurchaseStateContextProvider>
      <FlightsTokensProvider>
        <Outlet />
      </FlightsTokensProvider>
    </PurchaseStateContextProvider>
  );
}
