import { useMutation } from "@tanstack/react-query";

import { api } from "../api/api";
import * as Sentry from "@sentry/react";
import { DocumentScanningResult } from "../types/types";

export function useDocumentScanning({
  onSuccess,
  onError,
}: {
  onSuccess: (data: DocumentScanningResult | null) => void;
  onError?: () => void;
}) {
  return useMutation({
    mutationFn: async ({ frontDoc }: { frontDoc: string }) => {
      const response = await api.post<DocumentScanningResult>(
        "/scan-passport",
        {
          frontDoc,
        },
      );
      return response.data;
    },
    onSuccess,
    onError: (error) => {
      Sentry.captureException(error);
      onError?.();
    },
  });
}
