import { type ReactNode } from "react";
import { Card, Divider } from "@mui/material";
import { useTranslations } from "use-intl";
import type { Hotel } from "@/app/lib/types/types";

import { HotelName } from "../hotels/HotelName";
import { HotelStars } from "../hotels/HotelStars";
import { boardIcons } from "../hotels/BoardIcons";
import { formatCurrency } from "@/app/lib/utils/currency";

export const HotelCard = ({
  hotel,
  onClick,
  additionalInfo,
  isSuccessScreen,
  footer,
}: {
  hotel: Hotel;
  additionalInfo?: ReactNode;
  onClick?: () => void;
  isSuccessScreen?: boolean;
  footer?: ReactNode;
}) => {
  const t = useTranslations();

  return (
    <Card
      className="flex flex-col gap-4"
      sx={{
        "& + &": { mt: 1 },
        p: 2,
        borderRadius: "1.5rem",
      }}
      onClick={onClick}
      elevation={0}
    >
      <div className="flex justify-between gap-4">
        <div className="flex">
          <div className="h-[130px] w-[90px] overflow-clip rounded-2xl">
            {hotel.mainImageUrl ? (
              <img
                className="h-full w-full object-cover"
                src={hotel.mainImageUrl}
                alt={hotel.name}
              />
            ) : (
              <div className="flex h-full w-full items-center bg-gray-100 text-center text-xs text-gray-600">
                {t("noImage")}
              </div>
            )}
          </div>
        </div>

        <div className="flex w-full flex-col gap-2">
          <span className="text-title font-semibold">
            <HotelName name={hotel.name} />
          </span>

          {!!hotel.stars && <HotelStars stars={hotel.stars} />}

          {hotel.specialDeal && (
            <div className="flex items-center">
              <span className="rounded-2xl bg-[#FBE6D0] px-3 text-xs">
                {t("hotels.card.specialDeal")}
              </span>
            </div>
          )}
          {hotel.recommended && (
            <div className="flex items-center">
              <span className="rounded-2xl bg-[#94E0D8] px-3 text-xs">
                {t("hotels.card.recommended")}
              </span>
            </div>
          )}
          {!!hotel.boardBasis?.length &&
            hotel.boardBasis.map((bb) => (
              <div key={bb} className="flex items-center">
                <div className="flex items-center gap-2 text-xs">
                  {boardIcons[bb]}
                  {t(`hotels.boardBasis.${bb}`)}
                </div>
              </div>
            ))}
          {hotel.minPrice ? (
            <>
              <hr />
              <div className="flex flex-col justify-start">
                <div className="flex flex-row items-center justify-start">
                  <span className="text-title py-1 text-xl" dir="ltr">
                    {formatCurrency(
                      hotel.minPrice.value,
                      hotel.minPrice.currency,
                    )}
                  </span>
                  {!isSuccessScreen && (
                    <span className="text-tertiary px-1 text-xs">
                      {t("hotels.card.from")}
                    </span>
                  )}
                </div>
              </div>
            </>
          ) : null}
          {additionalInfo}
        </div>
      </div>

      {footer ? (
        <>
          <Divider />
          {footer}
        </>
      ) : null}
    </Card>
  );
};
