import { useState } from "react";
import { Button, ToggleButtonGroup } from "@mui/material";
import { CoinVertical, Star } from "@phosphor-icons/react";
import { HotelsSortOptions, SortOrder } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import { HotelsSortValue } from "@/app/lib/types/types";
import { SortButton } from "../SortButton";

type Props = {
  sortValue: HotelsSortValue;
  onSortValueChange: (value: HotelsSortValue) => void;
};

function HotelsListsSort({ sortValue, onSortValueChange }: Props) {
  const t = useTranslations();
  const [currentSort, setCurrentSort] = useState<HotelsSortValue>(sortValue);

  return (
    <div className="relative w-full">
      <ToggleButtonGroup
        className="flex w-full flex-col"
        exclusive
        size="small"
        color="primary"
        aria-label="sort options"
      >
        <SortButton
          value={{ field: HotelsSortOptions.PRICE, order: SortOrder.ASC }}
          isSelected={
            currentSort.field === HotelsSortOptions.PRICE &&
            currentSort.order === SortOrder.ASC
          }
          onChange={setCurrentSort}
        >
          <CoinVertical
            size={20}
            className="fill-secondary"
            weight="bold"
            aria-hidden
          />
          {t("hotels.filters.cheapestFirst")}
        </SortButton>

        <SortButton
          value={{ field: HotelsSortOptions.RATING, order: SortOrder.DESC }}
          isSelected={
            currentSort.field === HotelsSortOptions.RATING &&
            currentSort.order === SortOrder.DESC
          }
          onChange={setCurrentSort}
        >
          <Star
            size={20}
            className="fill-secondary"
            weight="bold"
            aria-hidden
          />
          {t("hotels.filters.ratingFirst")}
        </SortButton>
      </ToggleButtonGroup>

      <div className="m-4">
        <Button
          fullWidth
          onClick={() => onSortValueChange(currentSort)}
          variant="contained"
          size="large"
        >
          {t("hotels.filters.done")}
        </Button>
      </div>
    </div>
  );
}

export default HotelsListsSort;
