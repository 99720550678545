import { Drawer } from "@mui/material";
import { PropsWithChildren, RefObject, useLayoutEffect, useRef } from "react";
import useResizeObserver from "@react-hook/resize-observer";

const useDrawerSize = (target: RefObject<HTMLDivElement>) => {
  useLayoutEffect(() => {
    document.body.style.setProperty(
      "--drawer-height",
      `${target.current?.getBoundingClientRect().height || 0}px`,
    );
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => {
    document.body.style.setProperty(
      "--drawer-height",
      `${entry.target.getBoundingClientRect().height}px`,
    );
  });
};

export const PersistentDrawer = ({ children }: PropsWithChildren) => {
  const target = useRef<HTMLDivElement>(null);
  useDrawerSize(target);

  return (
    <Drawer
      PaperProps={{ ref: target }}
      anchor="bottom"
      variant="persistent"
      open={true}
    >
      <div className="w-full">{children}</div>
    </Drawer>
  );
};
