"use client";

import { ArrowLeft } from "@phosphor-icons/react";
import { IconButton } from "@mui/material";
import { useTranslations } from "use-intl";

export function MyTripsHeader({ onBackClick }: { onBackClick?: () => void }) {
  const t = useTranslations();

  return (
    <div className="mb-4 flex items-center justify-between py-4">
      <IconButton onClick={onBackClick} sx={{ padding: 0 }}>
        <ArrowLeft size={24} color="text-title" className="rtl:-scale-x-100" />
      </IconButton>

      <h3 className="text-title font-medium">{t("orderDetails")}</h3>

      <div className="size-6" />
    </div>
  );
}
