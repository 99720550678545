import HotelsCalculator from "../hotels/HotelsCalculator";
import FlightCalculator from "../flights/FlightCalculator";
import ScopeSwitcher from "../ScopeSwitcher";

interface SearchProps {
  category: "flights" | "hotels";
}

export const Search = ({ category = "flights" }: SearchProps) => {
  return (
    <>
      <div className="sticky top-10 z-0 my-8 flex w-full items-center justify-center">
        <ScopeSwitcher current={category} />
      </div>
      <div className="flex w-full flex-col items-center justify-center rounded-3xl bg-white text-black shadow-md">
        {category === "hotels" ? <HotelsCalculator /> : <FlightCalculator />}
      </div>
    </>
  );
};
