import { useTranslations } from "use-intl";
import PageError, { PageErrorProps } from "./PageError";
import { Warning } from "@phosphor-icons/react";
import { isAxiosError } from "axios";
import { ApiError } from "@/app/lib/types/types";
import { OrderType } from "@/app/lib/types/enums";

interface Props {
  type: "flights" | "hotels";
  error: unknown;
}

const getErrorPageContent = ({
  type,
  error,
  t,
}: Props & { t: ReturnType<typeof useTranslations> }) => {
  let title =
    type === "flights"
      ? t("flights.errorWhileBooking")
      : t("hotels.errorWhileBooking");
  let subtitles =
    type === "flights"
      ? [t("flights.errorWhileBookingDesc")]
      : [t("hotels.errorWhileBookingDesc")];
  let back: PageErrorProps["back"] = {
    text: t("backToHome"),
    options: { to: "/", search: { category: type } },
  };

  if (!(error instanceof Error)) {
    title = t("purchaseError");
    subtitles = [t("purchaseErrorDesc")];
  }

  if (
    isAxiosError<ApiError>(error) &&
    error.response?.data.code === "VALIDATION_FAILED"
  ) {
    subtitles = [];
    back = { text: t("back") };
    error.response?.data.data.forEach((validationError) => {
      subtitles.push(validationError.message);
    });
  }

  return { title, subtitles, back };
};

export const BookingError = ({ error, type }: Props) => {
  const t = useTranslations();

  const { title, subtitles, back } = getErrorPageContent({ error, type, t });

  return (
    <PageError
      title={title}
      subtitles={subtitles}
      back={{ ...back }}
      link={{
        text: t("orderDetails"),
        options: {
          to: "/trips",
          search: { category: type, orderType: OrderType.REFUNDED },
        },
      }}
      icon={<Warning size={60} color="#de370c" />}
    />
  );
};
