import { useLocale } from "use-intl";

export function getDir(locale: string): "rtl" | "ltr" {
  return locale === "ar" ? "rtl" : "ltr";
}

export function useLocaleInfo() {
  const locale = useLocale();

  const dir = getDir(locale);

  return { locale, dir };
}
