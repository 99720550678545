import { Chip } from "@mui/material";
import clsx from "clsx";

type Props = {
  overallTime?: string | null;
};

function FlightArrow({ overallTime }: Props) {
  return (
    <div className="relative flex w-full items-center justify-center">
      <Chip
        className={clsx(
          "bg-accent-700 relative z-10 px-2 text-xs font-normal text-white",
          { invisible: !overallTime }, // to reserve space
        )}
        label={overallTime}
        size="small"
        color="primary"
      />
      <div className="bg-accent-700 translate absolute h-[1.5px] w-full" />
    </div>
  );
}

export default FlightArrow;
