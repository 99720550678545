import HotelsList from "@/app/ui/hotels-list/HotelsList";
import { createFileRoute } from "@tanstack/react-router";
import { getQiToken } from "@/app/lib/api/api";
import clsx from "clsx";
import { useEffect } from "react";
import { useHotelBookingContext } from "@/app/lib/context/HotelBookingContext";

export const Route = createFileRoute("/_authenticated/_hotels/hotels/list")({
  component: RouteComponent,
});

function RouteComponent() {
  const qi = getQiToken();

  const { setData: setHotelBooking } = useHotelBookingContext();

  useEffect(() => {
    setHotelBooking(null);
  }, [setHotelBooking]);

  return (
    <main className={clsx("no-footer", { main: !qi, "main-qi": !!qi })}>
      <HotelsList />
    </main>
  );
}
