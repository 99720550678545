export const getSetFilterCount = <T>(
  filters: T | undefined,
  defaultFilters: T | undefined,
) => {
  if (!filters || !defaultFilters) {
    return 0;
  }

  return (Object.keys(filters) as Array<keyof typeof filters>).reduce(
    (acc, key) => {
      const value = filters[key];
      const defaultValue = defaultFilters[key];

      if (Array.isArray(value) && Array.isArray(defaultValue)) {
        // the easiest way to compare arrays content, we assume that they contain primitives
        const isSame =
          JSON.stringify([...value].sort()) ===
          JSON.stringify([...defaultValue].sort());

        return isSame ? acc : acc + 1;
      }

      return value === defaultValue ? acc : acc + 1;
    },
    0,
  );
};
