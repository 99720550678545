import { Plus, Minus } from "@phosphor-icons/react";
import clsx from "clsx";

interface CounterProps {
  value: number;
  maxValue?: number;
  minValue?: number;
  onChange: (newValue: number) => void;
}

export default function CounterButton({
  value,
  maxValue,
  minValue,
  onChange,
}: CounterProps) {
  const minusDisabled = minValue ? value <= minValue : false;
  const plusDisabled = maxValue ? value >= maxValue : false;

  return (
    <div
      dir="ltr"
      className="border-stroke-neutral flex w-fit items-center gap-4 rounded-2xl border border-solid p-3"
    >
      <button
        className="h-5 w-5"
        onClick={() => onChange(Math.max(value - 1, minValue || 0))}
        disabled={minusDisabled}
        type="button"
      >
        <Minus
          size={20}
          className={clsx({
            "fill-secondary": !minusDisabled,
            "fill-disabled": minusDisabled,
          })}
          weight="bold"
        />
      </button>
      <span className="text-label flex w-3 justify-center">{value}</span>
      <button
        className="h-5 w-5"
        onClick={() => onChange(Math.min(value + 1, maxValue || 9))}
        disabled={plusDisabled}
        type="button"
      >
        <Plus
          size={20}
          className={clsx({
            "fill-secondary": !plusDisabled,
            "fill-disabled": plusDisabled,
          })}
          weight="bold"
        />
      </button>
    </div>
  );
}
