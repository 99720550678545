import { IconButton } from "@mui/material";
import {
  X,
  ArrowLeft,
  ArrowsLeftRight,
  ArrowRight,
} from "@phosphor-icons/react";
import { TripType } from "@/app/lib/types/enums";
import type { TFlightFormValues } from "@/app/lib/types/interfaces";

const Header = ({
  searchData,
  onCancel,
  onBack,
}: {
  searchData: TFlightFormValues;
  onCancel: () => void;
  onBack: () => void;
}) => {
  return (
    <div className="align-center flex justify-between p-2">
      <IconButton onClick={onBack}>
        <ArrowLeft size={24} color="text-title" className="rtl:-scale-x-100" />
      </IconButton>
      <div className="flex items-center justify-center gap-2">
        <h3 className="text-title font-medium">{searchData.to.city}</h3>
        {searchData.tripType === TripType.ROUND_TRIP ? (
          <ArrowsLeftRight size={16} color="#003E2B" />
        ) : (
          <ArrowRight size={16} color="#003E2B" className="rtl:-scale-x-100" />
        )}
        <h3 className="text-title font-medium">{searchData.from.city}</h3>
      </div>
      <IconButton onClick={onCancel}>
        <X size={24} color="text-title" />
      </IconButton>
    </div>
  );
};

export default Header;
