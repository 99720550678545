import { useState, useMemo, PropsWithChildren } from "react";
import {
  PurchaseState,
  PurchaseStateContext,
  PurchaseStateContextValue,
} from "./PurchaseStateContext";

export const PurchaseStateContextProvider = ({
  children,
}: PropsWithChildren) => {
  const [purchaseState, setPurchaseState] = useState<PurchaseState>({
    wasPurchaseDone: false,
  });

  const contextValue = useMemo<PurchaseStateContextValue>(
    () => ({
      purchaseState,
      updatePurchaseState: (value) => {
        setPurchaseState(value);
      },
    }),
    [purchaseState],
  );

  return (
    <PurchaseStateContext.Provider value={contextValue}>
      {children}
    </PurchaseStateContext.Provider>
  );
};
