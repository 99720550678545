import { useState } from "react";
import { ChartBar } from "@phosphor-icons/react";
import { ToggleButtonGroup, Button } from "@mui/material";
import { useTranslations } from "use-intl";

import { FlightsSortValue } from "@/app/lib/types/types";
import { FlightsSortOptions, SortOrder } from "@/app/lib/types/enums";

import { SortButton } from "../SortButton";

type Props = {
  sortValue: FlightsSortValue;
  onSortValueChange: (value: FlightsSortValue) => void;
};

function FlightsSort({ sortValue, onSortValueChange }: Props) {
  const t = useTranslations();
  const [currentSort, setCurrentSort] = useState<FlightsSortValue>(sortValue);

  return (
    <>
      <div className="relative w-full">
        <ToggleButtonGroup
          className="flex w-full flex-col"
          exclusive
          size="small"
          color="primary"
          aria-label="sort options"
        >
          <SortButton
            value={{ field: FlightsSortOptions.PRICE, order: SortOrder.ASC }}
            isSelected={
              currentSort.field === FlightsSortOptions.PRICE &&
              currentSort.order === SortOrder.ASC
            }
            onChange={setCurrentSort}
          >
            <ChartBar size={16} className="rotate-90" aria-hidden />
            {t("flights.cheapFirst")}
          </SortButton>

          <SortButton
            value={{ field: FlightsSortOptions.PRICE, order: SortOrder.DESC }}
            isSelected={
              currentSort.field === FlightsSortOptions.PRICE &&
              currentSort.order === SortOrder.DESC
            }
            onChange={setCurrentSort}
          >
            <ChartBar
              size={16}
              style={{ transform: "rotate(90deg) scaleX(-1)" }}
              aria-hidden
            />
            {t("flights.expensiveFirst")}
          </SortButton>
        </ToggleButtonGroup>
      </div>

      <div className="mt-auto flex flex-col items-center py-4">
        <Button
          fullWidth
          onClick={() => onSortValueChange(currentSort)}
          variant="contained"
          size="large"
        >
          {t("flights.done")}
        </Button>
      </div>
    </>
  );
}

export default FlightsSort;
