import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Traveller } from "@/app/lib/types/types";
import { useTranslations } from "use-intl";

export const HotelRoomTravelers = ({
  selectedTravelers,
  onTravelersSelect,
  travelers,
}: {
  onTravelersSelect: (travelers: string[]) => void;
  selectedTravelers: string[];
  travelers: (Traveller & { available: boolean })[];
}) => {
  const t = useTranslations();
  const renderValue = (selected: string[]) => {
    return selected
      .map((p) => {
        const traveler = travelers.find((t) => t.id === p);
        return `${traveler?.firstName} ${traveler?.lastName}`;
      })
      .join(", ");
  };

  return (
    <div className="flex w-full">
      <FormControl size="small" className="w-full">
        <InputLabel id="assign-travellers-small-label">
          {t("reservations.assignRoom")}
        </InputLabel>
        <Select
          multiple
          className="w-full"
          labelId="assign-travellers-small-label"
          id="assign-travellers-small"
          value={selectedTravelers}
          label={t("reservations.assignRoom")}
          onChange={(event) => {
            const {
              target: { value },
            } = event;

            if (typeof value === "string") {
              return;
            }

            onTravelersSelect(value);
          }}
          renderValue={renderValue}
          sx={{ borderRadius: "12px" }}
          required
        >
          {travelers.map((traveler) => (
            <MenuItem
              key={traveler.id}
              value={traveler.id}
              disabled={
                !traveler.available && !selectedTravelers.includes(traveler.id)
              }
            >
              {traveler.firstName} {traveler.lastName}
              <Checkbox
                checked={selectedTravelers.includes(traveler.id)}
                sx={{ marginInlineStart: 1 }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
