import { Button, CircularProgress } from "@mui/material";
import { DownloadSimple } from "@phosphor-icons/react";

import { useReservationDocuments } from "../lib/hooks/useReservationDocuments";
import { useTranslations } from "use-intl";
import { FindUserReservationDocumentsDocument } from "../lib/types/codegen";
import { useState } from "react";

type DownloadButtonProps = {
  doc: FindUserReservationDocumentsDocument;
};

const ReservationDocumentDownloadButton = ({ doc }: DownloadButtonProps) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const openLink = (doc: FindUserReservationDocumentsDocument) => {
    if (isDownloading) {
      return;
    }

    setIsDownloading(true);
    my?.downloadFile({
      url: doc.link,
      success({ apFilePath }) {
        my?.openDocument({
          filePath: apFilePath,
          fileType: "pdf",
        });
      },
      fail(res) {
        my?.alert({ content: res.errorMessage });
      },
      complete() {
        setIsDownloading(false);
      },
    });
  };

  return (
    <Button
      fullWidth
      size="small"
      disableElevation
      variant="contained"
      // SuperQi has it's own api for displaying pdfs
      {...(my
        ? { onClick: () => openLink(doc) }
        : {
            href: doc.link,
            download: doc.name,
          })}
    >
      <span className="flex items-center gap-2">
        <span>Download {doc.name || "voucher"}</span>
        {isDownloading ? (
          <CircularProgress size={24} />
        ) : (
          <DownloadSimple size={24} />
        )}
      </span>
    </Button>
  );
};

type Props = {
  bookingId?: number;
};

export const ReservationDocumentList = ({ bookingId }: Props) => {
  const t = useTranslations();
  const { data } = useReservationDocuments(bookingId);

  if (!bookingId || !data?.length) {
    return (
      <div className="flex items-center justify-center gap-2">
        <CircularProgress size={20} />
        <span>{t("preparingTickets")}</span>
      </div>
    );
  }

  return (
    <ul>
      {data?.map((doc) => (
        <li key={doc.link} className="not-last:mb-2">
          <ReservationDocumentDownloadButton doc={doc} />
        </li>
      ))}
    </ul>
  );
};
