"use client";
import { ArrowLeft, CaretDown } from "@phosphor-icons/react";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";
import { HotelsSortOptions } from "@/app/lib/types/enums";
import { useTranslations } from "use-intl";
import { useRouter } from "@tanstack/react-router";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import { FiltersIconButton } from "../FiltersIconButton";
import { HotelsSortValue } from "@/app/lib/types/types";

type Props = {
  onFiltersButtonClick: () => void;
  onSortButtonClick: () => void;
  selectedSortValue: HotelsSortValue;
  hotelCount: number | undefined;
  filtersSet: number | undefined;
};

function Header({
  onFiltersButtonClick,
  onSortButtonClick,
  selectedSortValue,
  hotelCount,
  filtersSet,
}: Props) {
  const { history } = useRouter();
  const { searchData } = useHotelSearchDataContext();
  const t = useTranslations();

  const sortOptionsLabels = {
    [HotelsSortOptions.PRICE]: t("hotels.filters.cheapestFirst"),
    [HotelsSortOptions.RATING]: t("hotels.filters.ratingFirst"),
  };

  return (
    <div className="mt-4 mb-4 flex flex-col gap-4 px-4">
      <div className="align-center flex justify-between">
        <IconButton onClick={() => history.back()} sx={{ padding: 0 }}>
          <ArrowLeft size={24} className="fill-slate-1100 rtl:-scale-x-100" />
        </IconButton>
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-center gap-2">
            <h3 className="text-title font-medium">
              {searchData!.destination.name}
            </h3>
          </div>
          <span className="text-tertiary max-w-[220px] truncate text-xs font-normal">
            {dayjs(searchData!.dates.checkIn).format("ddd, DD MMM")} -{" "}
            {dayjs(searchData!.dates.checkOut).format("ddd, DD MMM")},{" "}
            {searchData!.rooms.length}{" "}
            {searchData!.rooms.length > 1
              ? t("hotels.search.rooms")
              : t("hotels.search.room")}
          </span>
        </div>
        <FiltersIconButton
          onClick={onFiltersButtonClick}
          filtersSetCount={filtersSet}
        />
      </div>
      <div className="flex items-center justify-between">
        <button
          onClick={onSortButtonClick}
          className="text-primary active:border-accent-700 inline-flex items-center gap-2 rounded-2xl bg-white px-4 py-2 text-sm"
        >
          <CaretDown aria-hidden /> {sortOptionsLabels[selectedSortValue.field]}
        </button>
        {hotelCount ? (
          <div className="text-md font-medium">
            {t("hotels.availableHotels", { hotelCount })}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Header;
