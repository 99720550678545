"use client";

import { useState } from "react";
import { Button } from "@mui/material";
import { UserPlus } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";
import SingleTravellerInList from "@/app/ui/travellers/SingleTravellerInList";
import DeleteTraveller from "@/app/ui/travellers/DeleteTraveller";
import TravellerForm from "@/app/ui/travellers/TravellerForm";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { useSuspenseQuery } from "@tanstack/react-query";
import {
  travelersQueryOptions,
  useDeleteTravelerMutation,
  useNewTravelerMutation,
  useUpdateTravelerMutation,
} from "@/app/lib/hooks/useTravellers";
import { TravellerCloseConfirmDrawer } from "./TravellerCloseConfirmDrawer";
import { Drawer } from "../Drawer";
import { Traveller } from "@/app/lib/types/types";

interface TravelerListV2Props {
  edit?: string;
  delete?: string;
  new?: boolean;
}

export function TravelerListV2(props: TravelerListV2Props) {
  const navigate = useNavigate();
  const t = useTranslations();

  const { mutateAsync: addTraveler } = useNewTravelerMutation();
  const { mutateAsync: editTraveler } = useUpdateTravelerMutation();
  const { mutateAsync: deleteTraveler } = useDeleteTravelerMutation();
  const { history } = useRouter();
  const [isCloseConfirmOpen, setIsCloseConfirmOpen] = useState(false);

  const { data } = useSuspenseQuery(travelersQueryOptions({ page: 1 }));

  const initTravellerDeletion = (traveller: Traveller) => {
    navigate({ to: "/travelers", search: { delete: traveller.id } });
  };

  const initTravellerEdit = (traveller: Traveller) => {
    navigate({ to: "/travelers", search: { edit: traveller.id } });
  };

  const closeDrawerHandler = () => {
    history.back();
  };

  const travelerForDeletion = props.delete
    ? data?.find((t) => t.id === props.delete)
    : undefined;
  const travelerForEdit = props.edit
    ? data?.find((t) => t.id === props.edit)
    : undefined;

  return (
    <>
      <div className="mt-4 flex flex-col gap-4 rounded-xl bg-white">
        {data?.map((traveller, index) => {
          return (
            <SingleTravellerInList
              checkDisabled={false}
              lastInList={data.length === index + 1}
              key={traveller.id}
              traveller={traveller}
              toggleTravellerSelection={() => {}}
              selectedTravellers={[]}
              onDelete={initTravellerDeletion}
              onEdit={initTravellerEdit}
            />
          );
        })}
      </div>

      <div className="flex justify-center">
        <Button
          variant="text"
          size="small"
          onClick={() => {
            navigate({ to: "/travelers", search: { new: true } });
          }}
          sx={{
            margin: "calc(var(--spacing) * 2) 0",
          }}
          color="primary"
        >
          <UserPlus size={24} className="fill-interactive me-2" />
          {t("travellers.addNew")}
        </Button>
      </div>

      <Drawer
        open={Boolean(props.new || travelerForEdit)}
        hasFullHeight
        heightOffset={24}
      >
        <TravellerForm
          onClose={(reason) => {
            if (reason === "cancelWithPendingChanges") {
              setIsCloseConfirmOpen(true);
              return;
            }

            closeDrawerHandler();
          }}
          traveller={travelerForEdit || null}
          onSubmit={(data) =>
            travelerForEdit
              ? editTraveler({ travelerId: travelerForEdit.id, data })
              : addTraveler(data)
          }
        />
      </Drawer>

      <TravellerCloseConfirmDrawer
        open={isCloseConfirmOpen}
        onClose={() => {
          setIsCloseConfirmOpen(false);
        }}
        onConfirm={() => {
          setIsCloseConfirmOpen(false);
          closeDrawerHandler();
        }}
      />

      <Drawer open={Boolean(travelerForDeletion)}>
        {travelerForDeletion ? (
          <DeleteTraveller
            traveller={travelerForDeletion}
            deleteTraveller={() => deleteTraveler(travelerForDeletion.id)}
            closeDrawer={closeDrawerHandler}
          />
        ) : null}
      </Drawer>
    </>
  );
}
