import { queryOptions, useQuery } from "@tanstack/react-query";

import api from "../api/api";
import {
  FindOrderReservationForFlightOutputDto,
  FindOrderReservationForHotelOutputDto,
} from "../types/codegen";

export const useOrder = (orderId?: string) => {
  return useQuery({
    queryKey: ["order", orderId] as const,

    queryFn: ({ signal }) =>
      // TODO: add proper types when available
      api.get<{ data: { bookingId: number } }>(`/orders/${orderId}`, {
        signal,
      }),
    select: (response) => response.data.data,
    enabled: Boolean(orderId),
    refetchInterval: (query) => {
      const response = query.state.data;

      // new orders have bookingId set after webhook is received
      // this may happen with a delay, so we need to keep polling
      if (!response?.data?.data?.bookingId) {
        return 2000;
      }

      return false;
    },
  });
};

export const flightOrderQueryOptions = (orderId?: string) =>
  queryOptions({
    queryKey: ["orders", "flights", orderId],
    queryFn: ({ queryKey: [, , orderId], signal }) =>
      api.get<FindOrderReservationForFlightOutputDto>(
        `/orders/${orderId}/reservations/flight`,
        {
          signal,
        },
      ),
    select: (response) => response.data?.data,
    enabled: Boolean(orderId),
  });

export const hotelOrderQueryOptions = (orderId?: string) =>
  queryOptions({
    queryKey: ["orders", "hotels", orderId],
    queryFn: ({ queryKey: [, , orderId], signal }) =>
      api.get<FindOrderReservationForHotelOutputDto>(
        `/orders/${orderId}/reservations/hotel`,
        {
          signal,
        },
      ),
    select: (response) => response.data?.data,
    enabled: Boolean(orderId),
  });
