import { useTranslations } from "use-intl";
import { Wrench } from "@phosphor-icons/react";

export function UnderMaintenance() {
  const t = useTranslations();

  return (
    <div className="flex min-h-screen w-full flex-col">
      <div className="flex h-[90%] w-full flex-col items-center justify-center">
        <Wrench size={60} alt="Maintenance" />
        <h3 className="pb-2 text-[20px] font-bold">{t("maintenance.title")}</h3>
        <span>{t("maintenance.makingImprovements")}</span>
        <span>{t("maintenance.checkBackSoon")}</span>
      </div>
    </div>
  );
}
