import { createContext, useContext } from "react";
import { THotelSearchResult } from "../../types/types";

export interface HotelDataContextValue {
  data: THotelSearchResult | null;
  setData: (data: THotelSearchResult) => void;
}

export const HotelDataContext = createContext<HotelDataContextValue>({
  data: null,
  setData: () => {},
});

export const useOptionalHotelDataContext = () => useContext(HotelDataContext);
export const useHotelDataContext = (): {
  [Key in keyof HotelDataContextValue]: NonNullable<HotelDataContextValue[Key]>;
} => {
  const value = useOptionalHotelDataContext();
  if (!value || !value.data) {
    throw new Error(
      "useHotelDataContext must be used within a HotelDataProvider and have a value",
    );
  }
  return {
    data: value.data,
    setData: value.setData,
  };
};
