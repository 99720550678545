import {
  HotelBookingRoomOption,
  THotelSearchResult,
} from "@/app/lib/types/types";
import { formatCurrency } from "@/app/lib/utils/currency";

export const HotelRoom = ({
  room,
  hotel,
  visitorsCount,
}: {
  room: HotelBookingRoomOption;
  hotel: THotelSearchResult;
  visitorsCount: number;
}) => {
  return (
    <div className="flex w-full justify-between gap-4 rounded-2xl bg-[#F3F4F7] p-3">
      <div className="text-sm font-medium">
        <div>{room.name}</div>
        <div className="mb-2">
          {room.board} - {visitorsCount} Visitors
        </div>
        <div className="font-bold">
          <span dir="ltr">
            {formatCurrency(room.price?.value, room.price?.currency)}
          </span>
        </div>
      </div>

      <div className="relative h-[48px] min-h-[48px] w-[60px] overflow-clip rounded-lg">
        {hotel.mainImageUrl && (
          <img
            className="z-10 min-h-12 min-w-16"
            src={hotel.mainImageUrl}
            alt={hotel.name}
            width={60}
            height={48}
          />
        )}
        <div className="absolute bottom-0 left-0 z-1 bg-black ps-1.5 pe-1">
          <div className="flex items-center justify-center gap-0.5">
            <div className="text-xs text-white">{hotel.imagesUrls.length}+</div>
            <img src="/image.svg" width={9} height={9} alt="image" />
          </div>
        </div>
      </div>
    </div>
  );
};
