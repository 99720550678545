import type {
  FindFlightOffersFlight,
  GetFlightAvailabilityTokenFlight,
  GetFlightAvailabilityTokenOutputDto,
} from "../types/codegen";
import api from "@/app/lib/api/api";
import { queryOptions, skipToken } from "@tanstack/react-query";

interface FlightAvailabilityParams {
  offerId: string;
  searchResultKey: string;
  searchResultToken: string;
  itineraryIndex: number;
}

export const flightAvailabilityQueryOptions = (
  params: FlightAvailabilityParams | null,
) =>
  queryOptions({
    queryKey: ["flight-availability", params] as const,
    queryFn: params
      ? () =>
          api.post<GetFlightAvailabilityTokenOutputDto>(
            `/flights/${params.offerId}/availability`,
            params,
          )
      : skipToken,
    select: (data) => data.data.data,
    enabled: !!params,
  });

export const transformFlightAvailabilityData = (
  data: FindFlightOffersFlight,
): GetFlightAvailabilityTokenFlight => ({
  ...data,
  segments: data.segments.map((segment) => ({
    ...segment,
    cabinClasses: segment.cabinClasses.map((cabinClass) => ({
      ...cabinClass,
      quantity:
        typeof cabinClass.quantity === "number"
          ? cabinClass.quantity
          : Number.parseInt(cabinClass.quantity, 10),
    })),
  })),
});
