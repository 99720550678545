import { useQuery } from "@tanstack/react-query";

import useDebouncedValue from "./useDebouncedValue";
import { api } from "../api/api";
import { FindCityAirportsQueryOutputDto } from "../types/codegen";

// Custom hook for airport search
export function useAirportSearch(searchQuery: string) {
  const debouncedSearch = useDebouncedValue(searchQuery.trim(), 500);

  const { data, isError, isLoading } = useQuery({
    queryKey: ["airports-search", debouncedSearch] as const,
    queryFn: ({ signal }) =>
      api.get<FindCityAirportsQueryOutputDto>(`/mappings/cities/airports`, {
        params: { searchQuery: debouncedSearch },
        signal,
      }),
    select: (response) => response.data.data,
    enabled: debouncedSearch.length > 2,
    staleTime: 1000 * 60 * 60 * 24, // fetch fresh translations after 24 hours
  });

  return {
    data,
    isLoading,
    isError,
  };
}
