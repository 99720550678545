import { ReactNode } from "react";
import dayjs from "dayjs";
import { Bed, CalendarBlank, User } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";
import { FindUserHotelsReservationsReservation } from "@/app/lib/types/codegen";
import { HotelCard } from "../hotels-list/HotelCard";

export function MyHotelCard({
  reservation,
  onClick,
  footer,
}: {
  reservation: FindUserHotelsReservationsReservation;
  onClick?: () => void;
  footer?: ReactNode;
}) {
  const t = useTranslations();
  const children = reservation.travelers.filter(
    (traveler) => traveler.type === "child",
  );

  const adults = reservation.travelers.filter(
    (traveler) => traveler.type === "adult",
  );

  return (
    <HotelCard
      onClick={onClick}
      hotel={{
        ...reservation.hotel,
        boardBasis: reservation.rooms
          .map(({ boardBasis }) => boardBasis)
          .flat(),
      }}
      footer={footer}
      additionalInfo={
        <ul className="flex flex-col gap-2">
          <li className="flex items-center justify-start gap-2 text-xs">
            <Bed className="fill-tertiary" size={20} />
            <span>
              {t("reservations.hotelRooms", {
                count: reservation.rooms.length,
              })}
            </span>
          </li>
          <li className="flex items-center justify-start gap-2 text-xs">
            <CalendarBlank className="fill-tertiary" size={20} />
            <span>
              {dayjs(reservation.serviceDates.startDate).format("ddd, DD MMMM")}{" "}
              - {dayjs(reservation.serviceDates.endDate).format("ddd, DD MMMM")}
            </span>
          </li>
          <li className="flex items-center justify-start gap-2 text-xs">
            <User className="fill-tertiary" size={20} />
            <span>
              {children.length > 0 ? (
                <>{t("reservations.children", { count: children.length })} </>
              ) : null}
              {t("reservations.adults", { count: adults.length })}
            </span>
          </li>
        </ul>
      }
    />
  );
}
