import { useTranslations } from "use-intl";
import { OrderStatus } from "../lib/types/types";
import { OrderLabel } from "./my-trips/OrderLabel";

const orderStatusToDescriptionMapping = {
  IN_PROGRESS: "reservations.orderInProgressDescription",
  REFUNDED: "reservations.orderRefundedDescription",
};

type Props = {
  status: OrderStatus;
};

export const OrderStatusSection = ({ status }: Props) => {
  const t = useTranslations();

  return (
    <section className="section-card mt-2 mb-4 flex w-full flex-col gap-4">
      <div className="flex w-full items-center justify-between">
        <span className="text-title text-sm">
          {t("reservations.orderStatus")}
        </span>
        <OrderLabel status={status} />
      </div>
      {status === "REFUNDED" || status === "IN_PROGRESS" ? (
        <span className="text-tertiary text-xs">
          {t(orderStatusToDescriptionMapping[status])}
        </span>
      ) : null}
    </section>
  );
};
