import { createContext, Dispatch, SetStateAction, useContext } from "react";

import { TFlightFormValues } from "@/app/lib/types/types";

export interface FlightSearchDataContextValue {
  searchData: TFlightFormValues | undefined;
  updateSearchData: Dispatch<SetStateAction<TFlightFormValues | undefined>>;
}

export const FlightSearchDataContext = createContext<
  FlightSearchDataContextValue | undefined
>(undefined);

export const useFlightSearchDataContext = () => {
  const context = useContext(FlightSearchDataContext);
  if (!context) {
    throw new Error(
      "useFlightSearchDataContext must be used within a FlightSearchDataProvider",
    );
  }
  return context;
};
