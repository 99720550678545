import { Wrench } from "@phosphor-icons/react";
import PageError from "./PageError";
import { useTranslations } from "use-intl";
import { NavigateOptions } from "@tanstack/react-router";

interface Props {
  backOptions?: NavigateOptions;
}

export const GeneralPageError = ({ backOptions = { to: "/" } }: Props) => {
  const t = useTranslations();

  return (
    <PageError
      subtitles={[t("pageDidntWork")]}
      icon={<Wrench size={60} color="#0212294d" />}
      back={{
        text: t("backToHome"),
        options: backOptions,
      }}
    />
  );
};
