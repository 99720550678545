import { PropsWithChildren, useMemo, useState } from "react";
import {
  HotelFiltersContext,
  HotelFiltersContextValue,
} from "./HotelFiltersContext";

export const HotelFiltersProvider = ({ children }: PropsWithChildren) => {
  const [defaultFilters, setDefaultFilters] =
    useState<HotelFiltersContextValue["defaultFilters"]>(null);
  const [filters, setFilters] =
    useState<HotelFiltersContextValue["filters"]>(null);

  const contextValue = useMemo<HotelFiltersContextValue>(
    () => ({
      filters,
      defaultFilters,
      updateFilters: (value) => {
        if (filters === null) {
          setDefaultFilters(value);
        }
        setFilters(value);
      },
      resetFilters: () => {
        setFilters(null);
        setDefaultFilters(null);
      },
    }),
    [filters, defaultFilters],
  );

  return (
    <HotelFiltersContext.Provider value={contextValue}>
      {children}
    </HotelFiltersContext.Provider>
  );
};
