import { SingleFightDetailsData } from "../context/SingleFlightDetailsContext";
import { GetFlightAvailabilityTokenOptionalService } from "../types/codegen";

export function isBaggageService(
  service: GetFlightAvailabilityTokenOptionalService,
) {
  return (
    service.service.categoryCode === "BG" ||
    service.service.categoryCode === "CY"
  );
}

export function getIncludedBaggageServices(
  services: GetFlightAvailabilityTokenOptionalService[],
) {
  return services.filter(
    (service) => service.included && isBaggageService(service),
  );
}

type TravellerBaggageInfo = Partial<Record<"BG" | "CY", string>>;
export type BaggageInfo = Record<string, Record<string, TravellerBaggageInfo>>;

/**
 * Returns aggregated information about the baggage for each traveller type and route
 * @param data
 * @returns E.g. { ADT: { "JFK - LAX": { BG: "1PC (20kg)", CY: "1PC (3kg)" } } }
 */
export function getBaggageInfo(
  data: SingleFightDetailsData,
): BaggageInfo | null {
  // For some providers, baggage information is returned in the offerItems
  const offerItems = data.offerItems;
  if (offerItems) {
    return offerItems.reduce((acc, offerItem) => {
      const travellerType = offerItem.ptc;
      if (!acc[travellerType]) {
        acc[travellerType] = {};
      }

      offerItem.baggageAllowance.forEach((baggage) => {
        const baggageInfo: TravellerBaggageInfo = {};
        if (baggage.allowed) {
          baggageInfo["BG"] = baggage.allowed;
        }
        if (baggage.carryOn) {
          baggageInfo["CY"] = baggage.carryOn;
        }

        const route = `${baggage.segment.origin} - ${baggage.segment.destination}`;

        acc[travellerType][route] = baggageInfo;
      });

      return acc;
    }, {} as BaggageInfo);
  }

  // For other providers, baggage information is returned as optional service
  const optionalServices = data?.optionalServices;
  if (!optionalServices) {
    return null;
  }

  return getIncludedBaggageServices(optionalServices).reduce((acc, service) => {
    const travellerType = service.target;
    const route = `${service.route.departureCode} - ${service.route.arrivalCode}`;

    if (!acc[travellerType]) {
      acc[travellerType] = {};
    }

    const baggageInfo: TravellerBaggageInfo = acc[travellerType][route] || {};

    if (
      service.service.categoryCode === "BG" ||
      service.service.categoryCode === "CY"
    ) {
      baggageInfo[service.service.categoryCode] = service.service.name;
    }

    acc[travellerType][route] = baggageInfo;

    return acc;
  }, {} as BaggageInfo);
}
