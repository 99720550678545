import {
  keepPreviousData,
  queryOptions,
  useMutation,
} from "@tanstack/react-query";

import type {
  CreateTravelerBodyInputDto,
  FindUserTravelersTravelers,
  UpdateTravelerBodyInputDto,
} from "@/app/lib/types/codegen";
import api from "../api/api";

import { queryClient } from "../query";

export const travelersQueryOptions = ({ page }: { page: number }) =>
  queryOptions({
    queryKey: ["travelers", page],
    queryFn: ({ signal, queryKey: [, page] }) =>
      // FIXME: Swagger has invalid response type
      api.get<{ data: { travelers: FindUserTravelersTravelers[] } }>(
        "/travelers",
        {
          signal,
          params: {
            page,
            perPage: 20,
          },
        },
      ),
    placeholderData: keepPreviousData,
    select: (data) => data.data.data.travelers,
  });

export const useDeleteTravelerMutation = () =>
  useMutation({
    mutationFn: (travelerId: string) => api.delete(`/travelers/${travelerId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });

export const useUpdateTravelerMutation = () =>
  useMutation({
    mutationFn: ({
      travelerId,
      data,
    }: {
      travelerId: string;
      data: UpdateTravelerBodyInputDto;
    }) => api.put(`/travelers/${travelerId}`, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });

export const useNewTravelerMutation = () =>
  useMutation({
    mutationFn: (body: CreateTravelerBodyInputDto) =>
      api.post("/travelers", body),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: travelersQueryOptions({ page: 1 }).queryKey,
      });
    },
  });
