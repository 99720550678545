import { Button } from "@mui/material";
import { useTravellersContext } from "@/app/lib/context/TravellersContext";
import { useFormik } from "formik";
import { HotelBookingSummary, SummaryFormValues } from "./HotelBookingSummary";
import { useHotelBooking } from "@/app/lib/hooks/useHotelBooking";
import * as yup from "yup";
import Loader from "../AnimatedLoader";
import PageLoader from "../PageLoader";
import { PersistentDrawer } from "../PersistentDrawer";
import { useTranslations } from "use-intl";
import { transformHotelTraveler } from "@/app/lib/utils/transformTraveler";
import {
  BookingResult,
  HotelBooking,
  THotelSearchResult,
} from "@/app/lib/types/types";
import { BookingError } from "../errors/BookingError";

interface HotelBookingSummaryFormProps {
  onSuccess: (response: BookingResult) => void;
  availabilityToken: string;
  searchResultKey: string;
  searchResultToken: string;
  booking: HotelBooking;
  hotel: THotelSearchResult;
}

export const HotelBookingSummaryForm = ({
  onSuccess,
  availabilityToken,
  searchResultKey,
  searchResultToken,
  booking,
  hotel,
}: HotelBookingSummaryFormProps) => {
  const t = useTranslations();
  const { isPending, isError, error, mutateAsync } = useHotelBooking({
    onSuccess,
  });

  const { getSelectedTravellers, leadTraveller, selectedTravellers } =
    useTravellersContext();

  const validationSchema = yup.object().shape({
    termsAndConditions: yup.boolean().required().isTrue(),
    leadTraveler: yup.string().required(),
    roomAssigments: yup
      .object()
      .shape(
        Object.fromEntries(
          booking.rooms.map((room) => [
            room.roomToken,
            yup.object({
              travelers: yup
                .array()
                .of(yup.string().required())
                .length(getSelectedTravellers.length),
            }),
          ]),
        ),
      )
      .required(),
  });

  const formik = useFormik<SummaryFormValues>({
    initialValues: {
      roomAssigments:
        booking.rooms.length === 1
          ? {
              [booking.rooms[0].roomToken]: {
                travelers: selectedTravellers,
              },
            }
          : {},
      leadTraveler: leadTraveller?.id || "",
      termsAndConditions: false,
    },
    onSubmit: (values) =>
      mutateAsync({
        availabilityToken,
        searchResultKey,
        searchResultToken,
        hotelId: `${hotel!.id}`,
        offerId: booking!.offerId,
        rooms: Array.from(Object.entries(values.roomAssigments)).map(
          ([packageRoomToken, assigment]) => ({
            packageRoomToken,
            travelers: assigment.travelers.map((t) => {
              const traveler = getSelectedTravellers.find(
                (trav) => trav.id === t,
              );

              return transformHotelTraveler(traveler!, {
                isLead: values.leadTraveler === t,
              });
            }),
          }),
        ),
      }),
    validationSchema,
  });

  if (isError) {
    return <BookingError error={error} type="hotels" />;
  }

  if (isPending) {
    return (
      <PageLoader>
        <Loader icon="buildings" />
      </PageLoader>
    );
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <HotelBookingSummary
        hotel={hotel}
        hotelBookingData={booking}
        form={formik}
      />

      <PersistentDrawer>
        <Button
          fullWidth
          type="submit"
          variant="contained"
          disabled={!formik.isValid || !formik.dirty}
        >
          {t("hotels.overview.bookRoom")}
        </Button>
      </PersistentDrawer>
    </form>
  );
};
