import { createContext, useContext } from "react";

import { HotelSearchTokens } from "../../types/types";

export interface HotelsTokensContextValue {
  data: HotelSearchTokens | null;
  isLoading: boolean;
}

export const HotelsTokensContext = createContext<HotelsTokensContextValue>({
  data: null,
  isLoading: false,
});

export const useHotelsTokensContext = () => {
  const context = useContext(HotelsTokensContext);
  if (!context) {
    throw new Error(
      "useHotelsTokensContext must be used within a HotelsTokensContextProvider",
    );
  }
  return context;
};
