import { OrderStatus } from "@/app/lib/types/types";
import { Chip, ChipOwnProps } from "@mui/material";
import { useTranslations } from "use-intl";

type Props = {
  status: OrderStatus;
};

const StatusToColorMapping: { [key in OrderStatus]: ChipOwnProps["color"] } = {
  IN_PROGRESS: "warning",
  TICKET_ISSUED: "success",
  BOOKED: "success",
  REFUNDED: "error",
};

const StatusLabels: { [key in OrderStatus]: string } = {
  IN_PROGRESS: "reservations.orderStatuses.inProgress",
  TICKET_ISSUED: "reservations.orderStatuses.ticketIssued",
  BOOKED: "reservations.orderStatuses.booked",
  REFUNDED: "reservations.orderStatuses.refunded",
};

export const OrderLabel = ({ status }: Props) => {
  const t = useTranslations();
  return (
    <Chip
      color={StatusToColorMapping[status]}
      label={t(StatusLabels[status])}
    />
  );
};
