import { mapFlightReservation } from "@/app/lib/hooks/useFlightsReservations";
import SingleAvailableFlight from "../SingleAvailableFlight";
import { FindOrderReservationForFlightReservation } from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";
import { ReservationPaymentDetails } from "./ReservationPaymentDetails";
import { ReservationNextSteps } from "./ReservationNextSteps";
import { ReservationDocumentList } from "../ReservationDocumentList";
import { OrderStatus } from "@/app/lib/types/types";
import { OrderStatusSection } from "../OrderStatusSection";

export const MyFlight = ({
  status,
  reservation,
}: {
  status: OrderStatus;
  reservation: FindOrderReservationForFlightReservation;
}) => {
  const t = useTranslations();

  return (
    <>
      <OrderStatusSection status={status} />

      <SingleAvailableFlight
        key={reservation.id}
        itinerary={{
          totalPrice: 0,
          flights: reservation.flights.map((flight) =>
            mapFlightReservation(flight),
          ),
        }}
      />

      <section className="section-card mt-2 mb-4 flex w-full items-center justify-between gap-4">
        <span className="section-title mb-0">{t("flights.ticketNumber")}</span>
        <span className="text-title text-base font-semibold">
          {reservation.reference.external}
        </span>
      </section>

      <ReservationDocumentList bookingId={reservation.id} />

      <section>
        <h3 className="section-title mt-4">{t("flights.passengerList")}</h3>
        <ul className="section-card flex w-full flex-col gap-4">
          {reservation.travelers.map((traveler) => (
            <li
              className="text-title"
              key={`${traveler.firstName}${traveler.lastName}`}
            >
              {traveler.firstName} {traveler.lastName}
            </li>
          ))}
        </ul>
      </section>

      <ReservationPaymentDetails reservation={reservation} />
      <ReservationNextSteps type="flight" />
    </>
  );
};
