import type { FindOrdersReservationsForHotelReservation } from "@/app/lib/types/codegen";
import { useTranslations } from "use-intl";
import { ReservationPaymentDetails } from "./ReservationPaymentDetails";
import { ReservationNextSteps } from "./ReservationNextSteps";
import { MyHotelCard } from "./MyHotelCard";
import { ReservationDocumentList } from "../ReservationDocumentList";

export const MyHotel = ({
  reservation,
}: {
  reservation: FindOrdersReservationsForHotelReservation;
}) => {
  const t = useTranslations();

  return (
    <>
      <MyHotelCard reservation={reservation} />

      <section className="section-card mt-2 mb-4 flex w-full items-center justify-between gap-4">
        <span className="section-title mb-0">
          {t("reservations.bookingNumber")}
        </span>
        <span className="text-title text-base font-semibold">
          {reservation.reference.external}
        </span>
      </section>

      <ReservationDocumentList bookingId={reservation.id} />

      <section>
        <h3 className="section-title mt-4">{t("reservations.roomDetails")}</h3>
        <ul className="section-card flex w-full flex-col gap-4">
          {reservation.rooms.map((room) => (
            <li
              key={room.id}
              className="flex justify-between gap-4 rounded-2xl bg-[#F3F4F7] p-3 text-sm font-medium"
            >
              <div>
                {[room.name, room.board, room.info].filter(Boolean).join(" - ")}
              </div>

              {reservation.hotel.mainImageUrl ? (
                <div className="relative h-[48px] w-[60px] overflow-clip rounded-lg">
                  <img
                    className="h-full w-full object-cover"
                    key={reservation.hotel.mainImageUrl}
                    src={reservation.hotel.mainImageUrl}
                    alt={reservation.hotel.name}
                    width={60}
                    height={48}
                  />
                </div>
              ) : null}
            </li>
          ))}
        </ul>
      </section>

      <ReservationPaymentDetails reservation={reservation} />
      <ReservationNextSteps type="hotel" />
    </>
  );
};
