"use client";

import { useState } from "react";
import { SwipeableDrawer, MenuItem, Button } from "@mui/material";
import { MapPinLine, CaretDown, X } from "@phosphor-icons/react";
import { TextField, Puller } from "../StyledTz";
import { useFormik } from "formik";
import dayjs from "dayjs";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

import DestinationSearch from "./DestinationSearch";
import CheckInSelection from "./CheckInSelection";
import VisitorsSelection from "./VisitorsSelections";
import { getFormikErrorsRecursive } from "@/app/lib/validation";
import { useSnackbar } from "@/app/lib/context/SnackbarContext";
import { TravelPurpose } from "@/app/lib/types/enums";
import { initialHotelsValues } from "@/app/lib/initialHotelsValues";
import type { THotelFormValues } from "@/app/lib/types/types";
import {
  convertArabicNumberToEnglish,
  validationSchema,
} from "./validation.ts";
import { useHotelSearchDataContext } from "@/app/lib/context/HotelSearchDataContext";
import { useHotelFiltersContext } from "@/app/lib/context/HotelFiltersContext";
import { useHotelSortContext } from "@/app/lib/context/HotelSortContext";
import { getQiToken } from "@/app/lib/api/api";

enum EDrawerComponent {
  DestinationSearch = "DestinationSearch",
  CheckInSelection = "CheckInSelection",
  VisitorsSelection = "VisitorsSelection",
}

function HotelsCalculator() {
  const [drawerComponent, setDrawerComponent] = useState<string | null>(null);
  const { resetSortValue } = useHotelSortContext();
  const { searchData, updateSearchData } = useHotelSearchDataContext();
  const { resetFilters } = useHotelFiltersContext();
  const qi = getQiToken();

  const navigate = useNavigate();
  const showSnackbar = useSnackbar();

  const t = useTranslations();

  const formik = useFormik({
    initialValues: searchData
      ? searchData!
      : (initialHotelsValues as THotelFormValues),
    validationSchema,
    onSubmit: (values) => {
      resetFilters();
      resetSortValue();
      updateSearchData({
        ...values,
        rooms: values.rooms.map((room) => {
          return {
            ...room,
            childrenAges: room.childrenAges.map((childrenAge) => {
              if (typeof childrenAge === "string") {
                return convertArabicNumberToEnglish(childrenAge);
              }
              return childrenAge;
            }),
          };
        }),
      });
      navigate({ to: "/hotels/list" });
    },
  });

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    formik.validateForm().then((res) => {
      if (getFormikErrorsRecursive(res, t).length > 0) {
        showSnackbar(getFormikErrorsRecursive(res, t)[0]);
      } else {
        formik.handleSubmit();
      }
    });
  };

  const checkInValue = dayjs(formik.values.dates.checkIn).format("ddd, D MMM");
  const checkOutValue = formik.values.dates.checkOut
    ? dayjs(formik.values.dates.checkOut).format("ddd, D MMM")
    : "_";

  const travelPurposeOptions = [
    {
      value: TravelPurpose.LEISURE,
      label: t("hotels.search.leisure"),
    },
    {
      value: TravelPurpose.BUSINESS,
      label: t("hotels.search.business"),
    },
  ];

  return (
    <div className="w-full rounded-3xl p-4">
      <form noValidate onSubmit={onSubmit} acceptCharset="ISO-8859-1">
        <div className="mb-4 flex flex-col gap-2">
          <div className="relative flex flex-col gap-2">
            <TextField
              fullWidth
              value={formik.values.destination.name?.toLowerCase()}
              label={
                <div className="flex items-center gap-1">
                  <MapPinLine size={24} />
                  <span className="ml-2">{t("hotels.search.destination")}</span>
                </div>
              }
              inputProps={{
                onMouseDown: (e) => {
                  e.preventDefault();
                  setDrawerComponent(EDrawerComponent.DestinationSearch);
                },
              }}
              InputProps={{
                endAdornment: formik.values.destination.name && (
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      formik.setFieldValue(
                        "destination",
                        initialHotelsValues.destination,
                      );
                    }}
                    className="absolute end-3"
                    type="button"
                  >
                    <X size={20} color="#6A778A" />
                  </button>
                ),
              }}
              sx={{
                "& input": {
                  textTransform: "capitalize !important",
                },
              }}
            />
          </div>
          <TextField
            fullWidth
            label={t("hotels.search.checkInAndCheckOut")}
            value={`${checkInValue} - ${checkOutValue}`}
            onMouseDown={(e) => {
              e.preventDefault();
              setDrawerComponent(EDrawerComponent.CheckInSelection);
            }}
          />
          <TextField
            fullWidth
            label={t("hotels.search.visitors")}
            // TODO: change this copy to "visitorsSumNoRooms" key when IRIX will fix the issue with booking multiple rooms
            value={t("hotels.search.visitorsSumWithTypes", {
              adultsCount: formik.values.rooms[0].adults,
              childrenCount: formik.values.rooms[0].children,
            })}
            InputProps={{
              endAdornment: (
                <CaretDown className="absolute end-5" color="black" size={24} />
              ),
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              setDrawerComponent(EDrawerComponent.VisitorsSelection);
            }}
          />
          <TextField
            select
            fullWidth
            name="travelPurpose"
            label={t("hotels.search.travelPurpose")}
            value={formik.values.travelPurpose}
            InputProps={{
              endAdornment: (
                <CaretDown className="absolute end-5" color="black" size={24} />
              ),
            }}
            onChange={(e) =>
              formik.setFieldValue("travelPurpose", e.target.value)
            }
          >
            {travelPurposeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <Button fullWidth variant="contained" type="submit">
          {t("hotels.search.searchHotel")}
        </Button>
      </form>

      <SwipeableDrawer
        anchor="bottom"
        onClose={() => setDrawerComponent(null)}
        onOpen={() => {}}
        open={drawerComponent != null}
        sx={{
          "& .MuiDrawer-paper": {
            paddingBottom: "env(safe-area-inset-bottom)",
            height:
              !drawerComponent ||
              drawerComponent === EDrawerComponent.VisitorsSelection
                ? "auto"
                : qi
                  ? "90vh"
                  : "100vh",
          },
        }}
      >
        <Puller />
        {drawerComponent === EDrawerComponent.DestinationSearch && (
          <DestinationSearch
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
        {drawerComponent === EDrawerComponent.CheckInSelection && (
          <CheckInSelection
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
        {drawerComponent === EDrawerComponent.VisitorsSelection && (
          <VisitorsSelection
            formProps={formik}
            onClose={() => setDrawerComponent(null)}
          />
        )}
      </SwipeableDrawer>
    </div>
  );
}

export default HotelsCalculator;
