import { FormikProps } from "formik";
import { useTranslations } from "use-intl";

import { THotelFormValues } from "@/app/lib/types/types";

import { Calendar } from "../Calendar";
import { Button } from "@mui/material";

function CheckInSelection({
  formProps,
  onClose,
}: {
  formProps: FormikProps<THotelFormValues>;
  onClose: () => void;
}) {
  const t = useTranslations();

  return (
    <div className="flex h-full flex-col">
      <div className="my-3 flex w-full justify-center">
        <h2 className="font-medium">{t("hotels.search.checkInAndCheckOut")}</h2>
      </div>
      <div className="mt-4 h-full">
        <Calendar
          startField={{
            label: t("hotels.search.checkIn"),
            value: formProps.values.dates.checkIn,
            path: "dates.checkIn",
          }}
          endField={{
            label: t("hotels.search.checkOut"),
            value: formProps.values.dates.checkOut,
            path: "dates.checkOut",
          }}
          setFieldValue={formProps.setFieldValue}
          disabledDates={{
            before: new Date(),
          }}
        />
      </div>
      <Button fullWidth variant="contained" onClick={onClose}>
        {t("hotels.confirm")}
      </Button>
    </div>
  );
}

export default CheckInSelection;
