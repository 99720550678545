import { useId, useState } from "react";
import {
  ToggleButtonGroup,
  ToggleButton,
  styled,
  Button,
  Checkbox,
  Divider,
} from "@mui/material";
import { useTranslations } from "use-intl";

import { StopsOptions } from "@/app/lib/types/enums";
import Image from "@/app/ui/ImageFallback";
import type { IFlightFilters } from "@/app/lib/types/interfaces";

import PriceRangeSlider from "../PriceRangeSlider";

const StyledToggleButton = styled(ToggleButton)(() => ({
  borderRadius: "32px !important",
  border: "1px solid #003E2B !important",
  textTransform: "none",
  padding: "8px",
  minWidth: "110px",
  color: "#003E2B",
  backgroundColor: "white",
  "&.Mui-selected": {
    backgroundColor: "#003E2B",
    color: "white",
    "&:hover": {
      backgroundColor: "#003E2B",
    },
    "&:selected": {
      backgroundColor: "#003E2B",
    },
  },
}));

type Props = {
  defaultFilters: IFlightFilters;
  filters: IFlightFilters;
  onFiltersChange: (newFilters: Partial<IFlightFilters>) => void;
};

function FlightsFilters({ defaultFilters, filters, onFiltersChange }: Props) {
  const priceHeaderId = useId();
  const t = useTranslations();
  // Make local copy of filters
  const [localFilters, setLocalFilters] = useState<IFlightFilters>({
    ...filters,
  });

  return (
    <>
      <div className="mt-4">
        <h3 id={priceHeaderId} className="pb-2 text-xl font-bold text-black">
          {t("hotels.filters.priceRange")}
        </h3>
        <PriceRangeSlider
          min={defaultFilters.priceRange[0]}
          max={defaultFilters.priceRange[1]}
          aria-labelledby={priceHeaderId}
          value={[localFilters.priceRange[0], localFilters.priceRange[1]]}
          onChange={(newValue) => {
            setLocalFilters((prevFilters) => ({
              ...prevFilters,
              priceRange: newValue,
            }));
          }}
        />
      </div>

      <div className="mt-8">
        <h3 className="pb-2 text-xl font-bold text-black">
          {t("flights.stops")}
        </h3>
        <ToggleButtonGroup
          value={localFilters.stops}
          exclusive
          onChange={(event, newValue: StopsOptions) => {
            // ensure that a value is selected
            if (newValue == null) {
              return;
            }

            setLocalFilters((prevFilters) => ({
              ...prevFilters,
              stops: newValue,
            }));
          }}
          className="flex w-full justify-between gap-2 py-2"
        >
          <StyledToggleButton value={StopsOptions.ANY}>
            {t("flights.any")}
          </StyledToggleButton>
          <StyledToggleButton value={StopsOptions.DIRECT}>
            {t("flights.direct")}
          </StyledToggleButton>
          <StyledToggleButton value={StopsOptions.ONE}>
            {t("flights.upToOne")}
          </StyledToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className="mt-8">
        <h3 className="pb-2 text-xl font-bold text-black">
          {t("flights.airlines")}
        </h3>
        <ul>
          {localFilters.airlines.map((airline) => (
            <li key={airline.name}>
              <label className="my-2 flex justify-between">
                <span className="flex items-center">
                  <Image
                    src={airline.logoUrl}
                    fallbackSrc="https://placehold.co/32"
                    alt={`${airline.name} logo`}
                    width={32}
                    height={32}
                  />
                  <span className="mr-2 ml-2 font-normal">{airline.name}</span>
                </span>

                <Checkbox
                  checked={localFilters.selectedAirlines.includes(airline.code)}
                  onChange={(e) => {
                    e.stopPropagation();
                    setLocalFilters((prevFilters) => ({
                      ...prevFilters,
                      selectedAirlines: e.target.checked
                        ? [...prevFilters.selectedAirlines, airline.code]
                        : prevFilters.selectedAirlines.filter(
                            (a) => a !== airline.code,
                          ),
                    }));
                  }}
                  sx={{ padding: "6px" }}
                />
              </label>
              <Divider />
            </li>
          ))}
        </ul>
      </div>

      <div className="mt-auto flex flex-col items-center gap-2 p-4 pb-0">
        <Button
          fullWidth
          onClick={() => setLocalFilters(defaultFilters)}
          variant="text"
        >
          {t("flights.clearFilters")}
        </Button>
        <Button
          fullWidth
          onClick={() => onFiltersChange(localFilters)}
          variant="contained"
        >
          {t("flights.done")}
        </Button>
      </div>
    </>
  );
}

export default FlightsFilters;
