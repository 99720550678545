import { Card, Divider } from "@mui/material";
import { SuitcaseRolling } from "@phosphor-icons/react";
import { extractAirlineCarriers } from "@/app/lib/utils/extractAirlineCarriers";
import { useTranslations } from "use-intl";
import type {
  Itierary,
  ItieraryFlight,
  OrderStatus,
} from "@/app/lib/types/types";
import { FlightInfo, FlightOverallTime } from "./flights/FlightInfo";
import { formatCurrency } from "../lib/utils/currency";
import AirlineAvatarGroup from "./AirlineAvatarGroup";
import { OrderLabel } from "./my-trips/OrderLabel";

const SingleFlightInfo = ({ flight }: { flight: ItieraryFlight }) => {
  const firstSegment = flight.segments[0];
  const lastSegment = flight.segments[flight.segments.length - 1];
  const t = useTranslations();

  const timeArray = flight.duration?.split(":") || null;
  const overallTime = timeArray?.length
    ? t("durationValue", {
        hours: timeArray[0]?.trim(),
        minutes: timeArray[1]?.trim(),
      })
    : null;

  const doesOperatingCarrierDiffer =
    flight?.segments?.[0]?.operatingCarrier &&
    flight.segments[0].operatingCarrier.airline.name !==
      flight.segments[0].marketingCarrier.airline.name;

  return (
    <FlightInfo
      origin={{
        date: firstSegment.departure.date,
        time: firstSegment.departure.time,
        code: firstSegment.departure.airport.code,
      }}
      destination={{
        date: lastSegment.arrival.date,
        time: lastSegment.arrival.time,
        code: lastSegment.arrival.airport.code,
      }}
    >
      <div className="flex flex-col">
        <FlightOverallTime
          overallTime={overallTime}
          stops={flight.stops?.length}
        />
        {doesOperatingCarrierDiffer && (
          <div className="text-tertiary text-center text-sm">{`${t("flights.operatedBy")} ${firstSegment.operatingCarrier.airline.name}`}</div>
        )}
      </div>
    </FlightInfo>
  );
};

function SingleAvailableFlight({
  itinerary,
  orderStatus,
  onClick,
}: {
  itinerary: Itierary;
  orderStatus?: OrderStatus;
  onClick?: () => void;
}) {
  const t = useTranslations();
  const allAirlines = extractAirlineCarriers(
    itinerary.flights.flatMap((f) => f.segments),
  );

  const isBaggageIncluded = itinerary.flights.some((flight) =>
    flight.segments.some((segment) =>
      segment.baggages?.some((baggage) => Number(baggage.quantity) > 0),
    ),
  );

  return (
    <Card
      className="flex flex-col gap-4 p-4"
      onClick={onClick}
      sx={{
        "& + &": { mt: 1 },
        borderRadius: "1.5rem",
      }}
      elevation={0}
    >
      <div className="flex w-full justify-between">
        <div className="flex items-center gap-2">
          <div className="flex w-fit">
            <AirlineAvatarGroup airlines={allAirlines} />
          </div>
          <div className="flex flex-wrap gap-1">
            {allAirlines.slice(0, 2).map((airline, index) => (
              <span
                key={airline.name || index}
                className="text-primary truncate text-sm"
              >
                {airline.name || t("unknownAirline")}{" "}
                <span className="text-tertiary text-xs">
                  ({airline.code} {airline.flightNumber})
                </span>
                {allAirlines.length > index + 1 ? "," : ""}
              </span>
            ))}
            {allAirlines.length > 2 && (
              <span className="text-primary mr-1 text-sm">
                +{allAirlines.length - 2}
              </span>
            )}
          </div>
        </div>
        {orderStatus && <OrderLabel status={orderStatus} />}
      </div>

      <SingleFlightInfo flight={itinerary.flights[0]} />
      {itinerary.flights[1] && (
        <SingleFlightInfo flight={itinerary.flights[1]} />
      )}

      {itinerary.totalPrice ? (
        <>
          <Divider />
          <div className="flex flex-row-reverse items-center justify-between">
            <span className="text-primary text-sm font-semibold" dir="ltr">
              {formatCurrency(itinerary.totalPrice, itinerary.currency)}
            </span>

            {isBaggageIncluded && (
              <div className="flex items-center gap-2">
                <span className="text-tertiary text-xs">
                  {t("flights.baggageIncluded")}
                </span>
                <SuitcaseRolling size={16} />
              </div>
            )}
          </div>
        </>
      ) : null}
    </Card>
  );
}

export default SingleAvailableFlight;
