import { useState, useId } from "react";
import { Button, Checkbox, Divider } from "@mui/material";
import {
  Swatches,
  ListBullets,
  ListDashes,
  Bed,
  Hamburger,
  Swap,
} from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

import type { IHotelsFilters } from "@/app/lib/types/interfaces";

import PriceRangeSlider from "../PriceRangeSlider";

const BOARD_BASIS_OPTIONS = [
  {
    name: "Half Board",
    shortName: "HB",
    icon: <ListDashes size={20} className="fill-secondary" />,
  },
  {
    name: "Full Board",
    shortName: "FB",
    icon: <ListBullets size={20} className="fill-secondary" />,
  },
  {
    name: "All Inclusive",
    shortName: "AI",
    icon: <Swatches size={20} className="fill-secondary" />,
  },
  {
    name: "Room Only",
    shortName: "RO",
    icon: <Bed size={20} className="fill-secondary" />,
  },
  {
    name: "Self Catering",
    shortName: "SC",
    icon: <Hamburger size={20} className="fill-secondary" />,
  },
  {
    name: "Bed & Breakfast",
    shortName: "BB",
    icon: <Swap size={20} className="fill-secondary" />,
  },
  {
    name: "Room Description",
    shortName: "RD",
    icon: <Bed size={20} className="fill-secondary" />,
  },
];

const BOARD_BASIS = BOARD_BASIS_OPTIONS.map((option) => option.shortName);
const getOptionByName = (name: string) =>
  BOARD_BASIS_OPTIONS.find((option) => option.shortName === name);

type Props = {
  defaultFilters: IHotelsFilters;
  filters: IHotelsFilters;
  onFiltersChange: (newFilters: Partial<IHotelsFilters>) => void;
};

function HotelsFilters({ defaultFilters, filters, onFiltersChange }: Props) {
  const priceHeaderId = useId();
  const t = useTranslations();
  const [localFilters, setLocalFilters] = useState<IHotelsFilters>({
    ...filters,
  });

  return (
    <>
      <div className="mt-4">
        <h3 id={priceHeaderId} className="pb-2 text-xl font-bold text-black">
          {t("hotels.filters.priceRangePerNight")}
        </h3>
      </div>

      <PriceRangeSlider
        min={defaultFilters.priceRange[0]}
        max={defaultFilters.priceRange[1]}
        aria-labelledby={priceHeaderId}
        value={[localFilters.priceRange[0], localFilters.priceRange[1]]}
        onChange={(newValue) => {
          setLocalFilters((prevFilters) => ({
            ...prevFilters,
            priceRange: newValue,
          }));
        }}
      />

      <div className="mt-8">
        <h3 className="pb-2 text-xl font-bold text-black">
          {t("hotels.filters.boardBasis")}
        </h3>
        <ul>
          {BOARD_BASIS.map((option) => (
            <li key={option}>
              <label className="flex w-full justify-between py-2">
                <div className="flex items-center gap-4">
                  {getOptionByName(option)?.icon}
                  <span className="text-primary font-medium">
                    {getOptionByName(option)?.name}
                  </span>
                </div>
                <Checkbox
                  checked={localFilters.boardBasis?.includes(option)}
                  onChange={() => {
                    setLocalFilters((prevFilters) => {
                      const newBoardBasisFilters =
                        prevFilters.boardBasis.includes(option)
                          ? prevFilters.boardBasis.filter(
                              (prev) => prev !== option,
                            )
                          : [...prevFilters.boardBasis, option];
                      return {
                        ...prevFilters,
                        boardBasis: newBoardBasisFilters,
                      };
                    });
                  }}
                  sx={{ padding: "6px" }}
                />
              </label>
              <Divider />
            </li>
          ))}
        </ul>

        <div className="mt-8">
          <h3 className="pb-2 text-xl font-bold text-black">
            {t("hotels.filters.hotelClass")}
          </h3>
          <ul>
            {[1, 2, 3, 4, 5].map((option) => (
              <li key={option}>
                <label className="flex w-full items-center justify-between py-2">
                  <span className="text-primary font-medium">
                    {t(`hotels.filters.stars.${option}`)}
                  </span>
                  <Checkbox
                    checked={localFilters.starRating?.includes(option)}
                    onChange={() => {
                      setLocalFilters((prevFilters) => {
                        const newStarFilters = prevFilters.starRating.includes(
                          option,
                        )
                          ? prevFilters.starRating.filter(
                              (prev) => prev !== option,
                            )
                          : [...prevFilters.starRating, option];
                        return {
                          ...prevFilters,
                          starRating: newStarFilters.sort((a, b) => a - b),
                        };
                      });
                    }}
                    sx={{ padding: "6px" }}
                  />
                </label>
                <Divider />
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="mt-auto flex flex-col items-center gap-2 p-4 pb-0">
        <Button
          fullWidth
          onClick={() => setLocalFilters(defaultFilters)}
          variant="text"
        >
          {t("hotels.filters.clearFilters")}
        </Button>
        <Button
          fullWidth
          onClick={() => onFiltersChange(localFilters)}
          variant="contained"
        >
          {t("hotels.filters.done")}
        </Button>
      </div>
    </>
  );
}

export default HotelsFilters;
