import { Star } from "@phosphor-icons/react";
import { useTranslations } from "use-intl";

type Props = {
  stars: number;
};

export function HotelStars({ stars }: Props) {
  const t = useTranslations();

  return (
    <div className="flex items-center gap-2">
      <div className="flex">
        {Array.from(Array(Number(stars))).map((_, index) => (
          <Star key={index} size={12} color="#EF9C43" weight="fill" />
        ))}
      </div>
      <span className="text-secondary text-sm">
        {t("hotels.card.stars", { stars: stars })}
      </span>
    </div>
  );
}
