"use client";

import {
  styled,
  ToggleButton,
  toggleButtonClasses,
  ToggleButtonGroup,
} from "@mui/material";
import { useTranslations } from "use-intl";
import { useNavigate } from "@tanstack/react-router";

// FIXME: Merge with ScopeSwitcher
export const StyledToggleButton = styled(ToggleButton)(() => ({
  border: "none",
  textTransform: "none",
  color: "var(--color-text-disabled-primary)",
  fontWeight: "500",
  fontSize: "16px",
  letterSpacing: "0.15px",
  lineHeight: "22px",
  borderRadius: "inherit !important",
  margin: "0 !important",
  [`&.${toggleButtonClasses.selected}`]: {
    backgroundColor: "white !important",
    color: "black !important",
  },
}));

interface CategorySwitcherProps {
  current: "flights" | "hotels";
}

export const CategorySwitcher = ({ current }: CategorySwitcherProps) => {
  const t = useTranslations();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center">
      <ToggleButtonGroup
        color="primary"
        value={current || "flights"}
        exclusive
        onChange={(_, newValue) => {
          navigate({
            to: "/trips",
            search: { category: newValue || current },
            replace: true,
          });
        }}
        aria-label="Platform"
        sx={{
          backgroundColor: "var(--color-surface-neutral-medium)",
          borderRadius: "46px",
          padding: "4px !important",
          height: "46px",
        }}
      >
        <StyledToggleButton value="flights" className="min-w-28">
          {t("reservations.flight.title")}
        </StyledToggleButton>
        <StyledToggleButton value="hotels" className="min-w-28">
          {t("reservations.hotel.title")}
        </StyledToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
